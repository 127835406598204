import { PermissionGate } from '../../../Components/Navegacao/PermissionGate';
import { FrontEndPathResources } from '../../../Models/Api';
import { RecursosPerfisEnum } from '../../../Models/Usuario';
import { FindAnexosDocumentosByOrdemColetaId } from '../../OrdemColeta/domain/usecases/FindAnexosDocumentosByOrdemColetaId';
import { PostReprocessarDescarga } from '../../OrdemColeta/domain/usecases/PostReprocessarDescarga';
import { PostReprocessarOrdemColeta } from '../../OrdemColeta/domain/usecases/PostReprocessarOrdemColeta';
import { PutArquivoOrdemColeta } from '../../OrdemColeta/domain/usecases/PutArquivoOrdemColeta';
import { OrdemColetaRepository } from '../../OrdemColeta/infra/OrdemColetaRepository';
import Listar from '../Listar';

export const DoubleCheckListarFactory: React.FC = () => {
    const ordemColetaRepository = new OrdemColetaRepository();

    const putArquivoOrdemColeta = new PutArquivoOrdemColeta(
        ordemColetaRepository
    );

    const findAnexosDocumentosByOrdemColetaId =
        new FindAnexosDocumentosByOrdemColetaId(ordemColetaRepository);
    
    const postReprocessarOrdemColeta = new PostReprocessarOrdemColeta(
        ordemColetaRepository
    );

    const postReprocessarDescarga = new PostReprocessarDescarga(
        ordemColetaRepository
    );

    return (
        <PermissionGate
            recurso={RecursosPerfisEnum.DOUBLE_CHECK_ALTERAR}
            redirect={FrontEndPathResources.Dashboard}
        >
            <Listar
                postReprocessarOrdemColeta={postReprocessarOrdemColeta}
                postReprocessarDescarga={postReprocessarDescarga}
                putArquivoOrdemColeta={putArquivoOrdemColeta}
                findAnexosDocumentosByOrdemColetaId={
                    findAnexosDocumentosByOrdemColetaId
                }
            />
        </PermissionGate>
    );
};
