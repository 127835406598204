import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ArticleIcon from '@mui/icons-material/Article';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckIcon from '@mui/icons-material/Check';
import DiscFullIcon from '@mui/icons-material/DiscFull';
import EditIcon from '@mui/icons-material/Edit';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import LaunchIcon from '@mui/icons-material/Launch';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import MoveUpIcon from '@mui/icons-material/MoveUp';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import {
    Card,
    CardContent,
    Chip,
    Container,
    Divider,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Theme,
    Tooltip,
    Typography,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { format } from 'date-fns';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useAuth } from '../../../AuthProvider';
import ModalConfirmarDelete from '../../../Components/ModalConfirmarDelete';
import { PermissionGate } from '../../../Components/Navegacao/PermissionGate';
import PageTitle from '../../../Components/PageTitle/PageTitle';
import SpinnerLoading from '../../../Components/SpinnerLoading/SpinnerLoading';
import GenericComplexTable, {
    ComplexColumn,
} from '../../../Components/Table/GenericComplexTable';
import Search from '../../../Components/Table/Search';
import { MensagemErroRequisicaoApi } from '../../../Config/Api';
import { ApiResources, FrontEndPathResources } from '../../../Models/Api';
import LoteEmbarqueTransportadora from '../../../Models/LoteEmbarqueTransportadora';
import { RecursosPerfisEnum } from '../../../Models/Usuario';
import { useFetchUseCase } from '../../../hooks/useFetchUseCase';
import useGenericService from '../../../hooks/useGenericService';
import { ModalHistoricoAlteracoesOrdemColeta } from '../../LoteEmbarque/ModalHistoricoAlteracoesOrdemColeta';
import { IOrdemColetaRequestDto } from '../../OrdemColeta/domain/dtos/IOrdemColetaRequestDto';
import { IOrdemColetaResponseDto } from '../../OrdemColeta/domain/dtos/IOrdemColetaResponseDto';
import { EStatusOrdemColeta } from '../../OrdemColeta/domain/entities/EStatusOrdemColeta';
import { DeleteOrdemColetaById } from '../../OrdemColeta/domain/usecases/DeleteOrdemColetaById';
import { FindAnexosDocumentosByOrdemColetaId } from '../../OrdemColeta/domain/usecases/FindAnexosDocumentosByOrdemColetaId';
import { GetSaldoLoteEmbarqueTransportadoraById } from '../../OrdemColeta/domain/usecases/GetSaldoLoteEmbarqueTransportadoraById';
import { PostReprocessarOrdemColeta } from '../../OrdemColeta/domain/usecases/PostReprocessarOrdemColeta';
import { PutArquivoOrdemColeta } from '../../OrdemColeta/domain/usecases/PutArquivoOrdemColeta';
import { EStatusLotesPorTransportadora } from '../domain/entities/EStatusLotesPorTransportadora';
import { FindLotesEmbarqueByDocumentoTransportadora } from '../domain/usecases/FindLotesEmbarqueByDocumentoTransportadora';
import { styles } from '../helpers/Styles';
import { LotePorTransportadoraDtoResponse } from '../types/LotePorTransportadoraDtoResponse';
import ModalDetalheErro from './ModalDetalheErro';
import ModalHistoricoAlteracoes from './ModalHistoricoAlteracoes';
import ModalAnexosDocumentos from './components/ModalAnexosDocumentos';
import { EStatusDoubleCheck } from '../../DoubleCheck/entities/EStatusDoubleCheck';
import { EStatusDescarga } from '../../DoubleCheck/entities/EStatusDescarga';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        tableHead: {
            backgroundColor: '#EDF0FE',
            fontWeight: 'bold',
        },
    })
);

export enum Status {
    LIBERADO = 'Liberado',
    ANDAMENTO = 'Em Andamento',
    AGUARDANDO = 'Aguardando Processamento',
    ENCERRADO = 'Encerrado',
}

interface Props {
    findLotesEmbarqueByDocumentoTransportadora: FindLotesEmbarqueByDocumentoTransportadora;
    getSaldoLoteEmbarqueTransportadoraById: GetSaldoLoteEmbarqueTransportadoraById;
    deleteOrdemColetaById: DeleteOrdemColetaById;
    putArquivoOrdemColeta: PutArquivoOrdemColeta;
    findAnexosDocumentosByOrdemColetaId: FindAnexosDocumentosByOrdemColetaId;
    postReprocessarOrdemColeta: PostReprocessarOrdemColeta;
}

export default function Listar({
    findLotesEmbarqueByDocumentoTransportadora,
    getSaldoLoteEmbarqueTransportadoraById,
    deleteOrdemColetaById,
    putArquivoOrdemColeta,
    findAnexosDocumentosByOrdemColetaId,
    postReprocessarOrdemColeta,
}: Props) {
    const { usuarioLogado } = useAuth();
    const { getService } = useGenericService();
    const { executePromise, loading } = useFetchUseCase();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const classes = useStyles();
    const navigate = useNavigate();

    const [lotesPorTransportadora, setLotesPorTransportadora] = useState<
        LotePorTransportadoraDtoResponse[]
    >([]);

    const [mostrarModalAuditoria, setMostrarModalAuditoria] =
        useState<boolean>(false);

    const [mostrarModalDetalheErro, setMostrarModalDetalheErro] =
        useState<boolean>(false);

    const [mostrarOrdemColetaDetalheErro, setMostrarOrdemColetaDetalheErro] =
        useState<IOrdemColetaResponseDto>();

    const [
        mostrarOrdemColetaModalAuditoria,
        setMostrarOrdemColetaModalAuditoria,
    ] = useState<boolean>(false);

    const [ordemColeta, setOrdemColeta] = useState<IOrdemColetaResponseDto>();

    const [loteMostrarAuditoria, setLoteMostrarAuditoria] =
        useState<LoteEmbarqueTransportadora>();

    const [refreshTable, setRefreshTable] = useState<boolean>(false);

    const [mostrarModalConfirmarDeletar, setMostrarModalConfirmarDeletar] =
        useState<boolean>(false);

    const [ordemColetaId, setOrdemColetaId] = useState<number>();

    const [idOrdemColetaDeletar, setIdOrdemColetaDeletar] = useState<number>(0);
    const [textSearch, setTextSearch] = useState('');

    const [
        filtroStatusLoteEmbarqueTransportadora,
        setFiltroStatusLoteEmbarqueTransportadora,
    ] = useState('');

    const [rowsPerPageCustom, setRowsPerPageCustom] = useState(10);

    const [pageCustom, setPageCustom] = useState(0);

    const [totalCustom, setTotalCustom] = useState(0);

    const [sortCustom, setSortCustom] = useState('desc');

    const [isReprocessando, setIsReprocessando] = useState(false);

    function handleReprocessarOrdemColeta(
        ordemColeta: IOrdemColetaResponseDto
    ) {
        setMostrarOrdemColetaDetalheErro(ordemColeta);
        setMostrarModalDetalheErro(true);
    }

    useEffect(() => {
        const cpf = usuarioLogado?.cpf?.replace(/\D/g, '');
        const cnpj = usuarioLogado?.cnpj?.replace(/\D/g, '');
        const documentoPesquisar = cpf || cnpj || '';
        if (usuarioLogado) {
            executePromise(
                () =>
                    findLotesEmbarqueByDocumentoTransportadora.execute({
                        documento: documentoPesquisar,
                        lote: textSearch,
                        status: filtroStatusLoteEmbarqueTransportadora,
                        page: pageCustom,
                        size: rowsPerPageCustom,
                        sort: sortCustom,
                        setTotal: setTotalCustom,
                    }),
                (response: LotePorTransportadoraDtoResponse[]) => {
                    setLotesPorTransportadora(response);
                },
                () => {
                    enqueueSnackbar('Não foi possível encontrar o registro', {
                        variant: 'error',
                        onClick: () => {
                            closeSnackbar();
                        },
                    });
                }
            );
        }
        setRefreshTable(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        usuarioLogado,
        refreshTable,
        textSearch,
        filtroStatusLoteEmbarqueTransportadora,
        rowsPerPageCustom,
        pageCustom,
        sortCustom,
    ]);

    const validaPrimarizacao = (row: any) => {
        if (row.ordemColetaList.length > 0) {
            return row.ordemColetaList.some(
                (oc: any) => oc.cadastradaViaPortal == false
            );
        }
    };

    const reprocessarOrdemColeta = async (
        ordemColeta: IOrdemColetaRequestDto
    ) => {
        setIsReprocessando(true);
        executePromise(
            () => postReprocessarOrdemColeta.execute(ordemColeta.id!),
            () => {
                enqueueSnackbar('Ordem de coleta reprocessada com sucesso', {
                    variant: 'success',
                    onClick: () => {
                        closeSnackbar();
                    },
                });
                setRefreshTable(true);
                setIsReprocessando(false);
            },
            (errors) => {
                if (errors?.length > 0) {
                    errors.map((error: MensagemErroRequisicaoApi) =>
                        enqueueSnackbar(error?.mensagem, {
                            variant: 'error',
                            onClick: () => {
                                closeSnackbar();
                            },
                        })
                    );
                } else {
                    enqueueSnackbar(
                        'Não foi possível reprocessar a ordem de coleta',
                        {
                            variant: 'error',
                            onClick: () => {
                                closeSnackbar();
                            },
                        }
                    );
                }

                setIsReprocessando(false);
            }
        );
    };

    const getPesoDisponivel = (row: any) => {
        let totalLancado = 0;
        row.ordemColetaList.map((ocl: any) => {
            totalLancado += ocl.peso;
        });

        return totalLancado === row.pesoPlanejadoTransportadora;
    };

    const getStatusOrdemEmbarqueFormatado = (
        statusOrdem: EStatusLotesPorTransportadora
    ) => {
        switch (statusOrdem) {
            case EStatusLotesPorTransportadora.FINALIZADO:
                return (
                    <Chip
                        style={styles().chipStatus}
                        label={
                            <div style={styles().divChip}>
                                <ImportContactsIcon
                                    style={styles().iconStyle}
                                />{' '}
                                {EStatusLotesPorTransportadora.FINALIZADO}
                            </div>
                        }
                        color="secondary"
                        variant="outlined"
                    />
                );
            case EStatusLotesPorTransportadora.ANDAMENTO:
                return (
                    <Chip
                        style={styles().chipStatus}
                        label={
                            <div style={styles().divChip}>
                                <CheckIcon style={styles().iconStyle} />{' '}
                                {EStatusLotesPorTransportadora.ANDAMENTO}
                            </div>
                        }
                        color="primary"
                        variant="outlined"
                    />
                );
            case EStatusLotesPorTransportadora.AGUARDANDO:
                return (
                    <Chip
                        style={styles().chipStatus}
                        label={
                            <div style={styles().divChip}>
                                <DiscFullIcon style={styles().iconStyle} />{' '}
                                {EStatusLotesPorTransportadora.AGUARDANDO}
                            </div>
                        }
                        color="warning"
                        variant="outlined"
                    />
                );
            case EStatusLotesPorTransportadora.LIBERADO:
                return (
                    <Chip
                        style={styles().chipStatus}
                        label={
                            <div style={styles().divChip}>
                                <CheckIcon style={styles().iconStyle} />{' '}
                                {EStatusLotesPorTransportadora.LIBERADO}
                            </div>
                        }
                        color="success"
                        variant="outlined"
                    />
                );
        }
    };

    const getStatusOrdemColetaFormatado = (
        ordemColeta: IOrdemColetaResponseDto
    ) => {
        switch (ordemColeta.statusOrdemColeta) {
            case EStatusOrdemColeta.AGENDADO:
                return (
                    <Chip
                        style={styles().chipStatus}
                        label={
                            <div style={styles().divChip}>
                                <AccessTimeIcon style={styles().iconStyle} />
                                {EStatusOrdemColeta.AGENDADO}
                            </div>
                        }
                        color="primary"
                        variant="outlined"
                    />
                );
            case EStatusOrdemColeta.ENVIADO_PARA_DOUBLECHECK:
                return (
                    <Chip
                        style={styles().chipStatus}
                        label={
                            <div style={styles().divChip}>
                                <WarningAmberIcon style={styles().iconStyle} />{' '}
                                {EStatusOrdemColeta.ENVIADO_PARA_DOUBLECHECK}
                            </div>
                        }
                        color="warning"
                        variant="outlined"
                    />
                );

            case EStatusOrdemColeta.PENDENTE_DE_DESCARGA:
                return (
                    <Chip
                        style={styles().chipStatus}
                        label={
                            <div style={styles().divChip}>
                                <WarningAmberIcon style={styles().iconStyle} />{' '}
                                {EStatusOrdemColeta.PENDENTE_DE_DESCARGA}
                            </div>
                        }
                        color="warning"
                        variant="outlined"
                    />
                );
            case EStatusOrdemColeta.PENDENTE_DE_FATURAMENTO:
                return (
                    <Chip
                        style={styles().chipStatus}
                        label={
                            <div style={styles().divChip}>
                                <WarningAmberIcon style={styles().iconStyle} />{' '}
                                {EStatusOrdemColeta.PENDENTE_DE_FATURAMENTO}
                            </div>
                        }
                        color="warning"
                        variant="outlined"
                    />
                );
            case EStatusOrdemColeta.ENVIADO_MAXYS:
                return (
                    <Chip
                        style={styles().chipStatus}
                        label={
                            <div style={styles().divChip}>
                                <WarningAmberIcon style={styles().iconStyle} />{' '}
                                {EStatusOrdemColeta.ENVIADO_MAXYS}
                            </div>
                        }
                        color="warning"
                        variant="outlined"
                    />
                );
            case EStatusOrdemColeta.FINALIZADO:
                return (
                    <Chip
                        style={styles().chipStatus}
                        label={
                            <div style={styles().divChip}>
                                <CheckIcon style={styles().iconStyle} />{' '}
                                {EStatusOrdemColeta.FINALIZADO}
                            </div>
                        }
                        color="primary"
                        variant="outlined"
                    />
                );
            case EStatusOrdemColeta.AGUARDANDO_GRE014:
                return (
                    <Chip
                        style={styles().chipStatus}
                        label={
                            <div style={styles().divChip}>
                                <WarningAmberIcon style={styles().iconStyle} />{' '}
                                {EStatusOrdemColeta.AGUARDANDO_GRE014}
                            </div>
                        }
                        color="warning"
                        variant="outlined"
                    />
                );
            case EStatusOrdemColeta.FINALIZADO_GRE014:
                return (
                    <Chip
                        style={styles().chipStatus}
                        label={
                            <div style={styles().divChip}>
                                <CheckIcon style={styles().iconStyle} />{' '}
                                {EStatusOrdemColeta.FINALIZADO_GRE014}
                            </div>
                        }
                        color="primary"
                        variant="outlined"
                    />
                );
            case EStatusOrdemColeta.COM_ERRO:
                return (
                    <Tooltip
                        title="Reprocessar Ordem de Coleta"
                        placement="top"
                        disableInteractive
                    >
                        <Chip
                            style={styles().chipStatus}
                            aria-label="reprocessar-ordem-coleta"
                            onClick={() =>
                                handleReprocessarOrdemColeta(ordemColeta)
                            }
                            label={
                                <div style={styles().divChip}>
                                    <CancelIcon style={styles().iconStyle} />{' '}
                                    {EStatusOrdemColeta.COM_ERRO}
                                </div>
                            }
                            color="error"
                            variant="outlined"
                            disabled={isReprocessando}
                        />
                    </Tooltip>
                );
        }
    };

    const colunas: ComplexColumn[] = [
        {
            attribute: 'lote',
            label: 'Lote',
            id: 'lote',
            sortable: true,
            minWidth: 40,
        },
        {
            attribute: 'localEmbarque.cidade',
            label: 'Local de Embarque',
            id: 'localEmbarque.cidade',
            format: (row) =>
                row.localEmbarque?.descricao
                    ? row.localEmbarque?.descricao
                    : row.localEmbarque.cidade,
        },
        {
            attribute: 'localDestino.cidade',
            label: 'Local de Destino',
            id: 'localDestino.cidade',
            format: (row) =>
                row.localDestino?.descricao
                    ? row.localDestino?.descricao
                    : row.localDestino.cidade,
        },
        {
            attribute: 'produto',
            label: 'Produto',
            id: 'produto',
            sortable: false,
            align: 'center',
            format: (row) => row.produto.nome,
        },
        {
            attribute: 'pesoPlanejadoTransportadora',
            label: 'Peso planejado',
            id: 'pesoPlanejadoTransportadora',
            sortable: false,
            align: 'center',
            format: (row) =>
                row.pesoPlanejadoTransportadora
                    .toString()
                    .replace('.', ',')
                    .replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ' KG',
        },
        {
            attribute: 'status',
            label: 'Status',
            id: 'status',
            sortable: false,
            format: (row) => getStatusOrdemEmbarqueFormatado(row.status),
        },
        {
            attribute: 'tipoEmbarque',
            label: 'Tipo',
            id: 'tipoEmbarque',
            sortable: false,
        },
        {
            attribute: 'observacao',
            label: 'Observação',
            id: 'observacao',
            sortable: false,
        },
        {
            attribute: 'id',
            label: 'Ações',
            id: 'acao',
            align: 'center',
            width: 200,
            format: (row) => {
                return (
                    <>
                        <PermissionGate
                            recurso={
                                RecursosPerfisEnum.LOTE_EMBARQUE_POR_TRANSPORTADORA
                            }
                            redirect={''}
                        >
                            <Tooltip
                                title="Ver"
                                placement="top"
                                disableInteractive
                            >
                                <IconButton
                                    aria-label="ver"
                                    onClick={() => {
                                        navigate(
                                            FrontEndPathResources.LoteEmbarquePorTransportadora +
                                                '/ver/' +
                                                row.id
                                        );
                                    }}
                                >
                                    <RemoveRedEyeIcon />
                                </IconButton>
                            </Tooltip>
                        </PermissionGate>
                        <PermissionGate
                            recurso={
                                RecursosPerfisEnum.LOTE_EMBARQUE_POR_TRANSPORTADORA
                            }
                            redirect={''}
                        >
                            <Tooltip
                                title="Ordem de coleta"
                                placement="top"
                                disableInteractive
                            >
                                <IconButton
                                    disabled={
                                        getPesoDisponivel(row) ||
                                        validaPrimarizacao(row)
                                    }
                                    aria-label="ordem-coleta"
                                    onClick={() => {
                                        navigate(
                                            FrontEndPathResources.OrdemColeta +
                                                '/criar/' +
                                                row.id,
                                            { state: row }
                                        );
                                    }}
                                >
                                    <MoveUpIcon />
                                </IconButton>
                            </Tooltip>
                        </PermissionGate>
                        <PermissionGate
                            recurso={
                                RecursosPerfisEnum.HISTORICO_LOTE_EMBARQUE_POR_TRANSPORTADORA
                            }
                            redirect={''}
                        >
                            <Tooltip
                                title="Histórico de alterações"
                                placement="top"
                                disableInteractive
                            >
                                <IconButton
                                    aria-label="ver"
                                    onClick={() => {
                                        setLoteMostrarAuditoria(
                                            row as LoteEmbarqueTransportadora
                                        );
                                        setMostrarModalAuditoria(true);
                                    }}
                                >
                                    <ArticleIcon />
                                </IconButton>
                            </Tooltip>
                        </PermissionGate>
                    </>
                );
            },
        },
    ];

    const getRowBodyExpandedRow = (row: any) => {
        return row.ordemColetaList.map(
            (ordemColeta: IOrdemColetaResponseDto) => {
                const permiteStatusAgendado =
                    ordemColeta.statusOrdemColeta !==
                    EStatusOrdemColeta.AGENDADO;
                const permiteStatusPendenteDeDescarga =
                    ordemColeta.statusOrdemColeta !==
                    EStatusOrdemColeta.PENDENTE_DE_DESCARGA
                const permiteStatusPendenteDeFaturamento =
                    ordemColeta.statusOrdemColeta !==
                    EStatusOrdemColeta.PENDENTE_DE_FATURAMENTO;
                const permiteStatusDoubleCheckPendenteDescarga = ordemColeta.ordemColetaValidacaoDescarga ?
                    (ordemColeta.ordemColetaValidacaoDescarga?.statusDescarga !== EStatusDescarga.PENDENTE_DESCARGA 
                    ) : false
                const permiteProcessoGre014 = ordemColeta.processoGre014 && ordemColeta.statusOrdemColeta == EStatusOrdemColeta.PENDENTE_DE_DESCARGA

                return (
                    <>
                        <TableRow key={ordemColeta.id}>
                            <TableCell>{ordemColeta.id}</TableCell>
                            <TableCell>
                                {ordemColeta.dataHoraColeta &&
                                    format(
                                        new Date(ordemColeta.dataHoraColeta),
                                        'dd/MM/Y HH:mm'
                                    ).toString()}
                            </TableCell>
                            <TableCell align="right">
                                {ordemColeta?.cavalo?.placa}
                            </TableCell>

                            <TableCell>
                                {row?.transportadora?.nome ||
                                    row?.transportadora?.nomeFantasia}
                            </TableCell>
                            <TableCell>
                                {ordemColeta?.peso
                                    ?.toString()
                                    .replace('.', ',')
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, '.') +
                                    ' KG'}
                            </TableCell>
                            <TableCell>
                                {ordemColeta?.ordemColetaValidacaoDescarga?.pesoDestino
                                    ? `${
                                          ordemColeta?.ordemColetaValidacaoDescarga?.pesoDestino
                                              ?.toString()
                                              .replace('.', ',')
                                              .replace(
                                                  /\B(?=(\d{3})+(?!\d))/g,
                                                  '.'
                                              ) + ' KG'
                                      }`
                                    : ''}
                            </TableCell>
                            <TableCell>
                                {getStatusOrdemColetaFormatado(ordemColeta)}
                            </TableCell>
                            <TableCell>
                                <Tooltip
                                    title="Abrir anexos e documentos"
                                    placement="top"
                                    disableInteractive
                                >
                                    <IconButton
                                        aria-label="abrir"
                                        onClick={() => {
                                            setOrdemColetaId(ordemColeta.id);
                                        }}
                                    >
                                        <PictureAsPdfIcon />
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                            <TableCell align="center">
                                <PermissionGate
                                    recurso={
                                        RecursosPerfisEnum.ORDEM_COLETA_VER
                                    }
                                    redirect={''}
                                >
                                    <Tooltip
                                        title="Ver"
                                        placement="top"
                                        disableInteractive
                                    >
                                        <IconButton
                                            aria-label="ver"
                                            onClick={() => {
                                                navigate(
                                                    FrontEndPathResources.OrdemColeta +
                                                        '/ver/' +
                                                        ordemColeta.id,
                                                    { state: row }
                                                );
                                            }}
                                        >
                                            <RemoveRedEyeIcon />
                                        </IconButton>
                                    </Tooltip>
                                </PermissionGate>
                                <PermissionGate
                                    recurso={
                                        RecursosPerfisEnum.ORDEM_COLETA_ALTERAR
                                    }
                                    redirect={''}
                                >
                                    <Tooltip
                                        title="Alterar"
                                        placement="top"
                                        disableInteractive
                                    >
                                        <IconButton
                                            disabled={permiteStatusAgendado || validaPrimarizacao(row)}
                                            aria-label="alterar"
                                            onClick={() => {
                                                navigate(
                                                    FrontEndPathResources.OrdemColeta +
                                                        '/alterar/' +
                                                        ordemColeta.id,
                                                    { state: row }
                                                );
                                            }}
                                        >
                                            <EditIcon />
                                        </IconButton>
                                    </Tooltip>
                                </PermissionGate>
                                <PermissionGate
                                    recurso={
                                        RecursosPerfisEnum.ORDEM_COLETA_ALTERAR
                                    }
                                    redirect={''}
                                >
                                    <Tooltip
                                        title="Lançar dados de embarque"
                                        placement="top"
                                        disableInteractive
                                    >
                                        <IconButton
                                            disabled={
                                                permiteStatusAgendado &&
                                                permiteStatusPendenteDeFaturamento
                                            }
                                            aria-label="Lançar dados de embarque"
                                            onClick={() => {
                                                navigate(
                                                    FrontEndPathResources.OrdemColeta +
                                                        '/lancar-dados-embarque/' +
                                                       ordemColeta.loteEmbarqueTransportadoraId +"/"+ ordemColeta.id  ,
                                                    { state: ordemColeta }
                                                );
                                            }}
                                        >
                                            <LaunchIcon />
                                        </IconButton>
                                    </Tooltip>
                                </PermissionGate>
                                <PermissionGate
                                    recurso={
                                        RecursosPerfisEnum.ORDEM_COLETA_ALTERAR
                                    }
                                    redirect={''}
                                >
                                    <Tooltip
                                        title="Validação de descarga"
                                        placement="top"
                                        disableInteractive
                                    >
                                        <IconButton
                                            disabled={
                                                (!permiteProcessoGre014 || permiteStatusDoubleCheckPendenteDescarga) && (permiteStatusPendenteDeDescarga || permiteStatusDoubleCheckPendenteDescarga)
                                            }
                                            aria-label="Validação de descarga"
                                            onClick={() => {
                                                navigate(
                                                    FrontEndPathResources.OrdemColeta +
                                                        '/validar-descarga/' +
                                                        ordemColeta.id
                                                );
                                            }}
                                        >
                                            <LocalShippingIcon
                                                color={
                                                    (!permiteProcessoGre014 || permiteStatusDoubleCheckPendenteDescarga) && (permiteStatusPendenteDeDescarga ||
                                                        permiteStatusDoubleCheckPendenteDescarga)
                                                        ? 'inherit'
                                                        : 'warning'
                                                }
                                            />
                                        </IconButton>
                                    </Tooltip>
                                </PermissionGate>
                                <PermissionGate
                                    recurso={
                                        RecursosPerfisEnum.ORDEM_COLETA_EXCLUIR
                                    }
                                    redirect={''}
                                >
                                    <Tooltip
                                        title="Excluir"
                                        placement="top"
                                        disableInteractive
                                    >
                                        <IconButton
                                            disabled={permiteStatusAgendado}
                                            aria-label="delete"
                                            onClick={() => {
                                                openModalDeletar(
                                                    ordemColeta.id!
                                                );
                                            }}
                                        >
                                            <CancelIcon />
                                        </IconButton>
                                    </Tooltip>
                                </PermissionGate>
                                <PermissionGate
                                    recurso={
                                        RecursosPerfisEnum.ORDEM_COLETA_VER
                                    }
                                    redirect={''}
                                >
                                    <Tooltip
                                        title="Histórico de alterações"
                                        placement="top"
                                        disableInteractive
                                    >
                                        <IconButton
                                            aria-label="ver histórico de aleterações"
                                            onClick={() => {
                                                setOrdemColeta(ordemColeta);
                                                setMostrarOrdemColetaModalAuditoria(
                                                    true
                                                );
                                            }}
                                        >
                                            <ArticleIcon />
                                        </IconButton>
                                    </Tooltip>
                                </PermissionGate>
                            </TableCell>
                        </TableRow>
                    </>
                );
            }
        );
    };

    function expandedRowContent(row: any) {
        return (
            <Card style={styles().card}>
                <CardContent>
                    <Typography
                        sx={{ fontSize: 20 }}
                        color="text.secondary"
                        gutterBottom
                    >
                        Ordens de coleta
                    </Typography>

                    <Table size="medium">
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    width={50}
                                    className={classes.tableHead}
                                >
                                    Nº Ordem
                                </TableCell>
                                <TableCell
                                    width={50}
                                    className={classes.tableHead}
                                >
                                    Data/Hora de início
                                </TableCell>
                                <TableCell
                                    width={50}
                                    className={classes.tableHead}
                                    align="right"
                                >
                                    Placa
                                </TableCell>
                                <TableCell
                                    width={80}
                                    className={classes.tableHead}
                                >
                                    Transportadora
                                </TableCell>
                                <TableCell
                                    width={50}
                                    className={classes.tableHead}
                                >
                                    Peso origem
                                </TableCell>
                                <TableCell
                                    width={50}
                                    className={classes.tableHead}
                                >
                                    Peso destino
                                </TableCell>
                                <TableCell
                                    width={50}
                                    className={classes.tableHead}
                                >
                                    Status
                                </TableCell>
                                <TableCell
                                    width={50}
                                    className={classes.tableHead}
                                >
                                    Anexos e Documentos
                                </TableCell>
                                <TableCell
                                    width={200}
                                    className={classes.tableHead}
                                    align="center"
                                >
                                    Ações
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>{getRowBodyExpandedRow(row)}</TableBody>
                    </Table>
                </CardContent>
                {!row?.ordensColetaList && row?.ordensColetaList?.length > 0 ? (
                    <Typography
                        sx={{ fontSize: 14, textAlign: 'center' }}
                        color="text.secondary"
                        gutterBottom
                        mb={3}
                    >
                        Não há ordens de coletas registradas
                    </Typography>
                ) : (
                    <> </>
                )}
            </Card>
        );
    }

    const openModalDeletar = (idOrdemColeta: number) => {
        setMostrarModalConfirmarDeletar(true);
        setIdOrdemColetaDeletar(idOrdemColeta);
    };

    const desativarOrdemColeta = async (idOrdemColetaDeletar: number) => {
        executePromise(
            () => deleteOrdemColetaById.execute(idOrdemColetaDeletar),
            () => {
                enqueueSnackbar('Ordem coleta desativada com sucesso', {
                    variant: 'success',
                    onClick: () => {
                        closeSnackbar();
                    },
                });
                setRefreshTable(true);
            }
        );
    };

    return (
        <PermissionGate
            recurso={RecursosPerfisEnum.LOTE_EMBARQUE_POR_TRANSPORTADORA}
            redirect={FrontEndPathResources.Dashboard}
        >
            <Container maxWidth={false}>
                <PageTitle title="Lotes por transportadora" />
                <Divider />
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={4} mt={3}>
                        <Search
                            onSearch={(searchText) => {
                                setTextSearch(searchText);
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} lg={4} mt={3}>
                        <FormControl fullWidth>
                            <InputLabel id="labelStatus">Status</InputLabel>
                            <Select
                                labelId="labelStatus"
                                id="status"
                                value={filtroStatusLoteEmbarqueTransportadora}
                                label="Status"
                                onChange={(event) =>
                                    setFiltroStatusLoteEmbarqueTransportadora(
                                        event.target.value as EStatusOrdemColeta
                                    )
                                }
                            >
                                <MenuItem value={''}>
                                    <em>Nenhum</em>
                                </MenuItem>
                                <MenuItem
                                    value={
                                        EStatusLotesPorTransportadora.LIBERADO
                                    }
                                >
                                    {EStatusLotesPorTransportadora.LIBERADO}
                                </MenuItem>
                                <MenuItem
                                    value={
                                        EStatusLotesPorTransportadora.ANDAMENTO
                                    }
                                >
                                    {EStatusLotesPorTransportadora.ANDAMENTO}
                                </MenuItem>
                                <MenuItem
                                    value={
                                        EStatusLotesPorTransportadora.AGUARDANDO
                                    }
                                >
                                    {EStatusLotesPorTransportadora.AGUARDANDO}
                                </MenuItem>
                                <MenuItem
                                    value={
                                        EStatusLotesPorTransportadora.FINALIZADO
                                    }
                                >
                                    {EStatusLotesPorTransportadora.FINALIZADO}
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <SpinnerLoading isLoading={loading}>
                    <GenericComplexTable
                        columnToSearch={'id'}
                        expandedRows
                        contentExpandedRow={expandedRowContent}
                        service={getService(
                            ApiResources.LoteEmbarquePorTransportadora
                        )}
                        rows={lotesPorTransportadora}
                        columns={colunas}
                        naoMostrarBotaoNovo
                        createButtonText="Cadastrar nova"
                        linkCreateButtonText={
                            FrontEndPathResources.OrdemColeta + '/criar'
                        }
                        naoMostrarCampoPesquisar
                        setRowsPerPageCustom={setRowsPerPageCustom}
                        setPageCustom={setPageCustom}
                        totalCustom={totalCustom}
                        setSortCustom={setSortCustom}
                        usarComponentesCustom={true}
                    />
                </SpinnerLoading>

                {mostrarModalAuditoria && loteMostrarAuditoria && (
                    <ModalHistoricoAlteracoes
                        loteEmbarqueTransportadora={loteMostrarAuditoria}
                        aberto={mostrarModalAuditoria}
                        fecharClicado={() => {
                            setMostrarModalAuditoria(false);
                        }}
                    />
                )}
                {mostrarModalDetalheErro && mostrarOrdemColetaDetalheErro && (
                    <ModalDetalheErro
                        ordemColeta={mostrarOrdemColetaDetalheErro}
                        aberto={mostrarModalDetalheErro}
                        fecharClicado={() => {
                            setMostrarModalDetalheErro(false);
                        }}
                        onReprocessa={() => {
                            reprocessarOrdemColeta(
                                mostrarOrdemColetaDetalheErro
                            );
                            setMostrarModalDetalheErro(false);
                        }}
                    />
                )}
                {mostrarOrdemColetaModalAuditoria && ordemColeta && (
                    <ModalHistoricoAlteracoesOrdemColeta
                        ordemColeta={ordemColeta}
                        aberto={mostrarOrdemColetaModalAuditoria}
                        fecharClicado={() => {
                            setMostrarOrdemColetaModalAuditoria(false);
                        }}
                    />
                )}
                {mostrarModalAuditoria && loteMostrarAuditoria && (
                    <ModalHistoricoAlteracoes
                        loteEmbarqueTransportadora={loteMostrarAuditoria}
                        aberto={mostrarModalAuditoria}
                        fecharClicado={() => {
                            setMostrarModalAuditoria(false);
                        }}
                    />
                )}
                {mostrarModalConfirmarDeletar && (
                    <ModalConfirmarDelete
                        open={mostrarModalConfirmarDeletar}
                        onClose={() => setMostrarModalConfirmarDeletar(false)}
                        onConfirm={() => {
                            desativarOrdemColeta(idOrdemColetaDeletar);
                            setMostrarModalConfirmarDeletar(false);
                        }}
                        mensagem={
                            'Deseja realmente desativar esta ordem de coleta?'
                        }
                    />
                )}
                {ordemColetaId && (
                    <ModalAnexosDocumentos
                        open={!!ordemColetaId}
                        onClose={() => setOrdemColetaId(undefined)}
                        ordemColetaId={ordemColetaId}
                        putArquivoOrdemColeta={putArquivoOrdemColeta}
                        findAnexosDocumentosByOrdemColetaId={
                            findAnexosDocumentosByOrdemColetaId
                        }
                    />
                )}
            </Container>
        </PermissionGate>
    );
}
