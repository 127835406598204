import { FunctionComponent, useState } from 'react';
import { useFormContext, useFieldArray, Controller } from 'react-hook-form';
import { IDoubleCheck } from '../entities/IDoubleCheck';
import { Button, Grid, IconButton, TextField, Tooltip } from '@mui/material';
import { styles } from '../../PerfilUsuario/helpers/Styles';
import { useAuth } from '../../../AuthProvider';
import CancelIcon from '@mui/icons-material/Cancel';
import { format, parse, formatISO, isValid } from 'date-fns';

interface ObservacoesFormProps {}

const ObservacoesForm: FunctionComponent<ObservacoesFormProps> = () => {
    const { control, setValue, reset, getValues } =
        useFormContext<IDoubleCheck>();

    const { fields, append, prepend, remove, swap, move, insert, replace } =
        useFieldArray({
            control,
            name: 'observacoes',
        });

    const auth = useAuth();

    function parseDate(value: any) {
        // Check if the value is already formatted as 'dd/MM/yyyy HH:mm'
        const parsedDate = parse(value, 'dd/MM/yyyy HH:mm', new Date());
      
        if (isValid(parsedDate)) {
          return value
        } else {
          return format(new Date(value), 'dd/MM/yyyy HH:mm');
        }
    }
      

    const [enableAddObservacao, setEnableAddObservacao] = useState(true);
    return (
        <>
            {fields.map((observacao, index) => {
                return (
                    <Grid container item spacing={2} key={index}>
                        <Grid item xs={12} lg={2}>
                            <Controller
                                name={`observacoes.${index}.dataHoraCriacao`}
                                control={control}
                                defaultValue={''}
                                render={({
                                    field: {
                                        ref,
                                        onChange,
                                        onBlur,
                                        value,
                                        ...field
                                    },
                                    fieldState: { error },
                                }) => (
                                    <TextField
                                        {...field}
                                        margin="normal"
                                        fullWidth
                                        value={parseDate(value)}
                                        label="Hora do registro"
                                        error={!!error?.message}
                                        helperText={
                                            error?.message ? error?.message : ''
                                        }
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={onChange}
                                        disabled={true}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <Controller
                                name={`observacoes.${index}.usuario`}
                                control={control}
                                defaultValue={''}
                                render={({
                                    field: {
                                        ref,
                                        onChange,
                                        onBlur,
                                        value,
                                        ...field
                                    },
                                    fieldState: { error },
                                }) => (
                                    <TextField
                                        {...field}
                                        margin="normal"
                                        fullWidth
                                        value={value}
                                        label="Usuário"
                                        error={!!error?.message}
                                        helperText={
                                            error?.message ? error?.message : ''
                                        }
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={onChange}
                                        disabled={true}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Controller
                                name={`observacoes.${index}.descricao`}
                                control={control}
                                defaultValue={''}
                                render={({
                                    field: {
                                        ref,
                                        onChange,
                                        onBlur,
                                        value,
                                        ...field
                                    },
                                    fieldState: { error },
                                }) => (
                                    <TextField
                                        {...field}
                                        margin="normal"
                                        fullWidth
                                        value={value}
                                        label="Descrição"
                                        multiline
                                        error={!!error?.message}
                                        helperText={
                                            error?.message ? error?.message : ''
                                        }
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={onChange}
                                        disabled={true}
                                    />
                                )}
                            />
                        </Grid>
                        {!enableAddObservacao && index + 1 == fields.length && (
                            <Grid item xs={12} lg={1} mt={2.2}>
                                <Tooltip
                                    title="Cancelar Observação"
                                    placement="top"
                                    disableInteractive
                                >
                                    <IconButton
                                        aria-label="delete"
                                        onClick={() => {
                                            remove(index);
                                            setEnableAddObservacao(true);
                                        }}
                                        size="large"
                                    >
                                        <CancelIcon />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        )}
                    </Grid>
                );
            })}
            {enableAddObservacao && (
                <Grid container item spacing={2}>
                    <Grid item xs={12} lg={4}>
                        <Controller
                            name={`novaObservacao.descricao`}
                            control={control}
                            defaultValue={''}
                            render={({
                                field: {
                                    ref,
                                    onChange,
                                    onBlur,
                                    value,
                                    ...field
                                },
                                fieldState: { error },
                            }) => (
                                <TextField
                                    {...field}
                                    margin="normal"
                                    fullWidth
                                    value={value}
                                    label="Descrição (máximo 500 caracteres)"
                                    multiline
                                    error={!!error?.message}
                                    helperText={
                                        error?.message ? error?.message : ''
                                    }
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={onChange}
                                    inputProps={{
                                        maxLength: 500,
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <Button
                            onClick={() => {
                                append({
                                    id: null,
                                    usuario:
                                        auth?.usuarioLogado?.nome,
                                    descricao: getValues(
                                        'novaObservacao.descricao'
                                    ),
                                    dataHoraCriacao: new Date(),
                                });
                                setValue('novaObservacao.usuario', '');
                                setValue('novaObservacao.descricao', '');
                                setEnableAddObservacao(false);
                            }}
                            variant="contained"
                            style={styles().button}
                            size="large"
                        >
                            Adicionar
                        </Button>
                    </Grid>
                </Grid>
            )}
        </>
    );
};

export default ObservacoesForm;
