import Amostra from './Amostra';

export default class Produto {
    constructor(
        public id: number,
        public nome: string,
        public amostras: Amostra[],
        public transgenico: boolean
    ) {}
}
