import Contato from "./Contato";
import Usuario from "./Usuario";

export default class Classificador {
    constructor(
        public id: number,
        public nome: string,
        public usuario?: Usuario,
        public contato?: Contato,
        public cpf?: string,
        public cnpj?: string,
    ) {}
}
