import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
} from '@mui/material';
import type Usuario from '../../Models/Usuario';
import { styles } from './helpers/Styles';

import { ApiResources } from '../../Models/Api';
import useGenericService from '../../hooks/useGenericService';
import { useSnackbar } from 'notistack';

import DownloadIcon from '@mui/icons-material/Download';
import { format } from 'date-fns';
import { processFileDownload } from '../../utils/processarArquivo';

interface AuditoriaCamposUsuarioProps {
    usuarioAtual: Usuario;
    usuarioAnterior: Usuario;
}

export function AuditoriaCamposUsuario({
    usuarioAtual,
    usuarioAnterior,
}: AuditoriaCamposUsuarioProps) {
    const { getService } = useGenericService();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    async function handleBaixarTermosLGPDByAnexoId(id: number) {
        const servico = getService(ApiResources.TermosVigentesLGPD);

        try {
            const response = await servico.api.get(`/download/${id}`, {
                responseType: 'arraybuffer',
            });

            processFileDownload(
                response.data,
                response.headers['content-disposition']
            );
        } catch (error) {
            enqueueSnackbar('Erro ao fazer o download do termo', {
                variant: 'error',
            });
        }
    }

    return (
        <List sx={styles().listStyle}>
            {usuarioAtual?.pessoa?.nome !== usuarioAnterior?.pessoa?.nome && (
                <ListItem>
                    <ListItemText
                        primary="Nome"
                        secondary={
                            <div style={styles().listItemText}>
                                {usuarioAnterior?.pessoa?.nome}
                                <ArrowForwardIcon
                                    fontSize="small"
                                    style={styles().arrowForwardIcon}
                                />{' '}
                                {usuarioAtual?.pessoa?.nome}
                            </div>
                        }
                    />
                </ListItem>
            )}

            {usuarioAtual?.cpf !== usuarioAnterior?.cpf && (
                <>
                    <Divider variant="inset" component="li" />
                    <ListItem>
                        <ListItemText
                            primary="CPF"
                            secondary={
                                <div style={styles().listItemText}>
                                    {usuarioAnterior?.cpf}{' '}
                                    <ArrowForwardIcon
                                        fontSize="small"
                                        style={styles().arrowForwardIcon}
                                    />{' '}
                                    {usuarioAtual?.cpf}
                                </div>
                            }
                        />
                    </ListItem>
                </>
            )}

            {usuarioAtual?.cnpj !== usuarioAnterior?.cnpj && (
                <>
                    <Divider variant="inset" component="li" />
                    <ListItem>
                        <ListItemText
                            primary="CNPJ"
                            secondary={
                                <div style={styles().listItemText}>
                                    {usuarioAnterior?.cnpj}{' '}
                                    <ArrowForwardIcon
                                        fontSize="small"
                                        style={styles().arrowForwardIcon}
                                    />{' '}
                                    {usuarioAtual?.cnpj}
                                </div>
                            }
                        />
                    </ListItem>
                </>
            )}

            {usuarioAnterior?.id !== usuarioAtual?.id && (
                <>
                    <Divider variant="inset" component="li" />
                    <ListItem>
                        <ListItemText
                            primary="Usuário - ID"
                            secondary={
                                <div style={styles().listItemText}>
                                    {usuarioAnterior?.id}
                                    <ArrowForwardIcon
                                        fontSize="small"
                                        style={styles().arrowForwardIcon}
                                    />{' '}
                                    {usuarioAtual?.id}
                                </div>
                            }
                        />
                    </ListItem>
                </>
            )}

            {usuarioAnterior?.login !== usuarioAtual?.login && (
                <>
                    <Divider variant="inset" component="li" />
                    <ListItem>
                        <ListItemText
                            primary="Usuário - Login"
                            secondary={
                                <div style={styles().listItemText}>
                                    {usuarioAnterior?.login}
                                    <ArrowForwardIcon
                                        fontSize="small"
                                        style={styles().arrowForwardIcon}
                                    />{' '}
                                    {usuarioAtual?.login}
                                </div>
                            }
                        />
                    </ListItem>
                </>
            )}

            {usuarioAnterior?.senha !== usuarioAtual?.senha && (
                <>
                    <Divider variant="inset" component="li" />
                    <ListItem>
                        <ListItemText
                            primary="Usuário - Senha"
                            secondary={
                                <div style={styles().listItemText}>
                                    {usuarioAnterior?.senha}
                                    <ArrowForwardIcon
                                        fontSize="small"
                                        style={styles().arrowForwardIcon}
                                    />{' '}
                                    {usuarioAtual?.senha}
                                </div>
                            }
                        />
                    </ListItem>
                </>
            )}

            {usuarioAnterior?.trocarSenha !== usuarioAtual?.trocarSenha && (
                <>
                    <Divider variant="inset" component="li" />
                    <ListItem>
                        <ListItemText
                            primary="Usuário - Trocar Senha"
                            secondary={
                                <div style={styles().listItemText}>
                                    {String(usuarioAnterior?.trocarSenha)}
                                    <ArrowForwardIcon
                                        fontSize="small"
                                        style={styles().arrowForwardIcon}
                                    />{' '}
                                    {String(usuarioAtual?.trocarSenha)}
                                </div>
                            }
                        />
                    </ListItem>
                </>
            )}

            {usuarioAnterior?.pessoa?.contato?.email !==
                usuarioAtual?.pessoa?.contato?.email && (
                <>
                    <Divider variant="inset" component="li" />
                    <ListItem>
                        <ListItemText
                            primary="Contato - Email"
                            secondary={
                                <div style={styles().listItemText}>
                                    {usuarioAnterior?.pessoa?.contato?.email}
                                    <ArrowForwardIcon
                                        fontSize="small"
                                        style={styles().arrowForwardIcon}
                                    />{' '}
                                    {usuarioAtual?.pessoa?.contato?.email}
                                </div>
                            }
                        />
                    </ListItem>
                </>
            )}

            {usuarioAnterior?.pessoa?.contato?.telefone !==
                usuarioAtual?.pessoa?.contato?.telefone && (
                <>
                    <Divider variant="inset" component="li" />
                    <ListItem>
                        <ListItemText
                            primary="Contato - Telefone"
                            secondary={
                                <div style={styles().listItemText}>
                                    {usuarioAnterior?.pessoa?.contato?.telefone}
                                    <ArrowForwardIcon
                                        fontSize="small"
                                        style={styles().arrowForwardIcon}
                                    />{' '}
                                    {usuarioAtual?.pessoa?.contato?.telefone}
                                </div>
                            }
                        />
                    </ListItem>
                </>
            )}

            {usuarioAnterior?.pessoa?.contato?.celular !==
                usuarioAtual?.pessoa?.contato?.celular && (
                <>
                    <Divider variant="inset" component="li" />
                    <ListItem>
                        <ListItemText
                            primary="Contato - Celular"
                            secondary={
                                <div style={styles().listItemText}>
                                    {usuarioAnterior?.pessoa?.contato?.celular}
                                    <ArrowForwardIcon
                                        fontSize="small"
                                        style={styles().arrowForwardIcon}
                                    />{' '}
                                    {usuarioAtual?.pessoa?.contato?.celular}
                                </div>
                            }
                        />
                    </ListItem>
                </>
            )}

            { usuarioAtual?.termosLGPD?.length ?(
                    <>
                        <Typography variant="body2">Termo LGPD</Typography>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Versão</TableCell>
                                        <TableCell>Data/Hora</TableCell>
                                        <TableCell>Ação</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {usuarioAtual?.termosLGPD?.map((termoAtual, index) => {
                                        const isUltimoTermo = index === (usuarioAtual?.termosLGPD?.length && usuarioAtual.termosLGPD.length - 1);

                                        // Exibir apenas o último termo, ou o único termo aceito
                                        if ((usuarioAtual?.termosLGPD?.length && usuarioAtual.termosLGPD.length === 1) || isUltimoTermo) {
                                            return (
                                                <TableRow key={termoAtual.id}>
                                                    <TableCell>{termoAtual.termoVersao}</TableCell>
                                                    <TableCell>
                                                        {termoAtual?.dataHoraAceite &&
                                                            format(new Date(termoAtual?.dataHoraAceite), 'dd/MM/Y HH:mm').toString()}
                                                    </TableCell>
                                                    <TableCell>
                                                        {termoAtual?.termoVersao != null && (
                                                            <Tooltip title="Clique para baixar a versão do termo aceito">
                                                                <IconButton
                                                                    size="small"
                                                                    aria-label="download"
                                                                    onClick={() => {
																	    if (termoAtual?.termoVigente?.anexo?.id != null) {
																	    		handleBaixarTermosLGPDByAnexoId(
																	    			termoAtual
																	    				?.termoVigente
																	    				?.anexo
																	    				?.id,
																	    		);
																	    }																																																		
																	}}
                                                                >
                                                                    <DownloadIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        }

                                        // Caso contrário, não mostrar
                                        return null;
                                    })}
                                </TableBody>

                            </Table>
                        </TableContainer>
                    </>
                ) : null}
        </List>
    );
}
