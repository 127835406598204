import { PermissionGate } from '../../../Components/Navegacao/PermissionGate';
import { FrontEndPathResources } from '../../../Models/Api';
import { RecursosPerfisEnum } from '../../../Models/Usuario';
import { GetLoteEmbarqueByLoteEmbarqueTransportadoraId } from '../../LoteEmbarque/domain/usecases/GetLoteEmbarqueByLoteEmbarqueTransportadoraId';
import { LoteEmbarqueRepository } from '../../LoteEmbarque/infra/LoteEmbarqueRepository';
import { GetLancarDadosEmbarqueById } from '../domain/usecases/GetLancarDadosEmbarqueById';
import { GetOrdemColetaById } from '../domain/usecases/GetOrdemColetaById';
import { PostLancarDadosEmbarque } from '../domain/usecases/PostLancarDadosEmbarque';
import { OrdemColetaRepository } from '../infra/OrdemColetaRepository';
import LancarDadosEmbarque from '../pages/LancarDadosEmbarque';

export const OrdemColetaLancarDadosEmbarque: React.FC = () => {
    const ordemColetaRepository = new OrdemColetaRepository();

    const loteEmbarqueRepository = new LoteEmbarqueRepository();

    const postLancarDadosEmbarque = new PostLancarDadosEmbarque(
        ordemColetaRepository
    );

    const getLancarDadosEmbarqueById = new GetLancarDadosEmbarqueById(
        ordemColetaRepository
    );

    const getOrdemColetaById = new GetOrdemColetaById(
        ordemColetaRepository
    );

    const getLoteEmquerByTransportadoraId = new GetLoteEmbarqueByLoteEmbarqueTransportadoraId(loteEmbarqueRepository);

    return (
        <PermissionGate
            recurso={RecursosPerfisEnum.ORDEM_COLETA_CRIAR}
            redirect={FrontEndPathResources.OrdemColeta}
        >
            <LancarDadosEmbarque
                postLancarDadosEmbarque={postLancarDadosEmbarque}
                getLancarDadosEmbarqueById={getLancarDadosEmbarqueById}
                getOrdemColetaById={getOrdemColetaById}
                getLoteEmbarqueByLoteEmbarqueTransportadoraId={getLoteEmquerByTransportadoraId}
            />
        </PermissionGate>
    );
};
