import DownloadIcon from '@mui/icons-material/Download';
import {
    Button,
    Grid,
    IconButton,
    InputAdornment,
    TextField,
    Tooltip,
} from '@mui/material';
import type { FunctionComponent } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { styles } from '../../PerfilUsuario/helpers/Styles';
import type { IDoubleCheck } from '../entities/IDoubleCheck';
import Util from '../../../Helpers/Util';
import { ApiResources } from '../../../Models/Api';
import useGenericService from '../../../hooks/useGenericService';
import type { IArquivoEncoded } from '../../OrdemColeta/domain/entities/IArquivoEncoded';
import { processFileDownload } from '../../../utils/processarArquivo';

interface EmbarqueFormProps {}

const EmbarqueForm: FunctionComponent<EmbarqueFormProps> = () => {
    const { control, setValue, reset, getValues, watch } =
        useFormContext<IDoubleCheck>();
    const { getService } = useGenericService();

    async function handleBaixarDocumento(arquivo: IArquivoEncoded) {
        const anexo = arquivo?.anexo;
        if (anexo) {
            const service = getService(ApiResources.Anexo);
            const response = await service.api.get('/download', {
                params: {
                    id: anexo?.fmId,
                },
                responseType: 'arraybuffer'
                })
                processFileDownload(response.data, response.headers['content-disposition'], anexo?.mimeType, anexo?.fileName);
        }
    }
    return (
        <Grid container item spacing={2}>
            <Grid item xs={12} lg={2}>
                <Controller
                    name={"pesagem.tara"}
                    control={control}
                    render={({
                        field: { ref, value, onChange, ...field },
                        fieldState: { error },
                    }) => (
                        <TextField
                            {...field}
                            margin="normal"
                            fullWidth
                            value={Util.formatNumberWithKG(value)}
                            onChange={onChange}
                            label="Tara"
                            error={!!error?.message}
                            helperText={error?.message ? error?.message : ''}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={true}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment
                                        disableTypography
                                        position="end"
                                    >
                                        KG
                                    </InputAdornment>
                                ),
                            }}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} lg={2}>
                <Controller
                    name={"pesagem.pesoBruto"}
                    control={control}
                    render={({
                        field: { ref, value, onChange, ...field },
                        fieldState: { error },
                    }) => (
                        <TextField
                            {...field}
                            margin="normal"
                            fullWidth
                            value={Util.formatNumberWithKG(value)}
                            onChange={onChange}
                            label="Peso bruto"
                            error={!!error?.message}
                            helperText={error?.message ? error?.message : ''}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={true}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment
                                        disableTypography
                                        position="end"
                                    >
                                        KG
                                    </InputAdornment>
                                ),
                            }}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} lg={2}>
                <Controller
                    name={"pesagem.pesoLiquido"}
                    control={control}
                    render={({
                        field: { ref, value, onChange, ...field },
                        fieldState: { error },
                    }) => (
                        <TextField
                            {...field}
                            margin="normal"
                            fullWidth
                            value={Util.formatNumberWithKG(value)}
                            onChange={onChange}
                            label="Peso líquido"
                            error={!!error?.message}
                            helperText={error?.message ? error?.message : ''}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={true}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment
                                        disableTypography
                                        position="end"
                                    >
                                        KG
                                    </InputAdornment>
                                ),
                            }}
                        />
                    )}
                />
            </Grid>
            <Grid container item spacing={2}>
                <Grid item xs={12} lg={3}>
                    <Controller
                        name={"laudo"}
                        control={control}
                        render={({
                            field: { ref, value, onChange, ...field },
                            fieldState: { error },
                        }) => (
                            <TextField
                                {...field}
                                margin="normal"
                                disabled={true}
                                fullWidth
                                value={value ? value.nome : ''}
                                onChange={onChange}
                                label="Laudo"
                                error={!!error?.message}
                                helperText={
                                    error?.message ? error?.message : ''
                                }
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        )}
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                    lg={0.5}
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Tooltip title="Baixar" placement="top" disableInteractive>
                        <IconButton
                            aria-label="baixar"
                            onClick={() => {
                                const laudo = getValues('laudo');
                                if (laudo) {
                                    handleBaixarDocumento(laudo);
                                }
                            }}
                        >
                            <DownloadIcon fontSize="medium" />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item xs={12} lg={3}>
                    <Button
                        onClick={() => {
                            setValue('laudo', null);
                        }}
                        variant="contained"
                        style={styles().button}
                        size="large"
                    >
                        Excluir Laudo
                    </Button>
                </Grid>
            </Grid>
            <Grid container item spacing={2}>
                <Grid item xs={12} lg={3}>
                    <Controller
                        name={"ticket"}
                        control={control}
                        render={({
                            field: { ref, value, onChange, ...field },
                            fieldState: { error },
                        }) => (
                            <TextField
                                {...field}
                                margin="normal"
                                disabled={true}
                                fullWidth
                                value={value ? value.nome : ''}
                                onChange={onChange}
                                label="Ticket"
                                error={!!error?.message}
                                helperText={
                                    error?.message ? error?.message : ''
                                }
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        )}
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                    lg={0.5}
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Tooltip title="Baixar" placement="top" disableInteractive>
                        <IconButton
                            aria-label="baixar"
                            onClick={() => {
                                const ticket = getValues('ticket');
                                if (ticket) {
                                    handleBaixarDocumento(ticket);
                                }
                            }}
                        >
                            <DownloadIcon fontSize="medium" />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item xs={12} lg={3}>
                    <Button
                        onClick={() => {
                            setValue('ticket', null);
                        }}
                        variant="contained"
                        style={styles().button}
                        size="large"
                    >
                        Excluir Ticket
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default EmbarqueForm;
