import { RevisionType } from '../../../Models/Auditoria';
import { StyleSheet } from '../../../StyleSheet';

const getBorderColorRevisionType = (revisionType: RevisionType) => {
    switch (revisionType) {
        case RevisionType.Adicionado:
            return '#3AB4F2';
        case RevisionType.Modificado:
            return '#FEB139';
        case RevisionType.Deletado:
            return '#D61C4E';
    }
};

export const styles = (props?: any) => {
    return StyleSheet.create({
        button: {
            marginTop: 25,
            marginBottom: 15,
            backgroundColor: '#465f75',
        },
        alert: {
            marginTop: 15,
            borderColor: getBorderColorRevisionType(props),
        },
        listStyle: {
            width: '100%',
            // maxWidth: 360,
            bgcolor: 'background.paper',
        },
        listItemText: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
        },
        arrowForwardIcon: {
            marginLeft: 10,
            marginRight: 10,
        },
        cadStyle: { marginTop: 2, width: props ? 280 : 500 },
        grid: { marginTop: 20, marginBottom: 20 },
        formControlStyle: { marginTop: 25 },
        mensagemErrorStyle: {
            color: '#D94C4C',
            marginTop: -10,
            marginLeft: 14,
            fontSize: 12,
        },
        successMessage: {
            color: '#4CAF50',
            marginTop: -10,
            marginLeft: 14,
            fontSize: 12,
        },
        divCenter: { alignItems: 'center' },
        divider: { marginTop: 10, marginBottom: 15 },
    });
};
