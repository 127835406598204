import Anexo from "./Anexo";

export default class NotaFiscal {
    constructor(
        public embarque_id: number,
        public notaFiscalEncoded: any,
        public chave_nota?: string,
        public anexo?:Anexo
    ) {}
}
