import ArticleIcon from '@mui/icons-material/Article';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import {
    Box,
    Card,
    CardContent,
    Chip,
    Container,
    Divider,
    Grid,
    IconButton,
    Tooltip,
    Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { PermissionGate } from '../../Components/Navegacao/PermissionGate';
import PageTitle from '../../Components/PageTitle/PageTitle';
import GenericComplexTable, {
    ComplexColumn,
} from '../../Components/Table/GenericComplexTable';
import { ApiResources, FrontEndPathResources } from '../../Models/Api';
import Produto from '../../Models/Produto';
import Usuario, {
    ChavesArmazenamentoStorage,
    RecursosPerfisEnum,
    StatusRequisicao,
} from '../../Models/Usuario';
import LoginResponse from '../../Models/responses/LoginResponse';
import useGenericService from '../../hooks/useGenericService';
import { usePermission } from '../../hooks/usePermission';
import ModalHistoricoAlteracoes from './ModalHistoricoAlteracoes';
import GenericModalItensExcluidos from '../../Components/Modal/GenericModalItensExcluidos';
import { format } from 'date-fns';

export default function Listar() {
    const [produtoMostrarAuditoria, setProdutoMostrarAuditoria] =
        useState<Produto>();

    const [mostrarModalAuditoria, setMostrarModalAuditoria] =
        useState<boolean>(false);

    const [mostrarModalItensExcluidos, setMostrarModalItensExcluidos] =
        useState<boolean>(false);

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const { isAllowed: isPermitidoExcluir } = usePermission(
        RecursosPerfisEnum.PRODUTOS_EXCLUIR
    );

    const [produtos, setProdutos] = useState<Produto[]>();

    const { getService } = useGenericService();

    useEffect(() => {
        let loginResponse = localStorage.getItem(
            ChavesArmazenamentoStorage.LOGIN_RESPONSE
        );

        if (loginResponse) {
            const usuario: Usuario = (
                JSON.parse(loginResponse) as LoginResponse
            ).usuario;

            if (usuario.trocarSenha) {
                window.location.pathname = FrontEndPathResources.AlterarSenha;
            }
        }
    }, []);

    const desativarProduto = async (url: string) => {
        try {
            const servico = getService(ApiResources.Produto);

            const resposta = await servico.api.delete(url);

            if (resposta.status === StatusRequisicao.OK) {
                enqueueSnackbar('Produto desativado com sucesso', {
                    variant: 'success',
                    onClick: () => {
                        closeSnackbar();
                    },
                });
                navigate(FrontEndPathResources.Produto);
                // carregar();
            }
        } catch (error) {}
    };

    const carregar = async () => {
        const servico = getService(ApiResources.Produto);

        const resposta = await servico.api.get('');

        setProdutos(resposta?.data?.content);
    };

    const colunas: ComplexColumn[] = [
        {
            attribute: 'id',
            label: 'ID',
            id: 'id',
            sortable: true,
        },
        {
            attribute: 'nome',
            label: 'Nome',
            id: 'nome',
            sortable: true,
        },
        {
            attribute: 'transgenico',
            label: 'É transgênico?',
            id: 'transgenico',
            sortable: false,
            format: (row) => (row.transgenico ? 'Sim' : 'Não'),
        },
        {
            attribute: 'nome',
            label: 'Ações',
            id: 'acao',
            width: 200,
            format: (row) => {
                return (
                    <>
                        <Tooltip title="Ver" placement="top" disableInteractive>
                            <IconButton
                                aria-label="delete"
                                onClick={() => {
                                    navigate(
                                        FrontEndPathResources.Produto +
                                            '/ver/' +
                                            row.id
                                    );
                                }}
                            >
                                <RemoveRedEyeIcon />
                            </IconButton>
                        </Tooltip>
                        <PermissionGate
                            recurso={RecursosPerfisEnum.PRODUTOS_ALTERAR}
                            redirect={''}
                        >
                            <Tooltip
                                title="Alterar"
                                placement="top"
                                disableInteractive
                            >
                                <IconButton
                                    aria-label="edit"
                                    onClick={() => {
                                        navigate(
                                            FrontEndPathResources.Produto +
                                                '/alterar/' +
                                                row.id
                                        );
                                    }}
                                >
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>
                        </PermissionGate>
                        <PermissionGate
                            recurso={RecursosPerfisEnum.PRODUTOS_EXCLUIR}
                            redirect={''}
                        >
                            <Tooltip
                                title="Desativar"
                                placement="top"
                                disableInteractive
                            >
                                <IconButton
                                    aria-label="delete"
                                    onClick={() => {
                                        desativarProduto('/' + row.id);
                                    }}
                                >
                                    <CancelIcon />
                                </IconButton>
                            </Tooltip>
                        </PermissionGate>

                        <PermissionGate
                            recurso={
                                RecursosPerfisEnum.PRODUTOS_CONSULTAR_HISTORICO
                            }
                            redirect={''}
                        >
                            <Tooltip
                                title="Histórico de alterações"
                                placement="top"
                                disableInteractive
                            >
                                <IconButton
                                    aria-label="ver"
                                    onClick={() => {
                                        setProdutoMostrarAuditoria(
                                            row as Produto
                                        );
                                        setMostrarModalAuditoria(true);
                                    }}
                                >
                                    <ArticleIcon />
                                </IconButton>
                            </Tooltip>
                        </PermissionGate>
                    </>
                );
            },
        },
    ];

    function expandedRowContent(row: any) {
        return (
            <Box sx={{ marginTop: 5, marginBottom: 5 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={6}>
                        <Card>
                            <CardContent>
                                <Typography
                                    sx={{ fontSize: 14 }}
                                    color="text.secondary"
                                    gutterBottom
                                >
                                    Amostras
                                </Typography>
                                {(row as Produto).amostras.map((a) => {
                                    return (
                                        <Chip
                                            style={{ margin: 5 }}
                                            label={a.nome}
                                            color="primary"
                                            variant="outlined"
                                        />
                                    );
                                })}
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Box>
        );
    }
    
    //#inicio - itens excluidos
    const validarRestaurar = async()=>{
        return true;
    };
    
    const varColunas: ComplexColumn[] = [
        {
            attribute: 'id',
            label: 'ID',
            id: 'id',
            sortable: true,
        },
        {
            attribute: 'nome',
            label: 'Nome',
            id: 'nome',
            sortable: true,
        },
        {
            attribute: 'dataHoraDeletado',
            label: 'Data da exclusão',
            id: 'dataHoraDeletado',
            align: 'left',
            width: 150,
            format: (row) =>
                format(new Date(row.dataHoraDeletado), 'dd/MM/Y').toString(),
        }
    ];
    //#fim - itens excluidos

    return (
        <PermissionGate
            recurso={RecursosPerfisEnum.PRODUTOS_VER}
            redirect={FrontEndPathResources.Dashboard}
        >
            <Container maxWidth={false}>
                <PageTitle title="Produtos" />

                <Divider />

                <GenericModalItensExcluidos
                    aberto={mostrarModalItensExcluidos}
                    fecharClicado={() => {
                        setMostrarModalItensExcluidos(false);
                    }}
                    varNome="Produtos"
                    varServico={ApiResources.Produto}
                    varTipoClasse={typeof Produto}
                    varColunas={varColunas}
                    validarRestaurar={validarRestaurar}
                />
                
                <PermissionGate
                    recurso={RecursosPerfisEnum.PRODUTOS_CONSULTAR_HISTORICO}
                    redirect={''}
                >
                    {mostrarModalAuditoria && produtoMostrarAuditoria && (
                        <ModalHistoricoAlteracoes
                            key={'historico_alteracoes'}
                            produto={produtoMostrarAuditoria}
                            aberto={mostrarModalAuditoria}
                            fecharClicado={() => {
                                setMostrarModalAuditoria(false);
                            }}
                        />
                    )}
                </PermissionGate>
                <GenericComplexTable
                    service={getService(ApiResources.Produto)}
                    rows={produtos}
                    columnToSearch={'nome'}
                    columns={colunas}
                    createButtonText="Cadastrar novo"
                    linkCreateButtonText={
                        FrontEndPathResources.Produto + '/criar'
                    }
                    expandedRows
                    contentExpandedRow={expandedRowContent}
                    mostrarIconeItensExcluidos={isPermitidoExcluir}
                    onIconeItensExcluidosClicado={() => {
                        setMostrarModalItensExcluidos(true);
                    }}
                />
            </Container>
        </PermissionGate>
    );
}
