import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    List,
    ListItem,
    ListItemText,
} from '@mui/material';
import { useFormContext } from 'react-hook-form';
import Upload from '../../../../../../../Components/Upload/Upload';
import { IOrdemColetaValidacaoDescarga } from '../../../../../domain/dtos/IOrdemColetaValidacaoDescarga';

interface Props {
    onClose: () => void;
    open: boolean;
}

export default function ModalAnexarTicket({ onClose, open = false }: Props) {
    const { setValue, clearErrors, getValues } =
        useFormContext<IOrdemColetaValidacaoDescarga>();

    const nameValue = getValues('ticketDescarga')?.nome;

    return (
        <Dialog
            open={open}
            onClose={() => onClose()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">Anexar Ticket</DialogTitle>
            <DialogContent>
                <Box sx={{ bgcolor: 'white', p: 2 }}>
                    <Upload
                        setFile={(file) => {
                            let fileToLoad = file;
                            let fileReader = new FileReader();

                            fileReader.onload = function (fileLoadedEvent) {
                                file = fileLoadedEvent!.target!.result;

                                const result = {
                                    stringBase64: file,
                                    nome: fileToLoad.name,
                                    tipo: fileToLoad.type,
                                    anexo: fileToLoad.anexo
                                };

                                setValue('ticketDescarga', result);
                                clearErrors('ticketDescarga');
                            };
                            fileReader.readAsDataURL(fileToLoad);
                        }}
                        arquivosSuportados={'.pdf, .jpeg, .jpg'}
                        mensagemIsDragAtivo="Arraste o documento aqui."
                        mensagemPadrao="Araste o documento aqui ou clique para selecionar do
                        computador"
                    />
                    {nameValue ? (
                        <List>
                            <ListItem key={nameValue}>
                                <ListItemText primary={`${nameValue}`} />
                            </ListItem>
                        </List>
                    ) : (
                        <></>
                    )}
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClose()} autoFocus>
                    Fechar
                </Button>
            </DialogActions>
        </Dialog>
    );
}
