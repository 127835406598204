import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { Container, Divider, IconButton, Tooltip } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';

import ArticleIcon from '@mui/icons-material/Article';
import { useEffect, useState } from 'react';
import { PermissionGate } from '../../Components/Navegacao/PermissionGate';
import PageTitle from '../../Components/PageTitle/PageTitle';
import GenericComplexTable, {
    ComplexColumn,
} from '../../Components/Table/GenericComplexTable';
import { ApiResources, FrontEndPathResources } from '../../Models/Api';
import Transportadora from '../../Models/Transportadora';
import Usuario, {
    ChavesArmazenamentoStorage,
    RecursosPerfisEnum,
    StatusRequisicao,
} from '../../Models/Usuario';
import LoginResponse from '../../Models/responses/LoginResponse';
import useGenericService from '../../hooks/useGenericService';
import { usePermission } from '../../hooks/usePermission';
import ModalHistoricoAlteracoes from './ModalHistoricoAlteracoes';
import GenericModalItensExcluidos from '../../Components/Modal/GenericModalItensExcluidos';


export default function Listar() {
    const [transportadoraMostrarAuditoria, setTransportadoraMostrarAuditoria] =
        useState<Transportadora>();

    const [mostrarModalAuditoria, setMostrarModalAuditoria] =
        useState<boolean>(false);

    const [mostrarModalItensExcluidos, setMostrarModalItensExcluidos] =
        useState<boolean>(false);

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const { isAllowed: isPermitidoExcluir } = usePermission(
        RecursosPerfisEnum.TRANSPORTADORAS_EXCLUIR
    );

    const { getService } = useGenericService();

    const [transportadoras, setTransportadoras] = useState<Transportadora[]>();

    useEffect(() => {
        let loginResponse = localStorage.getItem(
            ChavesArmazenamentoStorage.LOGIN_RESPONSE
        );

        if (loginResponse) {
            const usuario: Usuario = (
                JSON.parse(loginResponse) as LoginResponse
            ).usuario;

            if (usuario.trocarSenha) {
                window.location.pathname = FrontEndPathResources.AlterarSenha;
            }
        }
    }, []);

    const desativarTransportador = async (url: string) => {
        try {
            const servico = getService(ApiResources.Transportadora);

            const resposta = await servico.api.delete(url);

            if (resposta.status === StatusRequisicao.OK) {
                enqueueSnackbar('Transportadora desativado com sucesso', {
                    variant: 'success',
                    onClick: () => {
                        closeSnackbar();
                    },
                });
                navigate(FrontEndPathResources.Transportadora);
                // carregar();
            }
        } catch (error) {}
    };

    const carregar = async () => {
        const servico = getService(ApiResources.Transportadora);

        const resposta = await servico.api.get('');

        setTransportadoras(resposta?.data?.content);
    };

    const colunas: ComplexColumn[] = [
        {
            attribute: 'id',
            label: 'ID',
            id: 'id',
            sortable: true,
        },
        {
            attribute: 'nome',
            label: 'Nome',
            id: 'nome',
            minWidth: 200,
            sortable: true,
        },
        {
            attribute: 'contato.email',
            label: 'E-mail',
            id: 'email',
            sortable: false,
            format: (row) =>
                row.contato && row.contato.email ? row.contato.email : '',
        },
        {
            attribute: 'contato.telefone',
            label: 'Telefone',
            id: 'telefone',
            sortable: false,
            format: (row) =>
                row.contato && row.contato.telefone ? row.contato.telefone : '',
        },
        {
            attribute: 'contato.celular',
            label: 'Celular',
            id: 'celular',
            sortable: false,
            format: (row) =>
                row.contato && row.contato.celular ? row.contato.celular : '',
        },
        {
            attribute: 'nome',
            label: 'Ações',
            id: 'acao',
            width: 200,
            format: (row) => {
                return (
                    <>
                        <Tooltip title="Ver" placement="top" disableInteractive>
                            <IconButton
                                aria-label="view"
                                onClick={() => {
                                    navigate(
                                        FrontEndPathResources.Transportadora +
                                            '/ver/' +
                                            row.id
                                    );
                                }}
                            >
                                <RemoveRedEyeIcon />
                            </IconButton>
                        </Tooltip>
                        <PermissionGate
                            recurso={RecursosPerfisEnum.TRANSPORTADORAS_ALTERAR}
                            redirect={''}
                        >
                            <Tooltip
                                title="Alterar"
                                placement="top"
                                disableInteractive
                            >
                                <IconButton
                                    aria-label="edit"
                                    onClick={() => {
                                        navigate(
                                            FrontEndPathResources.Transportadora +
                                                '/alterar/' +
                                                row.id
                                        );
                                    }}
                                >
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>
                        </PermissionGate>
                        <PermissionGate
                            recurso={RecursosPerfisEnum.TRANSPORTADORAS_EXCLUIR}
                            redirect={''}
                        >
                            <Tooltip
                                title="Desativar"
                                placement="top"
                                disableInteractive
                            >
                                <IconButton
                                    aria-label="delete"
                                    onClick={() => {
                                        desativarTransportador('/' + row.id);
                                    }}
                                >
                                    <CancelIcon />
                                </IconButton>
                            </Tooltip>
                        </PermissionGate>
                        <PermissionGate
                            recurso={
                                RecursosPerfisEnum.TRANSPORTADORAS_CONSULTAR_HISTORICO
                            }
                            redirect={''}
                        >
                            <Tooltip
                                title="Histórico de alterações"
                                placement="top"
                                disableInteractive
                            >
                                <IconButton
                                    aria-label="ver"
                                    onClick={() => {
                                        setTransportadoraMostrarAuditoria(
                                            row as Transportadora
                                        );
                                        setMostrarModalAuditoria(true);
                                    }}
                                >
                                    <ArticleIcon />
                                </IconButton>
                            </Tooltip>
                        </PermissionGate>
                    </>
                );
            },
        },
    ];

    //#inicio - itens excluidos
    const validarRestaurar = async()=>{
        return true;
    };
    
    const varColunas: ComplexColumn[] = [
        {
            attribute: 'id',
            label: 'ID',
            id: 'id',
            sortable: true,
        },
        {
            attribute: 'nome',
            label: 'Nome',
            id: 'nome',
            sortable: true,
        },
        {
            attribute: 'cnpj',
            label: 'CNPJ',
            id: 'cnpj',
            width: 200,
            sortable: false,
            format: (value: any) =>
                value.cnpj.replace(
                    /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                    '$1.$2.$3/$4.$5'
                ),
        }
    ];
    //#fim - itens excluidos

    return (
        <PermissionGate
            recurso={RecursosPerfisEnum.TRANSPORTADORAS_VER}
            redirect={FrontEndPathResources.Dashboard}
        >
            <Container maxWidth={false}>
                <PageTitle title="Transportadoras" />

                <Divider />

                <GenericModalItensExcluidos
                    aberto={mostrarModalItensExcluidos}
                    fecharClicado={() => {
                        setMostrarModalItensExcluidos(false);
                    }}
                    varNome="Transportadoras"
                    varServico={ApiResources.Transportadora}
                    varTipoClasse={typeof Transportadora}
                    varColunas={varColunas}
                    validarRestaurar={validarRestaurar}
                />

                <PermissionGate
                    recurso={
                        RecursosPerfisEnum.TRANSPORTADORAS_CONSULTAR_HISTORICO
                    }
                    redirect={''}
                >
                    {mostrarModalAuditoria &&
                        transportadoraMostrarAuditoria && (
                            <ModalHistoricoAlteracoes
                                key={'historico_alteracoes'}
                                transportadora={transportadoraMostrarAuditoria}
                                aberto={mostrarModalAuditoria}
                                fecharClicado={() => {
                                    setMostrarModalAuditoria(false);
                                }}
                            />
                        )}
                </PermissionGate>

                <GenericComplexTable
                    service={getService(ApiResources.Transportadora)}
                    rows={transportadoras}
                    columnToSearch={'nome'}
                    columns={colunas}
                    createButtonText="Cadastrar novo"
                    linkCreateButtonText={
                        FrontEndPathResources.Transportadora + '/criar'
                    }
                    mostrarIconeItensExcluidos={isPermitidoExcluir}
                    onIconeItensExcluidosClicado={() => {
                        setMostrarModalItensExcluidos(true);
                    }}
                />
            </Container>
        </PermissionGate>
    );
}
