import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    Typography,
} from '@mui/material';
import { useState } from 'react';
import { styles } from '../Configuracao/helpers/Styles';


export interface IAceiteLGPDProps {
    termoAceito: boolean;
}

interface Props {
    fecharClicado: () => void;
    aberto: boolean;
    enviarAceiteLGPD: (aceite: IAceiteLGPDProps) => Promise<void>;
    termoLgpdAceito: boolean | null;
    setTermoLgpdAceito: (termo: boolean | null) => void;
    handleBaixarTermosLGPD:() => Promise<void>; 
    arquivoBaixado: boolean;
}

export default function ModalAceiteLGPD({
    fecharClicado,
    aberto = false,
    enviarAceiteLGPD,
    setTermoLgpdAceito,
    handleBaixarTermosLGPD,
    arquivoBaixado
}: Props) {
    const [isLoadingAceite, setIsLoadingAceite] = useState<boolean>(false);
    const [isLoadingDownload, setIsLoadingDownload] = useState<boolean>(false);
    const [termosAceito, setTermosAceito] = useState<boolean>(false);

    const handleAceiteLGPD = async () => {
        try {
            setIsLoadingAceite(true);
            await enviarAceiteLGPD({ termoAceito: termosAceito });
            setTermoLgpdAceito(termosAceito);
        } catch (error) {
            console.error(error);
            fecharClicado();
        } finally{
            setIsLoadingAceite(false);
        }
    };

    const handleDownloadTermoVigente = async () => {
        try {
            setIsLoadingDownload(true);
            await handleBaixarTermosLGPD();
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoadingDownload(false);
        }
    }

    return (
        <Dialog
            open={aberto}
            onClose={() => fecharClicado()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title" sx={{ textAlign: 'center' }}>
                TERMO LGPD
            </DialogTitle>
            <DialogContent>
                <Typography>
                    Para prosseguir é necessário ler e aceitar o Termo LGPD e Política de Privacidade.
                </Typography>

                <Grid container spacing={1} my={2}>
                    <Grid item xs={12}>
                        <Button
                            style={isLoadingDownload ? {...styles.button, ...styles.buttonDisabled} : styles.button}
                            variant="contained"
                            onClick={handleDownloadTermoVigente}
                            autoFocus
                            aria-label="arquivo LGPD"
                            disabled={isLoadingDownload}
                        >
                            {isLoadingDownload ? 'Baixando...': arquivoBaixado
                                ? 'Arquivo Baixado'
                                : 'Baixar Arquivo LGPD'}
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl>
                            <FormGroup>
                                <FormControlLabel
                                    id="smart-check-box"
                                    control={
                                        <Checkbox
                                            checked={termosAceito}
                                            onChange={() =>
                                                setTermosAceito(!termosAceito)
                                            }
                                        />
                                    }
                                    label="Declaro que li e estou de acordo com o Termo LGPD e Política de Privacidade."
                                />
                            </FormGroup>
                        </FormControl>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    style={styles.button}
                    variant="contained"
                    color="secondary"
                    onClick={fecharClicado}
                    autoFocus
                    aria-label="Cancelar"
                >
                    Cancelar
                </Button>
                <Button
                    style={{...styles.button ,
                        ...((!termosAceito || !arquivoBaixado) ? styles.buttonDisabled : {})
                    }}
                    variant="contained"
                    color="primary"
                    type="submit"
                    onClick={handleAceiteLGPD}
                    aria-label="Confirmar"
                    disabled={!termosAceito || !arquivoBaixado || isLoadingAceite}
                    
                >
                    Confirmar
                </Button>
            </DialogActions>
        </Dialog>
    );
}
