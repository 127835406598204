import { joiResolver } from '@hookform/resolvers/joi';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {
    Alert,
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    Radio,
    RadioGroup,
    TextField,
} from '@mui/material';
import ptBR from 'date-fns/locale/pt-BR';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import DividerComp from '../../../Components/Divider/DividerComp';
import InputNumero from '../../../Components/Input/InputNumero';
import InputPlaca from '../../../Components/Input/InputPlaca';
import SelectCidades from '../../../Components/Select/SelectCidades';
import SelectConjuntoPneu, {
    TireSet,
} from '../../../Components/Select/SelectConjuntoPneu';
import SelectMarcasMaxys, {
    DeviceBrand,
} from '../../../Components/Select/SelectMarcasMaxys';
import SelectModelosMaxys, {
    DeviceModel,
} from '../../../Components/Select/SelectModelosMaxys';
import SelectTipoBau, {
    TipoCarroceria,
} from '../../../Components/Select/SelectTipoBau';
import SelectTipoVeiculo, {
    DeviceType,
} from '../../../Components/Select/SelectTipoVeiculo';
import SpinnerLoading from '../../../Components/SpinnerLoading/SpinnerLoading';
import ViaCepService from '../../../Helpers/ViaCepService';
import { ApiResources } from '../../../Models/Api';
import CidadeIBGE from '../../../Models/CidadeIBGE';
import { TipoPessoa } from '../../../Models/Pessoa';
import { StatusRequisicao } from '../../../Models/Usuario';
import useGenericService from '../../../hooks/useGenericService';
import { Juncao, VehicleForm } from '../types/Juncao';
import { stylesTabMotorista } from './helpers/stylesTabMotorista';
import useMarcaTipoModelo from './hooks/useMarcaTipoModelo';
import useVeiculoValidation from './hooks/useVeiculoValidation';

interface Props {
    aberto: boolean;
    carreta?: VehicleForm;
    placaJaAdicionada: string;
    documentoProprietarioSugestao?: string;
    gravarClicado: (veiculo: VehicleForm) => void;
    fecharClicado: () => void;
}

export type CamposFormulario = {
    Plate: string;
    Document: string;
    Name: string;
    Postcode: string;
    Address: string;
    Number: string;
    County: string;
    cidadeIbge: CidadeIBGE | null;
    cidadeIbgePlate: CidadeIBGE | null;
    Document3: string;
    DeviceBrand: DeviceBrand | null;
    DeviceModel: DeviceModel | null;
    DeviceType: DeviceType | null;
    Document4: string;
    Year: string;
    Document2: string;
    NameDocument2: string;
    DueDocument2: Date | null;

    Document5: string | null;
    Postcode5: string | null;
    Address5: string | null;
    Number5: string | null;
    Complement5: string | null;
    County5: string | null;
    cidadeIbge5: CidadeIBGE | null;

    TipoCarroceria: TipoCarroceria | null;
    TireSet: TireSet | null;
    Capacity: string | null;
};

export default function ModalCarreta({
    carreta,
    documentoProprietarioSugestao,
    gravarClicado,
    aberto,
    placaJaAdicionada,
    fecharClicado,
}: Props) {
    const [mensagemErroBuscaVeiculo, setMensagemErroBuscaVeiculo] =
        useState<string>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [tipoPessoaCavalo, setTipoPessoaCavalo] = useState<TipoPessoa>(
        TipoPessoa.Juridica
    );
    const [usuarioBuscouPlaca, setUsuarioBuscouPlaca] =
        useState<boolean>(false);

    const { validacao, validacaoComCnpj } =
        useVeiculoValidation(tipoPessoaCavalo);

    const serviceViaCep = new ViaCepService();
    const { getService } = useGenericService();

    const { getMarcaByCodigo, getModeloByCodigo, getTipoByCodigo } =
        useMarcaTipoModelo();

    const [regimeTributarioProprietario, setRegimeTributarioProprietario] =
        useState<string>('N');
    const [regimeTributarioLocatario, setRegimeTributarioLocatario] =
        useState<string>('N');

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const getClifor = async (document: string): Promise<Juncao | null> => {
        try {
            const servico = getService(ApiResources.Juncao);

            const respostaApi = await servico.api.get(
                '/buscar-motorista-maxys-atac/' + document.replace(/\D/g, '')
            );

            if (respostaApi.status === StatusRequisicao.OK) {
                return respostaApi.data;
            } else {
                return null;
            }
        } catch (error) {
            return null;
        }
    };

    const getCidade = async (
        ibgeCode: number | string
    ): Promise<CidadeIBGE | null> => {
        try {
            const servico = getService(ApiResources.Juncao);

            const respostaApi = await servico.api.get(
                'get-cidade-ibge/' + ibgeCode
            );

            if (respostaApi.status === StatusRequisicao.OK) {
                return respostaApi.data;
            } else {
                return null;
            }
        } catch (error) {
            return null;
        }
    };

    const buscarLocatario = async (document: string) => {
        try {
            const resposta: Juncao | null = await getClifor(document);

            if (resposta) {
                if (resposta.Driver) {
                    const driver = resposta.Driver;

                    if (driver.Document.length <= 11) {
                        setValue(
                            'Document5',
                            driver.Document.replace(
                                /(\d{3})(\d{3})(\d{3})(\d{2})/,
                                '$1.$2.$3-$4'
                            )
                        );
                    } else {
                        setValue(
                            'Document5',
                            driver.Document.replace(
                                /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                                '$1.$2.$3/$4-$5'
                            )
                        );

                        setRegimeTributarioLocatario(
                            driver.Adresses[0].TaxType!
                        );
                    }
                    setValue('NameDocument2', driver.Name);
                    setValue('Postcode5', driver.Adresses[0].Postcode);
                    setValue('Address5', driver.Adresses[0].Address);
                    setValue('County5', driver.Adresses[0].County);
                    setValue('Number5', driver.Adresses[0].Number);

                    const respostaCidade: CidadeIBGE | null = await getCidade(
                        driver.Adresses[0].City
                    );

                    if (respostaCidade) {
                        setValue('cidadeIbge5', respostaCidade);
                    }
                } else {
                    limparLocatario();
                }
            } else {
                limparLocatario();
            }
        } catch (e) {
            limparLocatario();
        }
    };

    const {
        control,
        handleSubmit,
        setValue,
        watch,
        reset,
        getValues,
        formState: { errors },
    } = useForm<CamposFormulario>({
        resolver: joiResolver(
            tipoPessoaCavalo === TipoPessoa.Fisica
                ? validacao
                : validacaoComCnpj
        ),
        criteriaMode: 'all',
    });

    const watchMarca = watch('DeviceBrand');
    const watchDocument = watch('Document');
    const watchDocument5 = watch('Document5');
    const watchPlate = watch('Plate');

    useEffect(() => {
        if (documentoProprietarioSugestao) {
            buscarProprietario(documentoProprietarioSugestao);
        }
    }, [documentoProprietarioSugestao]);

    const buscarProprietario = async (document: string) => {
        try {
            const servico = getService(ApiResources.Juncao);

            const respostaApi = await servico.api.get(
                '/buscar-motorista-maxys-atac/' + document.replace(/\D/g, '')
            );

            if (respostaApi.status === StatusRequisicao.OK) {
                const juncao: Juncao = respostaApi.data;

                const driver = juncao.Driver;

                if (driver.Document.length <= 11) {
                    setValue(
                        'Document',
                        driver.Document.replace(
                            /(\d{3})(\d{3})(\d{3})(\d{2})/,
                            '$1.$2.$3-$4'
                        )
                    );
                } else {
                    setValue(
                        'Document',
                        driver.Document.replace(
                            /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                            '$1.$2.$3/$4-$5'
                        )
                    );

                    setRegimeTributarioProprietario(
                        driver.Adresses[0].TaxType!
                    );
                }
                setValue('Name', driver.Name);
                setValue('Postcode', driver.Adresses[0].Postcode);
                setValue('Address', driver.Adresses[0].Address);
                setValue('County', driver.Adresses[0].County);
                setValue('Number', driver.Adresses[0].Number);

                try {
                    const respostaApi = await servico.api.get(
                        'get-cidade-ibge/' + driver.Adresses[0].City
                    );

                    if (respostaApi.status === StatusRequisicao.OK) {
                        setValue('cidadeIbge', respostaApi.data);
                    }
                } catch (error) {
                    limparProprietario();
                }
            } else {
                limparProprietario();
            }
        } catch (error) {
            limparProprietario();
        }
    };

    function converterStringParaData(dataString: string) {
        if (dataString === null || dataString === '') {
            return null; // Retorna null para valores nulos ou vazios
          }

        const partes = dataString.split('/');
        const ano = parseInt(partes[2], 10);
        const mes = parseInt(partes[1], 10) - 1;
        const dia = parseInt(partes[0], 10);
        const data = new Date(ano, mes, dia);

        return isNaN(data.getTime()) ? null : data; // Retorna null para datas inválidas
    }

    useEffect(() => {
        const carregarDados = async (carreta: VehicleForm) => {
            setIsLoading(true);

            if (carreta.Document.length === 14) {
                setTipoPessoaCavalo(TipoPessoa.Fisica);
            }

            const servico = getService(ApiResources.Juncao);

            try {
                const respostaApi = await servico.api.get(
                    'get-cidade-ibge/' + carreta.City
                );

                if (respostaApi.status === StatusRequisicao.OK) {
                    setValue('cidadeIbge', respostaApi.data);
                }
            } catch (error) {
                setIsLoading(false);
            }

            try {
                const respostaApi2 = await servico.api.get(
                    'get-cidade-ibge/' + carreta.CityPlate
                );

                if (respostaApi2.status === StatusRequisicao.OK) {
                    setValue('cidadeIbgePlate', respostaApi2.data);
                }
            } catch (error) {
                setIsLoading(false);
            }

            setValue('Plate', carreta.Plate);
            setValue('Name', carreta.Name);
            setValue('Postcode', carreta.Postcode);
            setValue('Number', carreta.Number);
            setValue('Address', carreta.Address);
            setValue('County', carreta.County);
            setValue('Document2', carreta.Document2);
            setValue('Document3', carreta.Document3);
            setValue('Document4', carreta.Document4);
            setValue('DeviceBrand', carreta.DeviceBrand);
            setValue('DeviceModel', carreta.DeviceModel);
            setValue('DeviceType', carreta.DeviceType);
            setValue('Year', carreta.Year);
            setValue('NameDocument2', carreta.NameDocument2);
            if (carreta.DueDocument2 != null) {
                setValue(
                    'DueDocument2',
                    converterStringParaData(carreta.DueDocument2)
                );
            } else {
                setValue(
                    'DueDocument2',
                    null
                );
            }
            setValue('Document', carreta.Document);
            setValue('Address5', carreta.Address5);
            setValue('Complement5', carreta.Complement5);
            setValue('County5', carreta.County5);
            setValue('Number5', carreta.Number5);
            setValue('Postcode5', carreta.Postcode5);

            setValue('Document5', carreta.Document5);

            setValue('Capacity', carreta.Capacity);
            setValue('TireSet', carreta.TireSet);
            setValue('TipoCarroceria', carreta.TipoCarroceria);

            setRegimeTributarioProprietario(carreta.TaxType);
            setRegimeTributarioLocatario(
                carreta.TaxType5 ? carreta.TaxType5 : 'N'
            );

            if (carreta.City5) {
                try {
                    const respostaApi2 = await servico.api.get(
                        'get-cidade-ibge/' + carreta.City5
                    );

                    if (respostaApi2.status === StatusRequisicao.OK) {
                        setValue('cidadeIbge5', respostaApi2.data);
                    }
                } catch (error) {
                    setIsLoading(false);
                }
            }
            setIsLoading(false);
        };

        if (carreta) {
            carregarDados(carreta);
        }
    }, [carreta, setValue]);

    const limparProprietario = () => {
        // setValue('Document', '');
        setValue('Name', '');
        setValue('Postcode', '');
        setValue('Address', '');
        setValue('County5', '');
        setValue('County', '');
        setValue('Number', '');
        setValue('cidadeIbge', null);
        setRegimeTributarioProprietario('N');
    };

    const limparLocatario = () => {
        //setValue('Document5', '');
        setValue('NameDocument2', '');
        setValue('Postcode5', '');
        setValue('Address5', '');
        setValue('County5', '');
        setValue('Number5', '');
        setValue('cidadeIbge5', null);
        setRegimeTributarioLocatario('N');
    };

    const buscarCep = async (cep: string) => {
        try {
            const resposta = await serviceViaCep.buscarEndereco(cep);

            if (
                resposta.status === StatusRequisicao.OK &&
                !resposta?.data?.erro
            ) {
                setValue('Address', resposta?.data?.logradouro);
                setValue('County', resposta?.data?.bairro);

                const servico = getService(ApiResources.Juncao);

                try {
                    const respostaApi = await servico.api.get(
                        'get-cidade-ibge/' + resposta?.data?.ibge
                    );

                    if (respostaApi.status === StatusRequisicao.OK) {
                        setValue('cidadeIbge', respostaApi.data);
                    }
                } catch (error) {}
            }
        } catch (error) {}
    };

    const buscarCepLocatario = async (cep: string) => {
        try {
            const resposta = await serviceViaCep.buscarEndereco(cep);

            if (
                resposta.status === StatusRequisicao.OK &&
                !resposta?.data?.erro
            ) {
                setValue('Address5', resposta?.data?.logradouro);
                setValue('County5', resposta?.data?.bairro);

                const servico = getService(ApiResources.Juncao);

                try {
                    const respostaApi = await servico.api.get(
                        'get-cidade-ibge/' + resposta?.data?.ibge
                    );

                    if (respostaApi.status === StatusRequisicao.OK) {
                        setValue('cidadeIbge5', respostaApi.data);
                    }
                } catch (error) {}
            }
        } catch (error) {}
    };

    const buscarPlaca = async () => {
        setIsLoading(true);
        let plate = getValues('Plate');

        if (plate) {
            if (placaJaAdicionada.toUpperCase() === plate.toUpperCase()) {
                enqueueSnackbar('Essa placa já foi adicionada no cavalo', {
                    variant: 'info',
                    onClick: () => {
                        closeSnackbar();
                    },
                });
                setIsLoading(false);
                return;
            }

            try {
                const servico = getService(ApiResources.Juncao);

                const respostaApi = await servico.api.get(
                    '/buscar-placa-maxys-atac/' + plate.replace('-', '')
                );

                //se trazer o veículo, realiza requisição pra pegar as cidades
                if (respostaApi.status === StatusRequisicao.OK) {
                    setUsuarioBuscouPlaca(true);
                    const veiculo = respostaApi.data.DeviceRegisters;

                    if (veiculo.Document.length === 11) {
                        setTipoPessoaCavalo(TipoPessoa.Fisica);
                    } else {
                        setTipoPessoaCavalo(TipoPessoa.Juridica);
                    }

                    setValue('County', veiculo.County);
                    setValue('Document3', veiculo.Document3);
                    setValue('Document4', veiculo.Document4);
                    setValue('Name', veiculo.Name);
                    setValue('NameDocument2', veiculo.NameDocument2);
                    setValue('Number', veiculo.Number);
                    setValue('Postcode', veiculo.Postcode);
                    setValue('Year', veiculo.Year.toString());
                    setValue('Document2', veiculo.Document2);
                    setValue('Address', veiculo.Address);
                    if (veiculo.DueDocument2 != null) {
                        setValue(
                            'DueDocument2',
                            converterStringParaData(veiculo.DueDocument2)
                        );
                    }

                    if (veiculo.Document.length <= 11) {
                        setValue(
                            'Document',
                            veiculo.Document.replace(
                                /(\d{3})(\d{3})(\d{3})(\d{2})/,
                                '$1.$2.$3-$4'
                            )
                        );
                    } else {
                        setValue(
                            'Document',
                            veiculo.Document.replace(
                                /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                                '$1.$2.$3/$4-$5'
                            )
                        );
                    }
                    if (veiculo.TireSets && veiculo.TireSets.TireSet) {
                        setValue('TireSet', veiculo.TireSets);
                    }

                    setValue('TipoCarroceria', veiculo.tiposCarroceria);

                    setValue('Capacity', veiculo.Capacity.toString());

                    getMarcaByCodigo(veiculo.DeviceBrand).then((retorno) => {
                        setValue('DeviceBrand', retorno);
                    });

                    getModeloByCodigo(
                        veiculo.DeviceModel,
                        veiculo.DeviceBrand
                    ).then((retorno) => {
                        setValue('DeviceModel', retorno);
                    });

                    getTipoByCodigo(veiculo.DeviceType).then((retorno) => {
                        setValue('DeviceType', retorno);
                    });

                    setValue('Address5', veiculo.Address5);
                    setValue('Complement5', veiculo.Complement5);
                    setValue('County5', veiculo.County5);
                    setValue('Number5', veiculo.Number5);
                    setValue('Postcode5', veiculo.Postcode5);

                    if (veiculo.City5) {
                        try {
                            const respostaApi2 = await servico.api.get(
                                'get-cidade-ibge/' + veiculo.City5
                            );

                            if (respostaApi2.status === StatusRequisicao.OK) {
                                setValue('cidadeIbge5', respostaApi2.data);
                            }
                        } catch (error) {
                            setIsLoading(false);
                        }
                    }

                    if (veiculo.Document5.length <= 11) {
                        setValue(
                            'Document5',
                            veiculo.Document5.replace(
                                /(\d{3})(\d{3})(\d{3})(\d{2})/,
                                '$1.$2.$3-$4'
                            )
                        );
                    } else {
                        setValue(
                            'Document5',
                            veiculo.Document5.replace(
                                /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                                '$1.$2.$3/$4-$5'
                            )
                        );
                    }

                    try {
                        const respostaApi = await servico.api.get(
                            'get-cidade-ibge/' + veiculo.City
                        );

                        if (respostaApi.status === StatusRequisicao.OK) {
                            setValue('cidadeIbge', respostaApi.data);
                        }
                    } catch (error) {}

                    try {
                        const respostaApi = await servico.api.get(
                            'get-cidade-ibge/' + veiculo.CityPlate
                        );

                        if (respostaApi.status === StatusRequisicao.OK) {
                            setValue('cidadeIbgePlate', respostaApi.data);
                        }
                    } catch (error) {}
                }
                setMensagemErroBuscaVeiculo(undefined);
            } catch (error) {
                setMensagemErroBuscaVeiculo(
                    'Veículo não encontrado, você pode cadastrar um novo.'
                );
                setUsuarioBuscouPlaca(true);
                resetarCampos();
                setValue('Plate', plate);
                setIsLoading(false);
            }
        }
        setIsLoading(false);
    };

    const handleTabPress = (event: any) => {
        if (event.key === 'Tab') {
            event.preventDefault(); // Impede o comportamento padrão do Tab

            // Encontra o próximo input usando o atributo `tabIndex`
            const inputs = document.getElementsByTagName('input');
            const index = Array.from(inputs).indexOf(event.target);
            const nextInput = inputs[index + 1];

            // Foca no próximo input, se existir
            if (nextInput) {
                nextInput.focus();
            }
        }
    };

    const resetarCampos = () => {
        if (documentoProprietarioSugestao) {
            // reset({});
        } else {
            reset({Document: '', Document5: ''});
        }
    };

    const getDataFormatadaEnvioApi = (data: Date) => {
        const day = data.getDate().toString().padStart(2, '0');
        const month = (data.getMonth() + 1).toString().padStart(2, '0');
        const year = data.getFullYear().toString();

        return `${day}/${month}/${year}`;
    };

    const hasErrosLocatario = (dadosForm: CamposFormulario) => {
        if (
            dadosForm.Address5 ||
            dadosForm.County5 ||
            dadosForm.Document5 ||
            dadosForm.NameDocument2 ||
            dadosForm.Number5 ||
            dadosForm.Postcode5
        ) {
            if (
                !(
                    dadosForm.Address5 &&
                    dadosForm.County5 &&
                    dadosForm.Document5 &&
                    dadosForm.NameDocument2 &&
                    dadosForm.Number5 &&
                    dadosForm.Postcode5
                )
            ) {
                enqueueSnackbar(
                    'Finalize o preenchimento dos campos do locatário!',
                    {
                        variant: 'warning',
                        onClick: () => {
                            closeSnackbar();
                        },
                    }
                );
                return true;
            }
        }

        return false;
    };

    const tratarDados = (dadosForm: CamposFormulario) => {
        if (hasErrosLocatario(dadosForm)) {
            setIsLoading(false);
            return;
        }

        let veiculoTratado: VehicleForm = {
            CityPlate: Number(dadosForm.cidadeIbgePlate!.idIbge),
            City: Number(dadosForm.cidadeIbge!.idIbge),
            Address: dadosForm.Address.toUpperCase(),
            County: dadosForm.County.toUpperCase(),
            DeviceBrand: dadosForm.DeviceBrand,
            DeviceModel: dadosForm.DeviceModel,
            DeviceType: dadosForm.DeviceType,
            Document: dadosForm.Document,
            Document2: dadosForm.Document2,
            Document3: dadosForm.Document3,
            Document4: dadosForm.Document4,
            DueDocument2: dadosForm.DueDocument2
                ? getDataFormatadaEnvioApi(dadosForm.DueDocument2!)
                : null,
            Name: dadosForm.Name.toUpperCase(),
            NameDocument2: dadosForm.NameDocument2
                ? dadosForm.NameDocument2.toUpperCase()
                : '',
            Number: dadosForm.Number,
            Plate: dadosForm.Plate.toUpperCase(),
            Postcode: dadosForm.Postcode,
            Year: dadosForm.Year,
            Address5: dadosForm.Address5
                ? dadosForm.Address5.toUpperCase()
                : null,
            City5: dadosForm.cidadeIbge5
                ? Number(dadosForm.cidadeIbge5!.idIbge)
                : null,
            Complement5: dadosForm.Complement5
                ? dadosForm.Complement5.toUpperCase()
                : null,
            County5: dadosForm.County5 ? dadosForm.County5.toUpperCase() : null,
            Document5: dadosForm.Document5 ? dadosForm.Document5 : null,
            Number5: dadosForm.Number5 ? dadosForm.Number5 : null,
            Postcode5: dadosForm.Postcode5 ? dadosForm.Postcode5 : null,

            TaxType: regimeTributarioProprietario!,
            TaxType5: regimeTributarioLocatario
                ? regimeTributarioLocatario
                : null,
            Capacity: dadosForm.Capacity,
            TipoCarroceria: dadosForm.TipoCarroceria,
            TireSet: dadosForm.TireSet,
        };

        gravarClicado(veiculoTratado);
    };

    return (
        <Dialog open={aberto} onClose={() => fecharClicado()} maxWidth="xl">
            <DialogTitle id="alert-dialog-title">Adicionar carreta</DialogTitle>
            <DialogContent>
                <SpinnerLoading isLoading={isLoading}>
                    <Box sx={{ width: 1200, typography: 'body1' }}>
                        <form
                            noValidate
                            autoComplete="off"
                            // onSubmit={handleSubmit(tratarDados)}
                        >
                            <Grid container spacing={2}>
                                <Grid item xs={12} lg={3}>
                                    <Controller
                                        name="Plate"
                                        control={control}
                                        defaultValue={''}
                                        render={({
                                            field: { ref, ...field },
                                            fieldState: { error },
                                        }) => {
                                            return (
                                                <InputPlaca
                                                    {...field}
                                                    inputRef={ref}
                                                    required
                                                    value={field.value}
                                                    margin="normal"
                                                    autoComplete="new-password"
                                                    inputProps={{
                                                        style: {
                                                            textTransform:
                                                                'uppercase',
                                                        },
                                                    }}
                                                    // onBlur={(event) => {
                                                    //     buscarPlaca();
                                                    // }}
                                                    fullWidth
                                                    label="Placa"
                                                    error={!!errors.Plate}
                                                    helperText={
                                                        errors.Plate
                                                            ? errors.Plate
                                                                  .message
                                                            : ''
                                                    }
                                                />
                                            );
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={5}>
                                    <Button
                                        type="button"
                                        variant="contained"
                                        style={stylesTabMotorista().button}
                                        size="large"
                                        disabled={
                                            !watchPlate || watchPlate.length < 7
                                        }
                                        onClick={() => {
                                            buscarPlaca();
                                        }}
                                    >
                                        Buscar
                                    </Button>
                                </Grid>
                            </Grid>
                            {mensagemErroBuscaVeiculo && (
                                <Grid container spacing={2}>
                                    <Grid
                                        item
                                        xs={12}
                                        lg={12}
                                        style={{ marginTop: '16px' }}
                                    >
                                        <Alert
                                            variant="outlined"
                                            severity="error"
                                            style={{ marginTop: 15 }}
                                        >
                                            {mensagemErroBuscaVeiculo}
                                        </Alert>
                                    </Grid>
                                </Grid>
                            )}

                            <Grid container spacing={2}>
                                <Grid
                                    item
                                    xs={12}
                                    lg={2}
                                    style={{ marginTop: '16px' }}
                                >
                                    <Controller
                                        name="Document"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                type="text"
                                                label="CPF/CNPJ do proprietário"
                                                onBlur={() => {
                                                    if (
                                                        (watchDocument &&
                                                            watchDocument.length ===
                                                                14) ||
                                                        watchDocument?.length ===
                                                            18
                                                    ) {
                                                        buscarProprietario(
                                                            watchDocument
                                                        );
                                                    }
                                                }}
                                                autoComplete="new-password"
                                                disabled={
                                                    !usuarioBuscouPlaca &&
                                                    !carreta
                                                }
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                error={!!errors.Document}
                                                helperText={
                                                    errors.Document
                                                        ? (
                                                              errors.Document as any
                                                          ).message
                                                        : ''
                                                }
                                                inputProps={{ maxLength: 18 }}
                                                onChange={(e) => {
                                                    const { value } = e.target;
                                                    let formattedValue =
                                                        value.replace(
                                                            /\D/g,
                                                            ''
                                                        );
                                                    if (
                                                        formattedValue.length <=
                                                        11
                                                    ) {
                                                        formattedValue =
                                                            formattedValue.replace(
                                                                /(\d{3})(\d{3})(\d{3})(\d{2})/,
                                                                '$1.$2.$3-$4'
                                                            );
                                                    } else {
                                                        formattedValue =
                                                            formattedValue.replace(
                                                                /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                                                                '$1.$2.$3/$4-$5'
                                                            );
                                                    }
                                                    field.onChange(
                                                        formattedValue
                                                    );
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={7}>
                                    <Controller
                                        name="Name"
                                        control={control}
                                        defaultValue={''}
                                        render={({
                                            field: { ref, ...field },
                                            fieldState: { error },
                                        }) => {
                                            return (
                                                <TextField
                                                    {...field}
                                                    inputRef={ref}
                                                    margin="normal"
                                                    autoComplete="new-password"
                                                    required
                                                    fullWidth
                                                    disabled={
                                                        !usuarioBuscouPlaca &&
                                                        !carreta
                                                    }
                                                    inputProps={{
                                                        maxLength: 150,
                                                        style: {
                                                            textTransform:
                                                                'uppercase',
                                                        },
                                                    }}
                                                    onChange={(e) => {
                                                        e.target.value =
                                                            e.target.value.replace(/['",\\]/g, '');

                                                        field.onChange(e);
                                                    }}
                                                    label="Nome"
                                                    error={!!errors.Name}
                                                    helperText={
                                                        errors.Name
                                                            ? errors.Name
                                                                  .message
                                                            : ''
                                                    }
                                                />
                                            );
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={3}>
                                    <Controller
                                        name="Postcode"
                                        control={control}
                                        defaultValue={''}
                                        render={({
                                            field: { ref, ...field },
                                            fieldState: { error },
                                        }) => {
                                            return (
                                                <TextField
                                                    {...field}
                                                    inputRef={ref}
                                                    margin="normal"
                                                    autoComplete="new-password"
                                                    fullWidth
                                                    disabled={
                                                        !usuarioBuscouPlaca &&
                                                        !carreta
                                                    }
                                                    inputProps={{
                                                        maxLength: 8,
                                                    }}
                                                    label="CEP do proprietário"
                                                    onChange={(
                                                        event: React.ChangeEvent<
                                                            | HTMLTextAreaElement
                                                            | HTMLInputElement
                                                        >
                                                    ) => {
                                                        event.currentTarget.value =
                                                            event.currentTarget.value =
                                                                event.currentTarget.value.replace(
                                                                    /[^0-9]/g,
                                                                    ''
                                                                );

                                                        field.onChange(event);
                                                    }}
                                                    onBlur={(event) => {
                                                        buscarCep(
                                                            event.target.value
                                                        );
                                                    }}
                                                    error={!!errors.Postcode}
                                                    helperText={
                                                        errors.Postcode
                                                            ? errors.Postcode
                                                                  .message
                                                            : ''
                                                    }
                                                    data-id="campoCepOrigem"
                                                />
                                            );
                                        }}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={2}>
                                <Grid item xs={12} lg={5}>
                                    <Controller
                                        name="Address"
                                        control={control}
                                        defaultValue={''}
                                        render={({
                                            field: { ref, ...field },
                                            fieldState: { error },
                                        }) => {
                                            return (
                                                <TextField
                                                    {...field}
                                                    inputRef={ref}
                                                    margin="normal"
                                                    autoComplete="new-password"
                                                    fullWidth
                                                    disabled={
                                                        !usuarioBuscouPlaca &&
                                                        !carreta
                                                    }
                                                    inputProps={{
                                                        style: {
                                                            textTransform:
                                                                'uppercase',
                                                        },
                                                    }}
                                                    onChange={(e) => {
                                                        e.target.value =
                                                            e.target.value.replace(/['",\\]/g, '');

                                                        field.onChange(e);
                                                    }}
                                                    label="Logradouro do proprietário"
                                                    error={!!errors.Address}
                                                    helperText={
                                                        errors.Address
                                                            ? errors.Address
                                                                  .message
                                                            : ''
                                                    }
                                                    data-id="campoLogradouroOrigem"
                                                />
                                            );
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={2}>
                                    <Controller
                                        name="Number"
                                        control={control}
                                        defaultValue={''}
                                        render={({
                                            field: { ref, ...field },
                                            fieldState: { error },
                                        }) => {
                                            return (
                                                <InputNumero
                                                    {...field}
                                                    margin="normal"
                                                    inputRef={ref}
                                                    autoComplete="new-password"
                                                    fullWidth
                                                    disabled={
                                                        !usuarioBuscouPlaca &&
                                                        !carreta
                                                    }
                                                    maxLength={7}
                                                    required
                                                    label="Número"
                                                    error={!!errors.Number}
                                                    helperText={
                                                        errors.Number
                                                            ? errors.Number
                                                                  .message
                                                            : ''
                                                    }
                                                    data-id="campoNumeroOrigem"
                                                />
                                            );
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={5}>
                                    <Controller
                                        name="County"
                                        control={control}
                                        defaultValue={''}
                                        render={({
                                            field: { ref, ...field },
                                            fieldState: { error },
                                        }) => {
                                            return (
                                                <TextField
                                                    {...field}
                                                    inputRef={ref}
                                                    margin="normal"
                                                    autoComplete="new-password"
                                                    fullWidth
                                                    disabled={
                                                        !usuarioBuscouPlaca &&
                                                        !carreta
                                                    }
                                                    inputProps={{
                                                        style: {
                                                            textTransform:
                                                                'uppercase',
                                                        },
                                                    }}
                                                    onChange={(e) => {
                                                        e.target.value =
                                                            e.target.value.replace(/['",\\]/g, '');

                                                        field.onChange(e);
                                                    }}
                                                    label="Bairro"
                                                    error={!!errors.County}
                                                    helperText={
                                                        errors.County
                                                            ? errors.County
                                                                  .message
                                                            : ''
                                                    }
                                                    data-id="campoBairroOrigem"
                                                />
                                            );
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid
                                    item
                                    xs={12}
                                    lg={6}
                                    style={{ marginTop: '16px' }}
                                >
                                    <Controller
                                        control={control}
                                        name="cidadeIbge"
                                        defaultValue={null}
                                        render={(props) => {
                                            return (
                                                <SelectCidades
                                                    {...props}
                                                    options={[]}
                                                    inputRef={props.field.ref}
                                                    label={
                                                        'Cidade do proprietário'
                                                    }
                                                    required
                                                    disabled={
                                                        !usuarioBuscouPlaca &&
                                                        !carreta
                                                    }
                                                    onChange={(
                                                        _,
                                                        cidadeIbge
                                                    ) => {
                                                        props.field.onChange(
                                                            cidadeIbge
                                                        );
                                                    }}
                                                    fullWidth
                                                    error={!!errors.cidadeIbge}
                                                    helperText={
                                                        errors.cidadeIbge
                                                            ? (
                                                                  errors.cidadeIbge as any
                                                              ).message
                                                            : 'Pesquise pelo nome da cidade (min 3 letras)'
                                                    }
                                                    data-id="selectProduto"
                                                />
                                            );
                                        }}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    lg={6}
                                    style={{ marginTop: '16px' }}
                                >
                                    <Controller
                                        control={control}
                                        name="cidadeIbgePlate"
                                        defaultValue={null}
                                        render={(props) => {
                                            return (
                                                <SelectCidades
                                                    {...props}
                                                    options={[]}
                                                    inputRef={props.field.ref}
                                                    label={
                                                        'Cidade de registro do veículo'
                                                    }
                                                    required
                                                    disabled={
                                                        !usuarioBuscouPlaca &&
                                                        !carreta
                                                    }
                                                    onChange={(
                                                        _,
                                                        cidadeIbgePlate
                                                    ) => {
                                                        props.field.onChange(
                                                            cidadeIbgePlate
                                                        );
                                                    }}
                                                    fullWidth
                                                    error={
                                                        !!errors.cidadeIbgePlate
                                                    }
                                                    helperText={
                                                        errors.cidadeIbgePlate
                                                            ? (
                                                                  errors.cidadeIbgePlate as any
                                                              ).message
                                                            : 'Pesquise pelo nome da cidade (min 3 letras)'
                                                    }
                                                    data-id="selectProduto"
                                                />
                                            );
                                        }}
                                    />
                                </Grid>
                            </Grid>

                            {watchDocument && watchDocument.length === 18 && (
                                <Grid container spacing={2}>
                                    <Grid item xs={12} lg={5}>
                                        <FormControl>
                                            <FormLabel id="demo-row-radio-buttons-group-label">
                                                Regime tributário do
                                                Proprietário
                                            </FormLabel>
                                            <RadioGroup
                                                row
                                                onChange={(
                                                    event: React.ChangeEvent<HTMLInputElement>
                                                ) => {
                                                    setRegimeTributarioProprietario(
                                                        (
                                                            event.target as HTMLInputElement
                                                        ).value
                                                    );
                                                }}
                                                value={
                                                    regimeTributarioProprietario
                                                }
                                            >
                                                <FormControlLabel
                                                    value="N"
                                                    control={<Radio />}
                                                    label="Normal"
                                                />
                                                <FormControlLabel
                                                    value="S"
                                                    control={<Radio />}
                                                    label="Simples Nacional"
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            )}

                            <Grid container spacing={2}>
                                <Grid item xs={12} lg={5}>
                                    <Controller
                                        name="Document3"
                                        control={control}
                                        defaultValue={''}
                                        render={({
                                            field: { ref, ...field },
                                            fieldState: { error },
                                        }) => {
                                            return (
                                                <InputNumero
                                                    {...field}
                                                    inputRef={ref}
                                                    margin="normal"
                                                    autoComplete="new-password"
                                                    required
                                                    disabled={
                                                        !usuarioBuscouPlaca &&
                                                        !carreta
                                                    }
                                                    fullWidth
                                                    maxLength={11}
                                                    label="Renavam"
                                                    error={!!errors.Document3}
                                                    helperText={
                                                        errors.Document3
                                                            ? errors.Document3
                                                                  .message
                                                            : ''
                                                    }
                                                />
                                            );
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid
                                    item
                                    xs={12}
                                    lg={4}
                                    style={{ marginTop: '16px' }}
                                >
                                    <Controller
                                        control={control}
                                        name="DeviceBrand"
                                        defaultValue={null}
                                        render={(props) => {
                                            return (
                                                <SelectMarcasMaxys
                                                    {...props}
                                                    options={[]}
                                                    inputRef={props.field.ref}
                                                    label={'Marca'}
                                                    required
                                                    disabled={
                                                        !usuarioBuscouPlaca &&
                                                        !carreta
                                                    }
                                                    onChange={(
                                                        _,
                                                        DeviceBrand
                                                    ) => {
                                                        props.field.onChange(
                                                            DeviceBrand
                                                        );
                                                    }}
                                                    fullWidth
                                                    error={!!errors.DeviceBrand}
                                                    helperText={
                                                        errors.DeviceBrand
                                                            ? (
                                                                  errors.DeviceBrand as any
                                                              ).message
                                                            : ''
                                                    }
                                                />
                                            );
                                        }}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    lg={4}
                                    style={{ marginTop: '16px' }}
                                >
                                    <Controller
                                        control={control}
                                        name="DeviceModel"
                                        defaultValue={null}
                                        render={(props) => {
                                            return (
                                                <SelectModelosMaxys
                                                    {...props}
                                                    options={[]}
                                                    disabled={
                                                        !usuarioBuscouPlaca &&
                                                        !carreta
                                                    }
                                                    inputRef={props.field.ref}
                                                    marca={
                                                        watchMarca
                                                            ? watchMarca.DeviceBrand
                                                            : undefined
                                                    }
                                                    label={'Modelo'}
                                                    required
                                                    onChange={(
                                                        _,
                                                        DeviceModel
                                                    ) => {
                                                        props.field.onChange(
                                                            DeviceModel
                                                        );
                                                    }}
                                                    fullWidth
                                                    error={!!errors.DeviceModel}
                                                    helperText={
                                                        errors.DeviceModel
                                                            ? (
                                                                  errors.DeviceModel as any
                                                              ).message
                                                            : ''
                                                    }
                                                />
                                            );
                                        }}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    lg={4}
                                    style={{ marginTop: '16px' }}
                                >
                                    <Controller
                                        control={control}
                                        name="DeviceType"
                                        defaultValue={null}
                                        render={(props) => {
                                            return (
                                                <SelectTipoVeiculo
                                                    {...props}
                                                    options={[]}
                                                    disabled={
                                                        !usuarioBuscouPlaca &&
                                                        !carreta
                                                    }
                                                    inputRef={props.field.ref}
                                                    label={'Tipo do veículo'}
                                                    required
                                                    onChange={(
                                                        _,
                                                        DeviceType
                                                    ) => {
                                                        props.field.onChange(
                                                            DeviceType
                                                        );
                                                    }}
                                                    fullWidth
                                                    error={!!errors.DeviceType}
                                                    helperText={
                                                        errors.DeviceType
                                                            ? (
                                                                  errors.DeviceType as any
                                                              ).message
                                                            : ''
                                                    }
                                                />
                                            );
                                        }}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={2}>
                                <Grid
                                    item
                                    xs={12}
                                    lg={4}
                                    style={{ marginTop: '16px' }}
                                >
                                    <Controller
                                        control={control}
                                        name="TipoCarroceria"
                                        defaultValue={null}
                                        render={(props) => {
                                            return (
                                                <SelectTipoBau
                                                    {...props}
                                                    options={[]}
                                                    inputRef={props.field.ref}
                                                    label={'Tipo da Carroceria'}
                                                    required
                                                    disabled={
                                                        !usuarioBuscouPlaca &&
                                                        !carreta
                                                    }
                                                    onChange={(
                                                        _,
                                                        TipoCarroceria
                                                    ) => {
                                                        props.field.onChange(
                                                            TipoCarroceria
                                                        );
                                                    }}
                                                    fullWidth
                                                    error={
                                                        !!errors.TipoCarroceria
                                                    }
                                                    helperText={
                                                        errors.TipoCarroceria
                                                            ? (
                                                                  errors.TipoCarroceria as any
                                                              ).message
                                                            : ''
                                                    }
                                                />
                                            );
                                        }}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    lg={5}
                                    style={{ marginTop: '16px' }}
                                >
                                    <Controller
                                        control={control}
                                        name="TireSet"
                                        defaultValue={null}
                                        render={(props) => {
                                            return (
                                                <SelectConjuntoPneu
                                                    {...props}
                                                    options={[]}
                                                    inputRef={props.field.ref}
                                                    label={'Conjunto do pneu'}
                                                    required
                                                    disabled={
                                                        !usuarioBuscouPlaca &&
                                                        !carreta
                                                    }
                                                    onChange={(_, TireSet) => {
                                                        props.field.onChange(
                                                            TireSet
                                                        );
                                                    }}
                                                    fullWidth
                                                    error={!!errors.TireSet}
                                                    helperText={
                                                        errors.TireSet
                                                            ? (
                                                                  errors.TireSet as any
                                                              ).message
                                                            : ''
                                                    }
                                                />
                                            );
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={3}>
                                    <Controller
                                        name="Capacity"
                                        control={control}
                                        defaultValue={''}
                                        render={({
                                            field: { ref, ...field },
                                            fieldState: { error },
                                        }) => {
                                            return (
                                                <InputNumero
                                                    {...field}
                                                    disabled={
                                                        !usuarioBuscouPlaca &&
                                                        !carreta
                                                    }
                                                    inputRef={ref}
                                                    margin="normal"
                                                    maxLength={4}
                                                    autoComplete="new-password"
                                                    fullWidth
                                                    inputProps={{
                                                        maxLength: 3,
                                                    }}
                                                    label="Capacidade (m³)"
                                                    error={!!errors.Capacity}
                                                    helperText={
                                                        errors.Capacity
                                                            ? errors.Capacity
                                                                  .message
                                                            : ''
                                                    }
                                                />
                                            );
                                        }}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={2}>
                                <Grid item xs={12} lg={4}>
                                    <Controller
                                        name="Document4"
                                        control={control}
                                        defaultValue={''}
                                        render={({
                                            field: { ref, ...field },
                                            fieldState: { error },
                                        }) => {
                                            return (
                                                <TextField
                                                    {...field}
                                                    disabled={
                                                        !usuarioBuscouPlaca &&
                                                        !carreta
                                                    }
                                                    inputRef={ref}
                                                    margin="normal"
                                                    autoComplete="new-password"
                                                    required
                                                    fullWidth
                                                    inputProps={{
                                                        maxLength: 20,
                                                    }}
                                                    onChange={(e) => {
                                                        e.target.value =
                                                            e.target.value.replace(/['",\\]/g, '');

                                                        field.onChange(e);
                                                    }}
                                                    label="Chassi"
                                                    error={!!errors.Document4}
                                                    helperText={
                                                        errors.Document4
                                                            ? errors.Document4
                                                                  .message
                                                            : ''
                                                    }
                                                />
                                            );
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={1}>
                                    <Controller
                                        name="Year"
                                        control={control}
                                        defaultValue={''}
                                        render={({
                                            field: { ref, ...field },
                                            fieldState: { error },
                                        }) => {
                                            return (
                                                <InputNumero
                                                    {...field}
                                                    inputRef={ref}
                                                    margin="normal"
                                                    maxLength={4}
                                                    disabled={
                                                        !usuarioBuscouPlaca &&
                                                        !carreta
                                                    }
                                                    autoComplete="new-password"
                                                    fullWidth
                                                    label="Ano"
                                                    error={!!errors.Year}
                                                    helperText={
                                                        errors.Year
                                                            ? errors.Year
                                                                  .message
                                                            : ''
                                                    }
                                                />
                                            );
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={3}>
                                    <Controller
                                        name="Document2"
                                        control={control}
                                        defaultValue={''}
                                        render={({
                                            field: { ref, ...field },
                                            fieldState: { error },
                                        }) => {
                                            return (
                                                <InputNumero
                                                    {...field}
                                                    inputRef={ref}
                                                    margin="normal"
                                                    disabled={
                                                        !usuarioBuscouPlaca &&
                                                        !carreta
                                                    }
                                                    autoComplete="new-password"
                                                    fullWidth
                                                    maxLength={20}
                                                    label="RNTRC"
                                                    error={!!errors.Document2}
                                                    helperText={
                                                        errors.Document2
                                                            ? errors.Document2
                                                                  .message
                                                            : ''
                                                    }
                                                />
                                            );
                                        }}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    lg={4}
                                    style={{ marginTop: '16px' }}
                                >
                                    <LocalizationProvider
                                        locale={ptBR}
                                        dateAdapter={AdapterDateFns}
                                    >
                                        <Controller
                                            name="DueDocument2"
                                            control={control}
                                            defaultValue={null}
                                            render={({
                                                field: { ref, ...field },
                                                fieldState: { error },
                                            }) => {
                                                return (
                                                    <DatePicker
                                                        inputFormat="dd/MM/yyyy"
                                                        value={field.value}
                                                        disabled={
                                                            !usuarioBuscouPlaca &&
                                                            !carreta
                                                        }
                                                        onChange={
                                                            field.onChange
                                                        }
                                                        allowSameDateSelection
                                                        renderInput={(
                                                            params: any
                                                        ) => (
                                                            <TextField
                                                                {...params}
                                                                label="Data de vencimento do RNTRC"
                                                                placeholder=""
                                                                onKeyDown={
                                                                    handleTabPress
                                                                }
                                                                error={
                                                                    !!errors.DueDocument2
                                                                }
                                                                helperText={
                                                                    errors.DueDocument2
                                                                        ? errors
                                                                              .DueDocument2
                                                                              .message
                                                                        : ''
                                                                }
                                                                autoComplete="new-password"
                                                                fullWidth
                                                                required
                                                            />
                                                        )}
                                                    />
                                                );
                                            }}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                            </Grid>

                            <DividerComp texto="Locatário" />

                            <Grid container spacing={2}>
                                <Grid
                                    item
                                    xs={12}
                                    lg={2}
                                    style={{ marginTop: '16px' }}
                                >
                                    <Controller
                                        name="Document5"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                type="text"
                                                label="CPF/CNPJ do locatário"
                                                onBlur={() => {
                                                    if (
                                                        (watchDocument5 &&
                                                            watchDocument5.length ===
                                                                14) ||
                                                        watchDocument5?.length ===
                                                            18
                                                    ) {
                                                        buscarLocatario(
                                                            watchDocument5
                                                        );
                                                    }
                                                }}
                                                autoComplete="new-password"
                                                disabled={
                                                    !usuarioBuscouPlaca &&
                                                    !carreta
                                                }
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                error={!!errors.Document5}
                                                helperText={
                                                    errors.Document5
                                                        ? (
                                                              errors.Document5 as any
                                                          ).message
                                                        : ''
                                                }
                                                inputProps={{ maxLength: 18 }}
                                                onChange={(e) => {
                                                    const { value } = e.target;
                                                    let formattedValue =
                                                        value.replace(
                                                            /\D/g,
                                                            ''
                                                        );
                                                    if (
                                                        formattedValue.length <=
                                                        11
                                                    ) {
                                                        formattedValue =
                                                            formattedValue.replace(
                                                                /(\d{3})(\d{3})(\d{3})(\d{2})/,
                                                                '$1.$2.$3-$4'
                                                            );
                                                    } else {
                                                        formattedValue =
                                                            formattedValue.replace(
                                                                /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                                                                '$1.$2.$3/$4-$5'
                                                            );
                                                    }
                                                    field.onChange(
                                                        formattedValue
                                                    );
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={5}>
                                    <Controller
                                        name="NameDocument2"
                                        control={control}
                                        defaultValue={''}
                                        render={({
                                            field: { ref, ...field },
                                            fieldState: { error },
                                        }) => {
                                            return (
                                                <TextField
                                                    {...field}
                                                    inputRef={ref}
                                                    margin="normal"
                                                    disabled={
                                                        !usuarioBuscouPlaca &&
                                                        !carreta
                                                    }
                                                    fullWidth
                                                    inputProps={{
                                                        maxLength: 60,
                                                        style: {
                                                            textTransform:
                                                                'uppercase',
                                                        },
                                                    }}
                                                    onChange={(e) => {
                                                        e.target.value =
                                                            e.target.value.replace(/['",\\]/g, '');

                                                        field.onChange(e);
                                                    }}
                                                    label="Nome/Razão social do locatário"
                                                    error={
                                                        !!errors.NameDocument2
                                                    }
                                                    helperText={
                                                        errors.NameDocument2
                                                            ? errors
                                                                  .NameDocument2
                                                                  .message
                                                            : ''
                                                    }
                                                />
                                            );
                                        }}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={2}>
                                <Grid item xs={12} lg={3}>
                                    <Controller
                                        name="Postcode5"
                                        control={control}
                                        defaultValue={''}
                                        render={({
                                            field: { ref, ...field },
                                            fieldState: { error },
                                        }) => {
                                            return (
                                                <TextField
                                                    {...field}
                                                    inputRef={ref}
                                                    margin="normal"
                                                    autoComplete="new-password"
                                                    fullWidth
                                                    disabled={
                                                        !usuarioBuscouPlaca &&
                                                        !carreta
                                                    }
                                                    inputProps={{
                                                        maxLength: 8,
                                                    }}
                                                    label="CEP do locatário"
                                                    onChange={(
                                                        event: React.ChangeEvent<
                                                            | HTMLTextAreaElement
                                                            | HTMLInputElement
                                                        >
                                                    ) => {
                                                        event.currentTarget.value =
                                                            event.currentTarget.value =
                                                                event.currentTarget.value.replace(
                                                                    /[^0-9]/g,
                                                                    ''
                                                                );

                                                        field.onChange(event);
                                                    }}
                                                    onBlur={(event) => {
                                                        buscarCepLocatario(
                                                            event.target.value
                                                        );
                                                    }}
                                                    error={!!errors.Postcode5}
                                                    helperText={
                                                        errors.Postcode5
                                                            ? errors.Postcode5
                                                                  .message
                                                            : ''
                                                    }
                                                    data-id="campoCepOrigem"
                                                />
                                            );
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={5}>
                                    <Controller
                                        name="Address5"
                                        control={control}
                                        defaultValue={''}
                                        render={({
                                            field: { ref, ...field },
                                            fieldState: { error },
                                        }) => {
                                            return (
                                                <TextField
                                                    {...field}
                                                    inputRef={ref}
                                                    margin="normal"
                                                    autoComplete="new-password"
                                                    fullWidth
                                                    disabled={
                                                        !usuarioBuscouPlaca &&
                                                        !carreta
                                                    }
                                                    inputProps={{
                                                        style: {
                                                            textTransform:
                                                                'uppercase',
                                                        },
                                                    }}
                                                    onChange={(e) => {
                                                        e.target.value =
                                                            e.target.value.replace(/['",\\]/g, '');

                                                        field.onChange(e);
                                                    }}
                                                    label="Logradouro do locatário"
                                                    error={!!errors.Address5}
                                                    helperText={
                                                        errors.Address5
                                                            ? errors.Address5
                                                                  .message
                                                            : ''
                                                    }
                                                    data-id="campoLogradouroOrigem"
                                                />
                                            );
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={2}>
                                    <Controller
                                        name="Number5"
                                        control={control}
                                        defaultValue={''}
                                        render={({
                                            field: { ref, ...field },
                                            fieldState: { error },
                                        }) => {
                                            return (
                                                <InputNumero
                                                    {...field}
                                                    margin="normal"
                                                    inputRef={ref}
                                                    autoComplete="new-password"
                                                    fullWidth
                                                    disabled={
                                                        !usuarioBuscouPlaca &&
                                                        !carreta
                                                    }
                                                    maxLength={7}
                                                    required
                                                    label="Número do locatário"
                                                    error={!!errors.Number5}
                                                    helperText={
                                                        errors.Number5
                                                            ? errors.Number5
                                                                  .message
                                                            : ''
                                                    }
                                                    data-id="campoNumeroOrigem"
                                                />
                                            );
                                        }}
                                    />
                                </Grid>
                            </Grid>

                            {watchDocument5 && watchDocument5.length === 18 && (
                                <Grid container spacing={2}>
                                    <Grid
                                        item
                                        xs={12}
                                        lg={5}
                                        style={{ marginTop: '16px' }}
                                    >
                                        <FormControl>
                                            <FormLabel id="demo-row-radio-buttons-group-label">
                                                Regime tributário do Locatário
                                            </FormLabel>
                                            <RadioGroup
                                                row
                                                onChange={(
                                                    event: React.ChangeEvent<HTMLInputElement>
                                                ) => {
                                                    setRegimeTributarioLocatario(
                                                        (
                                                            event.target as HTMLInputElement
                                                        ).value
                                                    );
                                                }}
                                                value={
                                                    regimeTributarioLocatario
                                                }
                                            >
                                                <FormControlLabel
                                                    value="N"
                                                    control={<Radio />}
                                                    label="Normal"
                                                />
                                                <FormControlLabel
                                                    value="S"
                                                    control={<Radio />}
                                                    label="Simples Nacional"
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            )}

                            <Grid container spacing={2}>
                                <Grid item xs={12} lg={4}>
                                    <Controller
                                        name="Complement5"
                                        control={control}
                                        defaultValue={''}
                                        render={({
                                            field: { ref, ...field },
                                            fieldState: { error },
                                        }) => {
                                            return (
                                                <TextField
                                                    {...field}
                                                    inputRef={ref}
                                                    margin="normal"
                                                    autoComplete="new-password"
                                                    fullWidth
                                                    disabled={
                                                        !usuarioBuscouPlaca &&
                                                        !carreta
                                                    }
                                                    inputProps={{
                                                        style: {
                                                            textTransform:
                                                                'uppercase',
                                                        },
                                                    }}
                                                    onChange={(e) => {
                                                        e.target.value =
                                                            e.target.value.replace(/['",\\]/g, '');

                                                        field.onChange(e);
                                                    }}
                                                    label="Complemento do locatário"
                                                    error={!!errors.Complement5}
                                                    helperText={
                                                        errors.Complement5
                                                            ? errors.Complement5
                                                                  .message
                                                            : ''
                                                    }
                                                    data-id="campoLogradouroOrigem"
                                                />
                                            );
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={3}>
                                    <Controller
                                        name="County5"
                                        control={control}
                                        defaultValue={''}
                                        render={({
                                            field: { ref, ...field },
                                            fieldState: { error },
                                        }) => {
                                            return (
                                                <TextField
                                                    {...field}
                                                    inputRef={ref}
                                                    margin="normal"
                                                    autoComplete="new-password"
                                                    fullWidth
                                                    disabled={
                                                        !usuarioBuscouPlaca &&
                                                        !carreta
                                                    }
                                                    inputProps={{
                                                        style: {
                                                            textTransform:
                                                                'uppercase',
                                                        },
                                                    }}
                                                    onChange={(e) => {
                                                        e.target.value =
                                                            e.target.value.replace(/['",\\]/g, '');

                                                        field.onChange(e);
                                                    }}
                                                    label="Bairro do locatário"
                                                    error={!!errors.County5}
                                                    helperText={
                                                        errors.County5
                                                            ? errors.County5
                                                                  .message
                                                            : ''
                                                    }
                                                    data-id="campoBairroOrigem"
                                                />
                                            );
                                        }}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    lg={5}
                                    style={{ marginTop: '16px' }}
                                >
                                    <Controller
                                        control={control}
                                        name="cidadeIbge5"
                                        defaultValue={null}
                                        render={(props) => {
                                            return (
                                                <SelectCidades
                                                    {...props}
                                                    options={[]}
                                                    inputRef={props.field.ref}
                                                    label={
                                                        'Cidade do locatário'
                                                    }
                                                    required
                                                    disabled={
                                                        !usuarioBuscouPlaca &&
                                                        !carreta
                                                    }
                                                    onChange={(
                                                        _,
                                                        cidadeIbge5
                                                    ) => {
                                                        props.field.onChange(
                                                            cidadeIbge5
                                                        );
                                                    }}
                                                    fullWidth
                                                    error={!!errors.cidadeIbge5}
                                                    helperText={
                                                        errors.cidadeIbge5
                                                            ? (
                                                                  errors.cidadeIbge5 as any
                                                              ).message
                                                            : 'Pesquise pelo nome da cidade (min 3 letras)'
                                                    }
                                                    data-id="selectProduto"
                                                />
                                            );
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                spacing={2}
                                justifyContent="flex-end"
                            >
                                <Grid item xs={12} lg={2}>
                                    <Button
                                        type="button"
                                        variant="outlined"
                                        style={{
                                            marginTop: 25,
                                            marginBottom: 15,
                                            backgroundColor: '#fff',
                                        }}
                                        size="large"
                                        onClick={() => fecharClicado()}
                                    >
                                        Fechar
                                    </Button>
                                </Grid>

                                <Grid item xs={12} lg={2}>
                                    <Button
                                        type="button"
                                        variant="contained"
                                        style={stylesTabMotorista().button}
                                        size="large"
                                        disabled={
                                            !usuarioBuscouPlaca && !carreta
                                        }
                                        onClick={() => {
                                            handleSubmit(tratarDados)();
                                        }}
                                    >
                                        Salvar
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Box>
                </SpinnerLoading>
            </DialogContent>
            {/* <DialogActions>
                <Button onClick={() => fecharClicado()} autoFocus>
                    Fechar
                </Button>
                <Button onClick={() => handleSubmit(tratarDados)()} autoFocus>
                    Gravar
                </Button>
            </DialogActions> */}
        </Dialog>
    );
}
