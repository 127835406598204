import useGenericService from '../../../hooks/useGenericService';
import { ApiResources } from '../../../Models/Api';
import { IFilterDefault } from '../../../types/interfaces/IFilterDefault';
import { PaginationResponse } from '../../../types/interfaces/PaginationResponse';

import { ILoteEmbarqueRepository } from '../domain/repositories/ILoteEmbarqueRepository';
import { ILoteEmbarqueResponseDto } from '../dtos/ILoteEmbarqueResponseDto';

export class LoteEmbarqueRepository implements ILoteEmbarqueRepository {
    private serviceLoteEmbarque = useGenericService().getService(
        ApiResources.LoteEmbarque
    );

    async findPaginado(
        filter: IFilterDefault
    ): Promise<PaginationResponse<ILoteEmbarqueResponseDto>> {
        const resultado = await this.serviceLoteEmbarque.api.get('/listar', {
            params: filter,
        });

        return resultado.data.content;
    }

    async getLoteEmbarqueById(id: number): Promise<ILoteEmbarqueResponseDto> {
        const resultado = await this.serviceLoteEmbarque.api.get(`/${id}`);

        return resultado.data;
    }

    async getLoteEmbarqueByLoteEmbarqueTransportadoraId(
        id: number
    ): Promise<ILoteEmbarqueResponseDto> {
        const resultado = await this.serviceLoteEmbarque.api.get(
            `/lote-embarque-transportadora/${id}`
        );

        return resultado.data;
    }
}
