import { Button, Container, Divider, Grid, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import PageTitle from '../../../Components/PageTitle/PageTitle';
import SpinnerLoading from '../../../Components/SpinnerLoading/SpinnerLoading';
import { useFetchUseCase } from '../../../hooks/useFetchUseCase';
import { FrontEndPathResources } from '../../../Models/Api';
import { joiResolver } from '@hookform/resolvers/joi';
import DividerComp from '../../../Components/Divider/DividerComp';
import { useEffect, useState } from 'react';
import { IOrdemColetaValidacaoDescarga } from '../domain/dtos/IOrdemColetaValidacaoDescarga';
import { BuscarValidacaoDescargaPorIdOrdemColeta } from '../domain/usecases/ValidacaoDescargaBuscarPorIdOrdemColeta';
import { CadastrarValidacaoDescarga } from '../domain/usecases/ValidacaoDescargaCadastrar';
import { EditarValidacaoDescarga } from '../domain/usecases/ValidacaoDescargaEditar';
import { EnviarTicketDescargaInput } from './components/EnviarTicketDescargaInput';
import { styles } from './components/OrdemColetaForm/Styles';
import { ValidacaoDescargaForm } from './components/ValidacaoDescargaForm';
import ObservacoesForm from '../../DoubleCheck/components/ObservacoesForm';
import StatusForm from '../../DoubleCheck/components/StatusForm';
import { validacaoOrdemColetaValidacaoDescargaComTicket, validacaoOrdemColetaValidacaoDescargaSemTicket } from './components/ValidacaoDescargaForm/JoiValidators';

interface Props {
    cadastrarValidacaoDescarga: CadastrarValidacaoDescarga;
    buscarValidacaoDescargaPorIdOrdemColeta: BuscarValidacaoDescargaPorIdOrdemColeta;
    editarValidacaoDescarga: EditarValidacaoDescarga;
    doubleCheck: boolean;
}

interface Nota {
    chaveNota: string;
    danfe: {
        id: number;
        nome: string;
        tipo: string;
        stringBase64: string;
    };
    dataEmissao: string;
    id: number;
    numeroNf: number | null;
    numeroNfe: string;
    peso: number;
    serie: string;
    unidadeMedida: string;
}

export interface ApiError {
    atributo: string;
    mensagem: string;
}

export default function ValidarDescarga({
    cadastrarValidacaoDescarga,
    buscarValidacaoDescargaPorIdOrdemColeta,
    editarValidacaoDescarga,
    doubleCheck
}: Props) {
    const navigate = useNavigate();
    const { executePromise, loading } = useFetchUseCase();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { id } = useParams<{ id: string }>();

    const location = useLocation();
    const [state] = useState(location.state as any);

    const formValidarDescarga = useForm<IOrdemColetaValidacaoDescarga>({
        resolver: joiResolver(doubleCheck ? validacaoOrdemColetaValidacaoDescargaSemTicket : validacaoOrdemColetaValidacaoDescargaComTicket),
        criteriaMode: 'all',
    });
    const { control, handleSubmit, reset, setValue } = formValidarDescarga;

    const onSubmit = handleSubmit((data: IOrdemColetaValidacaoDescarga) => {
        if (data.id == null) {
            executePromise(
                () => cadastrarValidacaoDescarga.execute(data),
                () => {
                    enqueueSnackbar('Salvo com sucesso!', {
                        variant: 'success',
                        onClick: () => {
                            navigate(FrontEndPathResources.LoteEmbarquePorTransportadora);
                            closeSnackbar();
                        },
                    });
                    navigate(doubleCheck ? FrontEndPathResources.DoubleCheck : FrontEndPathResources.LoteEmbarquePorTransportadora);
                },
                (error) => {
                    error.forEach((value:ApiError) => {
                    enqueueSnackbar('Erro: ' + value.mensagem, {
                        variant: 'error',
                        onClick: () => {
                            closeSnackbar();
                        },
                    });
                    })
                   
                }
            );
        } else {
            executePromise(
                () => editarValidacaoDescarga.execute(data),
                () => {
                    enqueueSnackbar('Salvo com sucesso!', {
                        variant: 'success',
                        onClick: () => {
                            navigate(FrontEndPathResources.LoteEmbarquePorTransportadora);
                            closeSnackbar();
                        },
                    });
                    navigate(doubleCheck ? FrontEndPathResources.DoubleCheck : FrontEndPathResources.LoteEmbarquePorTransportadora);
                },
                (error) => {
                    error.forEach((value:ApiError) => {
                        enqueueSnackbar('Erro: ' + value.mensagem, {
                            variant: 'error',
                            onClick: () => {
                                closeSnackbar();
                            },
                        });
                        })
                    navigate(doubleCheck ? FrontEndPathResources.DoubleCheck : FrontEndPathResources.LoteEmbarquePorTransportadora);
                }
            );
        }
    });

    const getNumeroNotas = (notas: Nota[]): string => {
        return notas
            .map((nota) =>
                nota.numeroNf
                    ? nota.numeroNf.toString()
                    : nota.numeroNfe.toString()
            )
            .join(',');
    };

    useEffect(() => {
        executePromise(
            () => buscarValidacaoDescargaPorIdOrdemColeta.execute(Number(id)),
            (response: any) => {
                console.log(response)
                reset(response);

                const placaCaminhao = response.cavalo.placa;
                const pesoOrigem = response.peso;
                const notasAnteriores = response?.ordemColetaLancamento?.notasAnteriores ? response?.ordemColetaLancamento?.notasAnteriores: [];
                const ordemColetaValidacaoDescarga = response?.ordemColetaValidacaoDescarga;
                const ordemColetaLancamento = response?.ordemColetaLancamento
                const notaFiscal = response?.numeroNotaMaxys
                reset({
                    idOrdemColeta: response.id,
                    id: ordemColetaValidacaoDescarga?.id,
                    ordemColetaId: response.id,
                    placaCaminhao: placaCaminhao,
                    pesoOrigem: pesoOrigem,
                    numeroNotaFiscal: notaFiscal,
                    ticketDescarga: ordemColetaValidacaoDescarga?.ticketDescarga,
                    pesoDestino: ordemColetaValidacaoDescarga?.pesoDestino,
                    dataDescarga: ordemColetaValidacaoDescarga?.dataDescarga,
                    observacoes: ordemColetaValidacaoDescarga?.observacoes,
                    status: ordemColetaValidacaoDescarga?.statusDescarga
                });
            }
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [buscarValidacaoDescargaPorIdOrdemColeta]);

    return (
        <SpinnerLoading isLoading={loading}>
            <Container>
                <FormProvider {...formValidarDescarga}>
                    <PageTitle title={'Validar descarga'} />
                    <Divider />
                    <ValidacaoDescargaForm/>
                    <DividerComp texto="Documento anexo" />
                    <EnviarTicketDescargaInput enableDownloadAndDelete={doubleCheck}/>
                    <DividerComp texto="Observações" />
                    <ObservacoesForm />
                    {doubleCheck && (
                        <>
                            <DividerComp texto="Status" />
                            <StatusForm descarga />
                        </>
                    )}
                    <Grid container spacing={2} justifyContent="end">
                        <Button
                            onClick={() => {
                                onSubmit();
                            }}
                            variant="contained"
                            style={styles().button}
                            size="large"
                        >
                            Salvar
                        </Button>
                    </Grid>
                </FormProvider>
            </Container>
        </SpinnerLoading>
    );
}
