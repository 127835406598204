import { IBaseUseCase } from '../../../../types/interfaces/IBaseUseCase';
import { IOrdemColetaRepository } from '../repositories/IOrdemColetaRepository';

export class PostReprocessarDescarga implements IBaseUseCase<number, void> {
    constructor(private readonly repository: IOrdemColetaRepository) {}

    execute(ordemColetaId: number): Promise<void> {
        return this.repository.postReprocessarDescarga(ordemColetaId);
    }
}
