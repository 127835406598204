import AutorenewIcon from '@mui/icons-material/Autorenew';
import {
    Button,
    debounce,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Tooltip,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState, useCallback } from 'react';
import GenericBasicTable, { ComplexColumn } from '../../Components/Table/GenericBasicTable';
import { StatusRequisicao } from '../../Models/Usuario';
import Api, { ApiResources, FrontEndPathResources } from '../../Models/Api';
import useGenericService from '../../hooks/useGenericService';
import { styles } from '../../Screens/CadastroUsuario/helpers/Styles';
import { useNavigate } from 'react-router-dom';
import TablePagination from '@mui/material/TablePagination';

interface Props {
    aberto: boolean;
    fecharClicado: () => void;
    validarRestaurar: () => Promise<boolean>;
    varNome: string; // Exemplo: "Usuários"
    varServico: ApiResources; // Exemplo: ApiResources.Usuario
    varTipoClasse: any; // Exemplo: Usuario[]
    varColunas: ComplexColumn[]; // Do tipo ComplexColumn[]
}

interface LabelDisplayedRowsProps {
    from: number;
    to: number;
    count: number;
}

export default function GenericModalItensExcluidos({
    aberto,
    fecharClicado,
    validarRestaurar,
    varNome,
    varServico,
    varTipoClasse,
    varColunas,
}: Props) {
    const [registros, setRegistros] = useState<typeof varTipoClasse[]>([]);
    const { getService } = useGenericService();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const [paginaAtual, setPaginaAtual] = useState(0);
    const [registrosPorPagina, setRegistrosPorPagina] = useState(5);
    const [totalRegistros, setTotalRegistros] = useState(0);
    
    const customLabelDisplayedRows = ({ from, to, count }: LabelDisplayedRowsProps) => {
        return `${from}-${to} de ${count}`;
    };
    
    const colunaRestaurar: ComplexColumn = {
        attribute: 'nome',
        label: 'Ações',
        id: 'acao',
        width: 50,
        format: (row) => {
            return (
                <>
                    <Tooltip
                        title="Restaurar"
                        placement="top"
                        disableInteractive
                    >
                        <IconButton
                            aria-label="delete"
                            onClick={ 
                                () => {
                                restaurarRegistro(row.id);
                                buscarRegistrosExcluidos(paginaAtual, registrosPorPagina);
                            }}
                        >
                            <AutorenewIcon />
                        </IconButton>
                    </Tooltip>
                </>
            );
        }
    };
    const colunas: ComplexColumn[] = [...varColunas, colunaRestaurar];

    const buscarRegistrosExcluidos = useCallback(
        debounce(async (page: number, registrosPorPagina: number) => {
            const servico = getService(varServico);
            try {
                const resposta = await servico.api.get('/desativadas', {
                    params: {
                        page,
                        size: registrosPorPagina,
                    }
                });
                if (resposta.status === StatusRequisicao.OK) {
                    setRegistros(resposta?.data?.content);
                    setTotalRegistros(resposta?.data?.totalElements);
                }
            } catch (error) {
                enqueueSnackbar('Erro ao buscar registros excluídos', {
                    variant: 'error',
                    onClick: () => {
                        closeSnackbar();
                    },
                });
            }
        }, 300), [getService, varServico, registrosPorPagina, enqueueSnackbar, closeSnackbar]
    );

    const restaurarRegistro = async (registroId: number) => {
        const validacaoLicenca = await validarRestaurar();

        if (!validacaoLicenca) {
            return;
        }

        const servico = getService(varServico);
        
            try {
                const resposta = await servico.api.put(
                    '/restaurar/' + registroId,
                    {}
                );
    
                if (resposta.status === StatusRequisicao.OK) {
                    enqueueSnackbar('Registro restaurado com sucesso', {
                        variant: 'success',
                        onClick: () => {
                            closeSnackbar();
                        },
                    });
                    buscarRegistrosExcluidos(paginaAtual, registrosPorPagina);
                }
            } catch (error) {}
    };

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPaginaAtual(newPage);
        buscarRegistrosExcluidos(newPage, registrosPorPagina);
    };
    
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRegistrosPorPagina(+event.target.value);
        setPaginaAtual(paginaAtual);
    };

    useEffect(() => {
        buscarRegistrosExcluidos(paginaAtual, registrosPorPagina);
    }, [paginaAtual, registrosPorPagina]);

    useEffect(() => {
        if(aberto)
            buscarRegistrosExcluidos(paginaAtual, registrosPorPagina);
    }, [aberto]);

    return (
        <Dialog
            open={aberto}
            onClose={() => {
                fecharClicado();
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="xl"
        >
            <DialogTitle id="alert-dialog-title">
                {varNome} - registros excluídos
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2} style={styles().grid}>
                <GenericBasicTable columns={colunas} rows={registros} />
                </Grid>
                <TablePagination
                rowsPerPageOptions={[5, 10, 15]}
                component="div"
                count={totalRegistros}
                rowsPerPage={registrosPorPagina}
                labelRowsPerPage={'Linhas por página'}
                labelDisplayedRows={customLabelDisplayedRows}
                page={paginaAtual}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    setPaginaAtual(0);
                    setRegistrosPorPagina(5);
                    fecharClicado();}} autoFocus>
                    Fechar
                </Button>
            </DialogActions>
        </Dialog>
    );
}