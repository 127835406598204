import Joi from 'joi';

const validacao = {
    id: Joi.number().optional().allow(null),
    idOrdemColeta: Joi.number().required(),
    ordemColetaId: Joi.number().required(),
    placaCaminhao: Joi.string().required(),
    numeroNotaFiscal: Joi.string().required(),
    pesoOrigem: Joi.number(),
    pesoDestino: Joi.number(),
    dataDescarga: Joi.date().max('now').required().messages({
        'date.base': 'Data inválida',
        'date.required': 'Não pode ficar em branco',
        'date.max': 'Não deve ser maior que a data atual',
    }),
    observacoes: Joi.array().optional().allow(null),
    status: Joi.string().optional().allow('', null),
    novaObservacao: Joi.object().optional().allow(null)
}

export const validacaoOrdemColetaValidacaoDescargaComTicket = Joi.object({
    ...validacao,
    ticketDescarga: Joi.object().required(),
});

export const validacaoOrdemColetaValidacaoDescargaSemTicket = Joi.object({
    ...validacao,
    ticketDescarga: Joi.object().optional().allow(null),
});
