export enum EStatusDoubleCheck {
  ATENDIMENTO = 'Em Atendimento',
  PENDENTE = 'Pendente',
  LIBERADO = 'Liberado',
  NOVO = 'Novo',
  RESPONDIDO = 'Respondido', 
  ERRO = 'Com Erro',
  ENVIADO_MAXYS = 'Enviado Maxys',
  NOVO_DESCARGA = 'Novo Descarga',
  ATENDIMENTO_DESCARGA = 'Em Atendimento Descarga',
  PENDENTE_DESCARGA = 'Pendente de Descarga',
  RESPONDIDO_DESCARGA = 'Respondido Descarga',
  ERRO_DESCARGA = 'Com Erro Descarga',
  LIBERADO_DESCARGA = 'Liberado Descarga',
}