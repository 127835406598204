import useGenericService from '../../../hooks/useGenericService';
import { ApiResources } from '../../../Models/Api';
import type { ILGPDRepository } from '../domain/repositories/ILGPDRepository';
import type { ILGPDRequestDto } from '../dtos/ILGPDRequestDto';

import type { ILGPDResponseDto } from '../dtos/ILGPDResponseDto';

export class LGPDRepository implements ILGPDRepository {
    private serviceLGPD = useGenericService().getService(ApiResources.TermosVigentesLGPD);

    async getBuscarTermoAtivo(): Promise<ILGPDResponseDto> {
        const resultado = await this.serviceLGPD.api.get("/ativo");

        return resultado.data;
    }

    async postCriarNovoTermo(data:ILGPDRequestDto): Promise<ILGPDResponseDto> {
        const resultado = await this.serviceLGPD.api.post('/', data);
        return resultado.data;
    }
}
