import { Navigate, Route, Routes } from 'react-router-dom';

import NotFound from './Screens/Erros/NotFound';
import Home from './Screens/Home/Home';
import Login from './Screens/Login/Login';

import ProdutoAlterar from './Screens/Produto/Alterar';
import ProdutoCriar from './Screens/Produto/Criar';
import ProdutoListar from './Screens/Produto/Listar';
import ProdutoVer from './Screens/Produto/Ver';

import AmostraAlterar from './Screens/Amostra/Alterar';
import AmostraCriar from './Screens/Amostra/Criar';
import AmostraListar from './Screens/Amostra/Listar';
import AmostraVer from './Screens/Amostra/Ver';

import ClassificadorAlterar from './Screens/Classificador/Alterar';
import ClassificadorCriar from './Screens/Classificador/Criar';
import ClassificadorListar from './Screens/Classificador/Listar';
import ClassificadorVer from './Screens/Classificador/Ver';

import ClienteFornecedorAlterar from './Screens/ClienteFornecedor/Alterar';
import ClienteFornecedorCriar from './Screens/ClienteFornecedor/Criar';
import ClienteFornecedorListar from './Screens/ClienteFornecedor/Listar';
import ClienteFornecedorVer from './Screens/ClienteFornecedor/Ver';

import TransportadoraAlterar from './Screens/Transportadora/Alterar';
import TransportadoraCriar from './Screens/Transportadora/Criar';
import TransportadoraListar from './Screens/Transportadora/Listar';
import TransportadoraVer from './Screens/Transportadora/Ver';

import ContratanteAlterar from './Screens/Contratante/Alterar';
import ContratanteCriar from './Screens/Contratante/Criar';
import ContratanteListar from './Screens/Contratante/Listar';
import ContratanteVer from './Screens/Contratante/Ver';

import OrdemAlterar from './Screens/Ordem/Alterar';
import OrdemCriar from './Screens/Ordem/Criar';
import OrdemListar from './Screens/Ordem/Listar';
import OrdemVer from './Screens/Ordem/Ver';

import LoteEmbarqueAlterar from './Screens/LoteEmbarque/Alterar';
import LoteEmbarqueCriar from './Screens/LoteEmbarque/Criar';
import LoteEmbarqueListar from './Screens/LoteEmbarque/Listar';
import LoteEmbarqueVer from './Screens/LoteEmbarque/Ver';
import LoteEmbarqueVincularTransportadora from './Screens/LoteEmbarque/VincularTransportadora';

import PerfilUsuarioAlterar from './Screens/PerfilUsuario/Alterar';
import PerfilUsuarioCriar from './Screens/PerfilUsuario/Criar';
import PerfilUsuarioListar from './Screens/PerfilUsuario/Listar';
import PerfilUsuarioVer from './Screens/PerfilUsuario/Ver';

import CadastroUsuarioAlterar from './Screens/CadastroUsuario/Alterar';
import CadastroUsuarioCriar from './Screens/CadastroUsuario/Criar';
import CadastroUsuarioListar from './Screens/CadastroUsuario/Listar';
import CadastroUsuarioVer from './Screens/CadastroUsuario/Ver';

import Configuracao from './Screens/Configuracao/Configuracao';

import AlterarSenha from './Components/AlterarSenha/AlterarSenha';
import { FrontEndPathResources } from './Models/Api';
import RequireAuth from './RequireAuth';
import IntegracaoMaxys from './Screens/Integracao/IntegracaoMaxysForm';
import RegistroJuncao from './Screens/Juncao/RegistroJuncao';
import { OrdemColetaAlterar } from './Screens/OrdemColeta/factories/OrdemColetaAlterar';
import { OrdemColetaCriar } from './Screens/OrdemColeta/factories/OrdemColetaCriar';
import { OrdemColetaVer } from './Screens/OrdemColeta/factories/OrdemColetaVer';

import { DoubleCheck } from './Screens/DoubleCheck/DoubleCheck';
import { DoubleCheckListarFactory } from './Screens/DoubleCheck/factories/DoubleCheckListarFactory';
import { LotesPorTransportadoraListar } from './Screens/LotesPorTransportadora/factories/LotesPorTransportadoraListar';
import { LotesPorTransportadoraVer } from './Screens/LotesPorTransportadora/factories/LotesPorTransportadoraVer';
import { OrdemColetaLancarDadosEmbarque } from './Screens/OrdemColeta/factories/OrdemColetaLancarDadosEmbarque';
import { OrdemColetaValidarDescarga } from './Screens/OrdemColeta/factories/OrdemColetaValidarDescarga';

const Router = () => {
    return (
        <Routes>
            <Route path="/easymbark/login" element={<Login />} />

            <Route
                path="/easymbark"
                element={
                    <RequireAuth>
                        <Home />
                    </RequireAuth>
                }
            />
            <Route
                path={FrontEndPathResources.Configuracao}
                element={
                    <RequireAuth>
                        <Configuracao />
                    </RequireAuth>
                }
            />
            <Route
                path={FrontEndPathResources.AlterarSenha}
                element={
                    <RequireAuth>
                        <AlterarSenha />
                    </RequireAuth>
                }
            />
            <Route
                path={FrontEndPathResources.ClienteFornecedor + '/criar'}
                element={
                    <RequireAuth>
                        <ClienteFornecedorCriar />
                    </RequireAuth>
                }
            />
            <Route
                path={FrontEndPathResources.ClienteFornecedor + '/ver/:id'}
                element={
                    <RequireAuth>
                        <ClienteFornecedorVer />
                    </RequireAuth>
                }
            />
            <Route
                path={FrontEndPathResources.ClienteFornecedor + '/alterar/:id'}
                element={
                    <RequireAuth>
                        <ClienteFornecedorAlterar />
                    </RequireAuth>
                }
            />
            <Route
                path={FrontEndPathResources.ClienteFornecedor}
                element={
                    <RequireAuth>
                        <ClienteFornecedorListar />
                    </RequireAuth>
                }
            />
            <Route
                path={FrontEndPathResources.Produto + '/criar'}
                element={
                    <RequireAuth>
                        <ProdutoCriar />
                    </RequireAuth>
                }
            />
            <Route
                path={FrontEndPathResources.Produto}
                element={
                    <RequireAuth>
                        <ProdutoListar />
                    </RequireAuth>
                }
            />
            <Route
                path={FrontEndPathResources.Produto + '/ver/:id'}
                element={
                    <RequireAuth>
                        <ProdutoVer />
                    </RequireAuth>
                }
            />
            <Route
                path={FrontEndPathResources.Produto + '/alterar/:id'}
                element={
                    <RequireAuth>
                        <ProdutoAlterar />
                    </RequireAuth>
                }
            />

            <Route
                path={FrontEndPathResources.Amostra + '/criar'}
                element={
                    <RequireAuth>
                        <AmostraCriar />
                    </RequireAuth>
                }
            />
            <Route
                path={FrontEndPathResources.Amostra}
                element={
                    <RequireAuth>
                        <AmostraListar />
                    </RequireAuth>
                }
            />
            <Route
                path={FrontEndPathResources.Amostra + '/ver/:id'}
                element={
                    <RequireAuth>
                        <AmostraVer />
                    </RequireAuth>
                }
            />
            <Route
                path={FrontEndPathResources.Amostra + '/alterar/:id'}
                element={
                    <RequireAuth>
                        <AmostraAlterar />
                    </RequireAuth>
                }
            />

            <Route
                path={FrontEndPathResources.Classificador + '/criar'}
                element={
                    <RequireAuth>
                        <ClassificadorCriar />
                    </RequireAuth>
                }
            />
            <Route
                path={FrontEndPathResources.Classificador + '/ver/:id'}
                element={
                    <RequireAuth>
                        <ClassificadorVer />
                    </RequireAuth>
                }
            />
            <Route
                path={FrontEndPathResources.Classificador}
                element={
                    <RequireAuth>
                        <ClassificadorListar />
                    </RequireAuth>
                }
            />
            <Route
                path={FrontEndPathResources.Classificador + '/alterar/:id'}
                element={
                    <RequireAuth>
                        <ClassificadorAlterar />
                    </RequireAuth>
                }
            />
            <Route
                path={FrontEndPathResources.Transportadora}
                element={
                    <RequireAuth>
                        <TransportadoraListar />
                    </RequireAuth>
                }
            />

            <Route
                path={FrontEndPathResources.Transportadora + '/criar'}
                element={
                    <RequireAuth>
                        <TransportadoraCriar />
                    </RequireAuth>
                }
            />

            <Route
                path={FrontEndPathResources.Transportadora + '/alterar/:id'}
                element={
                    <RequireAuth>
                        <TransportadoraAlterar />
                    </RequireAuth>
                }
            />

            <Route
                path={FrontEndPathResources.Transportadora + '/ver/:id'}
                element={
                    <RequireAuth>
                        <TransportadoraVer />
                    </RequireAuth>
                }
            />

            <Route
                path={FrontEndPathResources.OrdemCarregamento}
                element={
                    <RequireAuth>
                        <OrdemListar />
                    </RequireAuth>
                }
            />
            <Route
                path={FrontEndPathResources.OrdemCarregamento + '/criar'}
                element={
                    false ? (
                        <Navigate replace to="*" />
                    ) : (
                        <RequireAuth>
                            <OrdemCriar />
                        </RequireAuth>
                    )
                }
            />
            <Route
                path={FrontEndPathResources.OrdemCarregamento + '/ver/:id'}
                element={
                    <RequireAuth>
                        <OrdemVer />
                    </RequireAuth>
                }
            />
            <Route
                path={FrontEndPathResources.OrdemCarregamento + '/alterar/:id'}
                element={
                    false ? (
                        <Navigate replace to="*" />
                    ) : (
                        <RequireAuth>
                            <OrdemAlterar />
                        </RequireAuth>
                    )
                }
            />
            <Route
                path={FrontEndPathResources.LoteEmbarque + '/criar'}
                element={
                    false ? (
                        <Navigate replace to="*" />
                    ) : (
                        <RequireAuth>
                            <LoteEmbarqueCriar />
                        </RequireAuth>
                    )
                }
            />
            <Route
                path={FrontEndPathResources.LoteEmbarque + '/ver/:id'}
                element={
                    <RequireAuth>
                        <LoteEmbarqueVer />
                    </RequireAuth>
                }
            />
            <Route
                path={FrontEndPathResources.LoteEmbarque + '/alterar/:id'}
                element={
                    false ? (
                        <Navigate replace to="*" />
                    ) : (
                        <RequireAuth>
                            <LoteEmbarqueAlterar />
                        </RequireAuth>
                    )
                }
            />
            <Route
                path={
                    FrontEndPathResources.LoteEmbarque +
                    '/vincular-transportadoras/:id'
                }
                element={
                    false ? (
                        <Navigate replace to="*" />
                    ) : (
                        <RequireAuth>
                            <LoteEmbarqueVincularTransportadora />
                        </RequireAuth>
                    )
                }
            />
            <Route
                path={FrontEndPathResources.LoteEmbarque}
                element={
                    <RequireAuth>
                        <LoteEmbarqueListar />
                    </RequireAuth>
                }
            />
            <Route
                path={FrontEndPathResources.Contratante}
                element={
                    <RequireAuth>
                        <ContratanteListar />
                    </RequireAuth>
                }
            />
            <Route
                path={FrontEndPathResources.Contratante + '/criar'}
                element={
                    <RequireAuth>
                        <ContratanteCriar />
                    </RequireAuth>
                }
            />
            <Route
                path={FrontEndPathResources.Contratante + '/alterar/:id'}
                element={
                    <RequireAuth>
                        <ContratanteAlterar />
                    </RequireAuth>
                }
            />
            <Route
                path={FrontEndPathResources.Contratante + '/ver/:id'}
                element={
                    <RequireAuth>
                        <ContratanteVer />
                    </RequireAuth>
                }
            />
            <Route
                path={FrontEndPathResources.Juncao}
                element={
                    <RequireAuth urlToGo={FrontEndPathResources.Juncao}>
                        <RegistroJuncao />
                    </RequireAuth>
                }
            />
            <Route
                path={FrontEndPathResources.IntegracaoMaxys}
                element={
                    <RequireAuth>
                        <IntegracaoMaxys />
                    </RequireAuth>
                }
            />

            <Route
                path={FrontEndPathResources.PerfilUsuario}
                element={
                    <RequireAuth>
                        <PerfilUsuarioListar />
                    </RequireAuth>
                }
            />
            <Route
                path={FrontEndPathResources.PerfilUsuario + '/criar'}
                element={
                    <RequireAuth>
                        <PerfilUsuarioCriar />
                    </RequireAuth>
                }
            />
            <Route
                path={FrontEndPathResources.PerfilUsuario + '/alterar/:id'}
                element={
                    <RequireAuth>
                        <PerfilUsuarioAlterar />
                    </RequireAuth>
                }
            />
            <Route
                path={FrontEndPathResources.PerfilUsuario + '/ver/:id'}
                element={
                    <RequireAuth>
                        <PerfilUsuarioVer />
                    </RequireAuth>
                }
            />

            <Route
                path={FrontEndPathResources.CadastroUsuario}
                element={
                    <RequireAuth>
                        <CadastroUsuarioListar />
                    </RequireAuth>
                }
            />
            <Route
                path={FrontEndPathResources.CadastroUsuario + '/criar'}
                element={
                    <RequireAuth>
                        <CadastroUsuarioCriar />
                    </RequireAuth>
                }
            />
            <Route
                path={FrontEndPathResources.CadastroUsuario + '/alterar/:id'}
                element={
                    <RequireAuth>
                        <CadastroUsuarioAlterar />
                    </RequireAuth>
                }
            />
            <Route
                path={FrontEndPathResources.CadastroUsuario + '/ver/:id'}
                element={
                    <RequireAuth>
                        <CadastroUsuarioVer />
                    </RequireAuth>
                }
            />
            <Route
                path={FrontEndPathResources.OrdemColeta + '/criar/:id'}
                element={
                    <RequireAuth>
                        <OrdemColetaCriar />
                    </RequireAuth>
                }
            />
            <Route
                path={FrontEndPathResources.OrdemColeta + '/ver/:id'}
                element={
                    <RequireAuth>
                        <OrdemColetaVer />
                    </RequireAuth>
                }
            />
            <Route
                path={FrontEndPathResources.OrdemColeta + '/alterar/:id'}
                element={
                    <RequireAuth>
                        <OrdemColetaAlterar />
                    </RequireAuth>
                }
            />
            <Route
                path={
                    FrontEndPathResources.OrdemColeta +
                    '/lancar-dados-embarque/:loteEmbarqueTransportadoraId/:id'
                }
                element={
                    <RequireAuth>
                        <OrdemColetaLancarDadosEmbarque />
                    </RequireAuth>
                }
            />
            <Route
                path={
                    FrontEndPathResources.OrdemColeta + '/validar-descarga/:id'
                }
                element={
                    <RequireAuth>
                        <OrdemColetaValidarDescarga />
                    </RequireAuth>
                }
            />
            <Route
                path={FrontEndPathResources.LoteEmbarquePorTransportadora}
                element={
                    <RequireAuth>
                        <LotesPorTransportadoraListar />
                    </RequireAuth>
                }
            />
            <Route
                path={
                    FrontEndPathResources.LoteEmbarquePorTransportadora +
                    '/ver/:id'
                }
                element={
                    <RequireAuth>
                        <LotesPorTransportadoraVer />
                    </RequireAuth>
                }
            />
            <Route
                path={FrontEndPathResources.DoubleCheck + '/:id'}
                element={
                    <RequireAuth>
                        <DoubleCheck />
                    </RequireAuth>
                }
            />
            <Route
                path={FrontEndPathResources.DoubleCheckDescarga + '/:id'}
                element={
                    <RequireAuth>
                        <OrdemColetaValidarDescarga doubleCheck />
                    </RequireAuth>
                }
            />
            <Route
                path={FrontEndPathResources.DoubleCheck}
                element={
                    <RequireAuth>
                        <DoubleCheckListarFactory />
                    </RequireAuth>
                }
            />

            <Route path="*" element={<NotFound />} />
        </Routes>
    );
};

export default Router;
