// Função separada para processar o download, incluindo a extração do nome
export function processFileDownload(data: string, contentDisposition: string, type = 'application/pdf', filenameInfo?: string) {
    // Usa filenameInfo se fornecido, senão tenta extrair do contentDisposition, com fallback para 'downloaded-file.pdf'
    const filename = filenameInfo || extractFilename(contentDisposition) || 'downloaded-file.pdf';
    const blob = new Blob([data], { type });
    downloadBlob(blob, filename);
}
// Função utilitária para extrair o nome do arquivo do header Content-Disposition
function extractFilename(contentDisposition: string): string | undefined {
    const filenameMatch = contentDisposition?.match(/filename="(.+)"/);
    return filenameMatch ? filenameMatch[1] : undefined;
}

// Função utilitária para baixar o arquivo Blob
function downloadBlob(blob: Blob, filename: string) {
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
}
