import { Grid, TextField } from '@mui/material';
import { format } from 'date-fns';
import { FunctionComponent } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import DividerComp from '../../../Components/Divider/DividerComp';
import { IDoubleCheck } from '../entities/IDoubleCheck';

interface CavaloCarretaProps {}

const CavaloCarretaForm: FunctionComponent<CavaloCarretaProps> = () => {
    const { control } = useFormContext<IDoubleCheck>();

    const { fields, append, prepend, remove, swap, move, insert, replace } =
        useFieldArray({
            control,
            name: 'carretas',
        });

    return (
        <>
            <Grid container item spacing={2}>
                <Grid item xs={12} lg={2}>
                    <Controller
                        name="cavalo.placa"
                        control={control}
                        defaultValue={''}
                        render={({
                            field: { ref, onChange, onBlur, value, ...field },
                            fieldState: { error },
                        }) => (
                            <TextField
                                {...field}
                                margin="normal"
                                fullWidth
                                value={value}
                                label="Placa"
                                error={!!error?.message}
                                helperText={
                                    error?.message ? error?.message : ''
                                }
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={onChange}
                                disabled={true}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} lg={3}>
                    <Controller
                        name="cavalo.rntrc"
                        control={control}
                        defaultValue={''}
                        render={({
                            field: { ref, onChange, onBlur, value, ...field },
                            fieldState: { error },
                        }) => (
                            <TextField
                                {...field}
                                margin="normal"
                                fullWidth
                                value={value}
                                label="RNTRC"
                                error={!!error?.message}
                                helperText={
                                    error?.message ? error?.message : ''
                                }
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={onChange}
                                disabled={true}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} lg={2}>
                    <Controller
                        name="cavalo.rntrcDataVencimento"
                        control={control}
                        defaultValue={''}
                        render={({
                            field: { ref, onChange, onBlur, value, ...field },
                            fieldState: { error },
                        }) => (
                            <TextField
                                {...field}
                                margin="normal"
                                fullWidth
                                value={
                                    value
                                        ? format(new Date(value), 'dd/MM/yyyy')
                                        : ''
                                }
                                label="Vencimento RNTRC"
                                error={!!error?.message}
                                helperText={
                                    error?.message ? error?.message : ''
                                }
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={onChange}
                                disabled={true}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} lg={5}>
                    <Controller
                        name="cavalo.proprietarioNome"
                        control={control}
                        defaultValue={''}
                        render={({
                            field: { ref, onChange, onBlur, value, ...field },
                            fieldState: { error },
                        }) => (
                            <TextField
                                {...field}
                                margin="normal"
                                fullWidth
                                value={value}
                                label="Nome proprietário/locatário"
                                error={!!error?.message}
                                helperText={
                                    error?.message ? error?.message : ''
                                }
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={onChange}
                                disabled={true}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} lg={3}>
                    <Controller
                        name="cavalo.proprietarioDocumento"
                        control={control}
                        defaultValue={''}
                        render={({
                            field: { ref, onChange, onBlur, value, ...field },
                            fieldState: { error },
                        }) => (
                            <TextField
                                {...field}
                                margin="normal"
                                fullWidth
                                value={value}
                                label="Documento proprietário/locatário"
                                error={!!error?.message}
                                helperText={
                                    error?.message ? error?.message : ''
                                }
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={onChange}
                                disabled={true}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} lg={3}>
                    <Controller
                        name="cavalo.renavam"
                        control={control}
                        defaultValue={''}
                        render={({
                            field: { ref, onChange, onBlur, value, ...field },
                            fieldState: { error },
                        }) => (
                            <TextField
                                {...field}
                                margin="normal"
                                fullWidth
                                value={value}
                                label="Renavam"
                                error={!!error?.message}
                                helperText={
                                    error?.message ? error?.message : ''
                                }
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={onChange}
                                disabled={true}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} lg={1}>
                <Controller
                    name="cavalo.uf"
                    control={control}
                    defaultValue={''}
                    render={({
                        field: { ref, onChange, onBlur, value, ...field },
                        fieldState: { error },
                    }) => (
                        <TextField
                            {...field}
                            margin="normal"
                            fullWidth
                            value={value}
                            label="UF"
                            error={!!error?.message}
                            helperText={error?.message ? error?.message : ''}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={onChange}
                            disabled={true}
                        />
                    )}
                />
                </Grid>
            </Grid>
            {fields.map((item, index) => {
                return (
                    <>
                        <DividerComp texto={`Carreta ${index+1}`} />
                        <Grid container item spacing={2} key={item.id}>
                            <Grid item xs={12} lg={2}>
                                <Controller
                                    name={`carretas.${index}.placa`}
                                    control={control}
                                    defaultValue={''}
                                    render={({
                                        field: {
                                            ref,
                                            onChange,
                                            onBlur,
                                            value,
                                            ...field
                                        },
                                        fieldState: { error },
                                    }) => (
                                        <TextField
                                            {...field}
                                            margin="normal"
                                            fullWidth
                                            value={value}
                                            label="Placa"
                                            error={!!error?.message}
                                            helperText={
                                                error?.message
                                                    ? error?.message
                                                    : ''
                                            }
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={onChange}
                                            disabled={true}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} lg={3}>
                                <Controller
                                    name={`carretas.${index}.rntrc`}
                                    control={control}
                                    defaultValue={''}
                                    render={({
                                        field: {
                                            ref,
                                            onChange,
                                            onBlur,
                                            value,
                                            ...field
                                        },
                                        fieldState: { error },
                                    }) => (
                                        <TextField
                                            {...field}
                                            margin="normal"
                                            fullWidth
                                            value={value}
                                            label="RNTRC"
                                            error={!!error?.message}
                                            helperText={
                                                error?.message
                                                    ? error?.message
                                                    : ''
                                            }
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={onChange}
                                            disabled={true}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} lg={2}>
                                <Controller
                                    name={`carretas.${index}.rntrcDataVencimento`}
                                    control={control}
                                    defaultValue={''}
                                    render={({
                                        field: {
                                            ref,
                                            onChange,
                                            onBlur,
                                            value,
                                            ...field
                                        },
                                        fieldState: { error },
                                    }) => (
                                        <TextField
                                            {...field}
                                            margin="normal"
                                            fullWidth
                                            value={
                                                value
                                                    ? format(
                                                          new Date(value),
                                                          'dd/MM/yyyy'
                                                      )
                                                    : ''
                                            }
                                            label="Vencimento RNTRC"
                                            error={!!error?.message}
                                            helperText={
                                                error?.message
                                                    ? error?.message
                                                    : ''
                                            }
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={onChange}
                                            disabled={true}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} lg={5}>
                                <Controller
                                    name={`carretas.${index}.proprietarioNome`}
                                    control={control}
                                    defaultValue={''}
                                    render={({
                                        field: {
                                            ref,
                                            onChange,
                                            onBlur,
                                            value,
                                            ...field
                                        },
                                        fieldState: { error },
                                    }) => (
                                        <TextField
                                            {...field}
                                            margin="normal"
                                            fullWidth
                                            value={value}
                                            label="Nome proprietário/locatário"
                                            error={!!error?.message}
                                            helperText={
                                                error?.message
                                                    ? error?.message
                                                    : ''
                                            }
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={onChange}
                                            disabled={true}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} lg={3}>
                                <Controller
                                    name={`carretas.${index}.proprietarioDocumento`}
                                    control={control}
                                    defaultValue={''}
                                    render={({
                                        field: {
                                            ref,
                                            onChange,
                                            onBlur,
                                            value,
                                            ...field
                                        },
                                        fieldState: { error },
                                    }) => (
                                        <TextField
                                            {...field}
                                            margin="normal"
                                            fullWidth
                                            value={value}
                                            label="Documento proprietário/locatário"
                                            error={!!error?.message}
                                            helperText={
                                                error?.message
                                                    ? error?.message
                                                    : ''
                                            }
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={onChange}
                                            disabled={true}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} lg={3}>
                                <Controller
                                    name={`carretas.${index}.renavam`}
                                    control={control}
                                    defaultValue={''}
                                    render={({
                                        field: {
                                            ref,
                                            onChange,
                                            onBlur,
                                            value,
                                            ...field
                                        },
                                        fieldState: { error },
                                    }) => (
                                        <TextField
                                            {...field}
                                            margin="normal"
                                            fullWidth
                                            value={value}
                                            label="Renavam"
                                            error={!!error?.message}
                                            helperText={
                                                error?.message
                                                    ? error?.message
                                                    : ''
                                            }
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={onChange}
                                            disabled={true}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} lg={1}>
                                <Controller
                                    name={`carretas.${index}.uf`}
                                    control={control}
                                    defaultValue={''}
                                    render={({
                                        field: { ref, onChange, onBlur, value, ...field },
                                        fieldState: { error },
                                    }) => (
                                        <TextField
                                            {...field}
                                            margin="normal"
                                            fullWidth
                                            value={value}
                                            label="UF"
                                            error={!!error?.message}
                                            helperText={error?.message ? error?.message : ''}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={onChange}
                                            disabled={true}
                                        />
                                    )}
                                />
                                </Grid>
                        </Grid>
                    </>
                );
            })}
        </>
    );
};

export default CavaloCarretaForm;
