import {
    Alert,
    AlertTitle,
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormGroup,
    Typography,
} from '@mui/material';
import Upload from '../../Components/Upload/Upload';
import { useState } from 'react';
import { styles } from './helpers/Styles';
import type { IArquivoEncoded } from '../OrdemColeta/domain/entities/IArquivoEncoded';
import type { Arquivo } from '../Ordem/Listar';

interface Props {
    // embarque: Embarque;
    fecharClicado: () => void;
    aberto: boolean;
    enviarAnexoClicado: (arquivo: IArquivoEncoded) => void;
}

export default function ModalAnexarLGPD({
    // embarque,
    fecharClicado,
    aberto = false,
    enviarAnexoClicado,
}: Props) {
    const [arquivo, setArquivo] = useState<string>();

    const [arquivoSelecionado, setArquivoSelecionado] = useState<Arquivo>();

    const [aceitaLGPD, setAceitaLGPD] = useState<boolean>(false);

    const tratarDados = () => {
        if (arquivo && arquivoSelecionado) {
            enviarAnexoClicado({
                nome: arquivoSelecionado.name,
                stringBase64: arquivo,
                tipo: 'LGPD',
                anexo: null

            });
        }
    };

    function handleSetFile(file: File) {
        setArquivoSelecionado(file);

        const fileReader = new FileReader();

        fileReader.onload = (fileLoadedEvent) => {
            const result = fileLoadedEvent.target?.result;

            if (typeof result === 'string') {
                setArquivo(result); // Apenas atribua se for uma string
            } else {
                console.error('O resultado não é uma string, é um ArrayBuffer');
            }
        };

        fileReader.readAsDataURL(file);
    }

    return (
        <Dialog
            open={aberto}
            onClose={() => fecharClicado()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                Anexar termo de aceite da LGPD.
            </DialogTitle>
            <DialogContent>
                <Typography>
                    Selecione o arquivo LGPD que será exibido ao usuário <br />{' '}
                    no momento do login.
                </Typography>

                <Box sx={{ bgcolor: 'white', p: 2 }}>
                    <Upload
                        setFile={handleSetFile}
                        arquivosSuportados={'.pdf'}
                        mensagemIsDragAtivo="Arraste o arquivo aqui."
                        mensagemPadrao="Arraste arquivo aqui ou clique para selecionar do
                        computador"
                    />

                    {arquivoSelecionado && (
                        <Alert severity="info" style={{ marginTop: 16 }}>
                            <AlertTitle>Arquivo selecionado</AlertTitle>
                            {arquivoSelecionado.name}
                        </Alert>
                    )}

                    <div className="flex items-center space-x-2">
                        <FormControl>
                            <FormGroup>
                                <FormControlLabel
                                    id="smart-check-box"
                                    control={
                                        <Checkbox
                                            checked={aceitaLGPD}
                                            onChange={() =>
                                                setAceitaLGPD(!aceitaLGPD)
                                            }
                                        />
                                    }
                                    label="Eu confirmo que este é o arquivo correto da LGPD"
                                />
                            </FormGroup>
                        </FormControl>
                    </div>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    style={styles.button}
                    variant="contained"
                    color="secondary"
                    onClick={fecharClicado}
                    autoFocus
                    aria-label="Cancelar ação"
                >
                    Cancelar
                </Button>
                <Button
                    style={{...styles.button ,
                        ...((!aceitaLGPD || !arquivoSelecionado) ? styles.buttonDisabled : {})
                        }}
                    variant="contained"
                    color="primary"
                    type="submit"
                    onClick={tratarDados}
                    aria-label="Enviar formulário"
                    disabled={!aceitaLGPD || !arquivoSelecionado}
                >
                    Enviar
                </Button>
            </DialogActions>
        </Dialog>
    );
}
