import Joi from 'joi';

const cpfRegex = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;
const cpfOrCnpjRegexExp =
    /(\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2})|(\d{3}\.\d{3}\.\d{3}-\d{2})/;
const placaRegex = /^[A-Za-z]{3}\d{1}[A-Za-z0-9]{1}\d{2}$/;

export const validacao = (pesoTotal: number) => {
    return Joi.object({
        id: Joi.number().allow('', null).optional(),
        maxysId: Joi.number().allow('', null).optional(),
        loteEmbarqueId: Joi.number().allow('', null).optional(),
        idMaxysERP: Joi.number().allow('', null).optional(),
        loteEmbarqueTransportadoraId: Joi.number().allow('', null).optional(),
        cadastradaViaPortal: Joi.boolean().allow('', null).optional(),
        numeroLoteGpe65: Joi.number().allow('', null).optional(),
        statusOrdemColeta: Joi.string().allow('', null).optional(),
        dadosLancamentoEmbarque: Joi.object().allow('', null).optional(),
        tipoLoteEmbarque: Joi.string().allow('', null).optional(),
        produtoId: Joi.number().allow('', null).optional(),
        descricaoItem: Joi.string().required().messages({
            'string.base': 'O campo deve ser uma string',
            'string.empty': 'O campo não pode ficar em branco',
            'any.required': 'O campo não pode ficar em branco',
        }),
        dataHoraColeta: Joi.date().allow('', null).messages({
            'date.base': 'O campo deve ser uma data',
            'any.required': 'O campo não pode ficar em branco',
            'string.empty': 'O campo não pode ficar em branco',
        }).custom((value, helper) => {
        const today = new Date();
        if (value < today) {
            return helper.message({ 'custom': 'A data de coleta deve ser posterior a hoje' });
        }
            return value;
        }),
        peso: Joi.number()
            .required()
            .messages({
                'number.base': 'O campo deve ser um número',
                'number.empty': 'O campo não pode ficar em branco',
                'any.required': 'O campo não pode ficar em branco',
            })
            .custom((value, helpers) => {
                if (value > pesoTotal) {
                    return helpers.message(
                        `O peso do item (${value}) ultrapassa o peso total permitido (${pesoTotal}).` as any
                    );
                }
                return value;
            }),
        veiculoTerceiro: Joi.boolean().allow('', null).optional(),
        motorista: Joi.object()
            .required()
            .keys({
                id: Joi.number().allow('', null).optional(),
                nome: Joi.string().required().messages({
                    'string.base': 'O campo deve ser uma string',
                    'string.empty': 'O campo não pode ficar em branco',
                    'any.required': 'O campo não pode ficar em branco',
                }),
                cpf: Joi.string().required().pattern(cpfRegex).messages({
                    'string.base': 'O campo deve ser uma string',
                    'string.empty': 'O campo não pode ficar em branco',
                    'any.required': 'O campo não pode ficar em branco',
                    'string.pattern.base': 'Deve ser um CPF válido',
                }),
                uf: Joi.string().required().min(2).messages({
                    'string.base': 'Usar a sigla (duas letras) correspondente à UF',
                    'string.empty': 'O campo não pode ficar em branco',
                    'any.required': 'O campo não pode ficar em branco',
                    'string.min': 'UF não é válida'
                }),
                cnh: Joi.string().required().messages({
                    'string.base': 'O campo deve ser uma string',
                    'string.empty': 'O campo não pode ficar em branco',
                    'any.required': 'O campo não pode ficar em branco',
                }),
            }),
        cavalo: Joi.object()
            .required()
            .keys({
                id: Joi.number().allow('', null).optional(),
                proprietarioId: Joi.number().allow('', null).optional(),
                placa: Joi.string().required().pattern(placaRegex).messages({
                    'string.base': 'O campo deve ser uma string',
                    'string.empty': 'O campo não pode ficar em branco',
                    'any.required': 'O campo não pode ficar em branco',
                    'string.pattern.base': 'Deve ser uma placa válida',
                }),
                renavam: Joi.string().required().max(11).messages({
                    'string.base': 'O campo deve ser uma string',
                    'string.empty': 'O campo não pode ficar em branco',
                    'any.required': 'O campo não pode ficar em branco',
                    'string.max': 'O campo deve ter no máximo 11 caracteres',
                }),
                rntrc: Joi.string().min(9).max(12).required().messages({
                    'string.base': 'não pode ficar em branco',
                    'string.empty': 'não pode ficar em branco',
                    'string.max': 'deve ter no máximo 12 caracteres',
                    'string.min': 'deve ter no mínimo 9 caracteres',
                    'any.required': 'não pode ficar em branco',
                }),
                rntrcDataVencimento: Joi.date().allow('', null).optional(),
                proprietarioDocumento: Joi.string()
                    .required()
                    .pattern(cpfOrCnpjRegexExp)
                    .messages({
                        'string.base': 'O campo deve ser uma string',
                        'string.empty': 'O campo não pode ficar em branco',
                        'any.required': 'O campo não pode ficar em branco',
                        'string.pattern.base': 'Deve ser um CPF ou CNPJ válido',
                    }),
                proprietarioNome: Joi.string().required().messages({
                    'string.base': 'O campo deve ser uma string',
                    'string.empty': 'O campo não pode ficar em branco',
                    'any.required': 'O campo não pode ficar em branco',
                }),
                uf: Joi.string().required().min(2).messages({
                    'string.base': 'Usar a sigla (duas letras) correspondente à UF',
                    'string.empty': 'O campo não pode ficar em branco',
                    'any.required': 'O campo não pode ficar em branco',
                    'string.min': 'UF não é válida'
                }),
            }),
        carretas: Joi.array().items(
            Joi.object().keys({
                id: Joi.number().allow('', null).optional(),
                proprietarioId: Joi.number().allow('', null).optional(),
                placa: Joi.string()
                    .allow('')
                    .min(7)
                    .pattern(placaRegex)
                    .messages({
                        'string.base': 'O campo deve ser uma string',
                        'string.empty': 'O campo pode ficar em branco',
                        'string.pattern.base': 'Deve ser uma placa válida',
                        'string.min': 'Deve ser uma placa válida',
                    })
                    .optional(),
                renavam: Joi.when('placa', {
                    is: Joi.string().min(7),
                    then: Joi.string().required().max(11).messages({
                        'string.base': 'O campo deve ser uma string',
                        'string.empty': 'O campo não pode ficar em branco',
                        'any.required': 'O campo não pode ficar em branco',
                        'string.max':
                            'O campo deve ter no máximo 11 caracteres',
                    }),
                    otherwise: Joi.string().allow('', null).optional(),
                }),
                rntrc: Joi.when('placa', {
                    is: Joi.string().min(7),
                    then: Joi.string().required().messages({
                        'string.base': 'O campo deve ser uma string',
                        'string.empty': 'O campo não pode ficar em branco',
                        'any.required': 'O campo não pode ficar em branco',
                    }),
                    otherwise: Joi.string().allow('', null).optional(),
                }),
                rntrcDataVencimento: Joi.date().allow('', null).optional(),
                proprietarioDocumento: Joi.when('placa', {
                    is: Joi.string().min(7),
                    then: Joi.string()
                        .required()
                        .pattern(cpfOrCnpjRegexExp)
                        .messages({
                            'string.base': 'O campo deve ser uma string',
                            'string.empty': 'O campo não pode ficar em branco',
                            'any.required': 'O campo não pode ficar em branco',
                            'string.pattern.base':
                                'Deve ser um CPF ou CNPJ válido',
                        }),
                    otherwise: Joi.string().allow('', null).optional(),
                }),
                proprietarioNome: Joi.when('placa', {
                    is: Joi.string().min(7),
                    then: Joi.string().required().messages({
                        'string.base': 'O campo deve ser uma string',
                        'string.empty': 'O campo não pode ficar em branco',
                        'any.required': 'O campo não pode ficar em branco',
                    }),
                    otherwise: Joi.string().allow('', null).optional(),
                }),
                uf: Joi.when('placa', {
                    is: Joi.string().min(2),
                    then: Joi.string()
                        .required()
                        .messages({
                            'string.base': 'Usar a sigla (duas letras) correspondente à UF',
                            'string.empty': 'O campo não pode ficar em branco',
                            'any.required': 'O campo não pode ficar em branco',
                            'string.min': 'UF não é válida'
                        }),
                    otherwise: Joi.string().allow('', null).optional(),
                })
            })
        ),
        classificacaoAmostras: Joi.array().allow('', null).optional(),
    });
};

export const validacaoFindJuncao = Joi.object({
    cpfMotorista: Joi.string().required().pattern(cpfRegex).messages({
        'string.pattern.base': 'Deve ser um CPF válido',
        'any.required': 'O campo não pode ficar em branco',
        'string.empty': 'O campo não pode ficar em branco',
    }),
    placaCavalo: Joi.string().required().pattern(placaRegex).messages({
        'string.pattern.base': 'Deve ser uma placa válida',
        'any.required': 'O campo não pode ficar em branco',
        'string.empty': 'O campo não pode ficar em branco',
    }),
    placaCarreta1: Joi.string().allow('', null).optional(),
    placaCarreta2: Joi.string().allow('', null).optional(),
    placaCarreta3: Joi.string().allow('', null).optional(),
});
