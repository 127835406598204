import { Status } from './../Screens/Ordem/Formulario';
import { LocalClassificacao, TipoOrdem } from '../Screens/Ordem/Formulario';
import Classificador from './Classificador';
import ClienteFornecedor from './ClienteFornecedor';
import Endereco from './Endereco';
import Embarque from './Embarque';
import Produto from './Produto';
import Contratante from './Contratante';
import Transportadora from './Transportadora';

export default class OrdemCarregamento {
    constructor(
        public id: number,
        public status: Status,
        public codigoContrato: string,
        public pesoPlanejado: number,
        public tipoNegociacao: TipoOrdem,
        public localClassificacao: LocalClassificacao,
        public classificador: Classificador,
        public clifor: ClienteFornecedor,
        public produto: Produto,
        public enderecamentoOrigem: Endereco,
        public enderecamentoDestino: Endereco,
        public cadastradaPeloPortal: boolean,
        public transportadora_id?: number,
        public transportadora?: Transportadora,
        public contratante?: Contratante,
        public embarques?: Embarque[]
    ) {}
}
