import type { IBaseUseCase } from '../../../../types/interfaces/IBaseUseCase';
import type { ILGPDRequestDto } from '../../dtos/ILGPDRequestDto';
import type { ILGPDResponseDto } from '../../dtos/ILGPDResponseDto';
import type { ILGPDRepository } from '../repositories/ILGPDRepository';

export class PostTermoVigenteLGPD
    implements IBaseUseCase<ILGPDRequestDto, ILGPDResponseDto>
{
    constructor(private readonly repository: ILGPDRepository) {}

    execute(data: ILGPDRequestDto): Promise<ILGPDResponseDto> {
        console.log('data', data);

        console.log('repository', this.repository);

        return this.repository.postCriarNovoTermo(data);
    }
}
