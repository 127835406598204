import LockResetIcon from '@mui/icons-material/LockReset';
import Logout from '@mui/icons-material/Logout';
import Settings from '@mui/icons-material/Settings';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import * as React from 'react';
import { useNavigate } from 'react-router';
import { useAuth } from '../../AuthProvider';
import { FrontEndPathResources } from '../../Models/Api';
import type Usuario from '../../Models/Usuario';
import {
    ChavesArmazenamentoStorage,
    EnumAutorizacao,
} from '../../Models/Usuario';
import type LoginResponse from '../../Models/responses/LoginResponse';

export default function UsuarioBar() {
    const [usuarioLogado, setUsuarioLogado] = React.useState<Usuario>();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const auth = useAuth();
    const navigate = useNavigate();

    React.useEffect(() => {
        const loginResponse = localStorage.getItem(
            ChavesArmazenamentoStorage.LOGIN_RESPONSE
        );

        if (loginResponse) {
            setUsuarioLogado(
                (JSON.parse(loginResponse) as LoginResponse).usuario
            );
        }
    }, []);

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    textAlign: 'center',
                }}
            >
                <Tooltip title="Account settings">
                    <IconButton
                        onClick={handleClick}
                        size="small"
                        sx={{ ml: 2 }}
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                    >
                        <Avatar sx={{ width: 32, height: 32 }}>
                            {usuarioLogado?.login.charAt(0).toUpperCase()}
                        </Avatar>
                    </IconButton>
                </Tooltip>
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem disabled
                        style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <ListItemIcon>
                                <AccountCircleIcon fontSize="small" />
                            </ListItemIcon>
                             {usuarioLogado ?
                    usuarioLogado?.nome : ''
                    }
                </MenuItem>
                {usuarioLogado &&
                    usuarioLogado?.autorizacoes[0]?.tipoAcesso !==
                        EnumAutorizacao.Suporte && (
                        <MenuItem
                            onClick={() => {
                                navigate(FrontEndPathResources.Configuracao);
                            }}
                        >
                            <ListItemIcon>
                                <Settings fontSize="small" />
                            </ListItemIcon>
                            Configurações
                        </MenuItem>
                    )}

                <MenuItem
                    onClick={() => {
                        navigate(FrontEndPathResources.AlterarSenha);
                    }}
                >
                    <ListItemIcon>
                        <LockResetIcon fontSize="small" />
                    </ListItemIcon>
                    Alterar senha
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        auth.signout(() => {
                            navigate('/easymbark/login');
                        });
                    }}
                >
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    Sair
                </MenuItem>
            </Menu>
        </>
    );
}
