import type Autorizacao from './Autorizacao';
import type PerfilUsuario from './PerfilUsuario';
import type Pessoa from './Pessoa';
import type TermoLGPD from './TermoLGPD';

export enum ChavesArmazenamentoStorage {
    Usuario = '@USUARIO_PORTAL_EASYMBARK',
    Token = '@TOKEN_USUARIO_PORTAL',
    HOST = '@HOST_PORTAL',
    CLIENT = '@EMPRESA_TENANCY_PORTAL',
    LOGIN_RESPONSE = 'LOGIN_RESPONSE_PORTAL',
}
export enum EnumAutorizacao {
    Portal = 'PORTAL',
    Classificador = 'CLASSIFICADOR',
    Contratante = 'CONTRATANTE',
    Suporte = 'SUPORTE',
    Integração = 'INTEGRACAO',
}

export enum StatusRequisicao {
    OK = 200,
    CREATED = 201,
    BAD_REQUEST = 400,
}

export enum RecursosPerfisEnum {
    DASHBOARD_VER = 'Dashboard - Ver',
    ORDEM_CARREGAMENTO_VER = 'Ordem de carregamento - Ver',
    ORDEM_CARREGAMENTO_CRIAR = 'Ordem de carregamento - Criar',
    ORDEM_CARREGAMENTO_ALTERAR = 'Ordem de carregamento - Alterar',
    ORDEM_CARREGAMENTO_EXCLUIR = 'Ordem de carregamento - Excluir',
    ORDEM_CARREGAMENTO_ALTERAR_PESO_PLANEJADO = 'Ordem de carregamento - Alterar peso planejado',
    ORDEM_CARREGAMENTO_ANEXAR_NOTA_FISCAL = 'Ordem de carregamento - Anexar Nota Fiscal',
    ORDEM_CARREGAMENTO_BAIXAR_PDF_ROMANEIO = 'Ordem de carregamento - Baixar pdf romaneio',
    ORDEM_CARREGAMENTO_CONSULTAR_HISTORICO = 'Ordem de carregamento - Consultar histórico de alterações',
    DOUBLE_CHECK_ALTERAR = 'Double check - Alterar',
    LOTE_EMBARQUE_VER = 'Lote Embarque - Ver',
    LOTE_EMBARQUE_ALTERAR = 'Lote Embarque - Alterar',
    LOTE_EMBARQUE_CONSULTAR_HISTORICO = 'Lote Embarque - Consultar histórico de alterações',
    CLIENTES_FORNECEDORES_VER = 'Clientes/Fornecedores - Ver',
    CLIENTES_FORNECEDORES_CRIAR = 'Clientes/Fornecedores - Criar',
    CLIENTES_FORNECEDORES_ALTERAR = 'Clientes/Fornecedores - Alterar',
    CLIENTES_FORNECEDORES_EXCLUIR = 'Clientes/Fornecedores - Excluir',
    CLIENTES_FORNECEDORES_CONSULTAR_HISTORICO = 'Clientes/Fornecedores - Consultar histórico de alterações',
    CLASSIFICADORES_VER = 'Classificadores - Ver',
    CLASSIFICADORES_CRIAR = 'Classificadores - Criar',
    CLASSIFICADORES_ALTERAR = 'Classificadores - Alterar',
    CLASSIFICADORES_EXCLUIR = 'Classificadores - Excluir',
    CLASSIFICADORES_CONSULTAR_HISTORICO = 'Classificadores - Consultar histórico de alterações',
    PRODUTOS_VER = 'Produtos - Ver',
    PRODUTOS_CRIAR = 'Produtos - Criar',
    PRODUTOS_ALTERAR = 'Produtos - Alterar',
    PRODUTOS_EXCLUIR = 'Produtos - Excluir',
    PRODUTOS_CONSULTAR_HISTORICO = 'Produtos - Consultar histórico de alterações',
    AMOSTRAS_VER = 'Amostras - Ver',
    AMOSTRAS_CRIAR = 'Amostras - Criar',
    AMOSTRAS_ALTERAR = 'Amostras - Alterar',
    AMOSTRAS_EXCLUIR = 'Amostras - Excluir',
    TRANSPORTADORAS_VER = 'Transportadoras - Ver',
    TRANSPORTADORAS_CRIAR = 'Transportadoras - Criar',
    TRANSPORTADORAS_ALTERAR = 'Transportadoras - Alterar',
    TRANSPORTADORAS_EXCLUIR = 'Transportadoras - Excluir',
    TRANSPORTADORAS_CONSULTAR_HISTORICO = 'Transportadoras - Consultar histórico de alterações',
    JUNCAO_CRIAR = 'Junção - Criar',
    INTEGRACOES_VER = 'Integrações - Ver',
    INTEGRACOES_ALTERAR = 'Integrações - Alterar',
    PERFIIS_USUARIOS_VER = 'Perfis de usuários - Ver',
    PERFIIS_USUARIOS_CRIAR = 'Perfis de usuários - Criar',
    PERFIIS_USUARIOS_ALTERAR = 'Perfis de usuários - Alterar',
    PERFIIS_USUARIOS_EXCLUIR = 'Perfis de usuários - Excluir',
    PERFIIS_USUARIOS_CONSULTAR_HISTORICO = 'Perfis de usuários - Consultar histórico de alterações',
    ADMINISTRADOR_VER = 'Administrador - Ver',
    ADMINISTRADOR_CRIAR = 'Administrador - Criar',
    ADMINISTRADOR_ALTERAR = 'Administrador - Alterar',
    ADMINISTRADOR_EXCLUIR = 'Administrador - Excluir',
    CADASTRO_USUARIOS_VER = 'Cadastro Usuários - Ver',
    CADASTRO_USUARIOS_CRIAR = 'Cadastro Usuários - Criar',
    CADASTRO_USUARIOS_ALTERAR = 'Cadastro Usuários - Alterar',
    CADASTRO_USUARIOS_EXCLUIR = 'Cadastro Usuários - Excluir',
    CONFIGURACOES_USUARIO = 'Configurações de usuário',
    ORDEM_COLETA_VER = 'Ordem de coleta - Ver',
    ORDEM_COLETA_CRIAR = 'Ordem de coleta - Criar',
    ORDEM_COLETA_ALTERAR = 'Ordem de coleta - Alterar',
    ORDEM_COLETA_EXCLUIR = 'Ordem de coleta - Excluir',
    LOTE_EMBARQUE_POR_TRANSPORTADORA = 'Lotes de embarque por transportadora - Ver',
    HISTORICO_LOTE_EMBARQUE_POR_TRANSPORTADORA = 'Histórico de lotes por transportadora - Ver',
}

export default class Usuario {
    constructor(
        public id: number,
        public login: string,
        public autorizacoes: Autorizacao[],
        public perfilUsuario: PerfilUsuario,
        public pessoa: Pessoa,
        public cpf?: string,
        public cnpj?: string,
        public trocarSenha?: boolean,
        public senha?: string,
        public integradoMaxys?: boolean,
        public nome?: string,
        public termosLGPD?: TermoLGPD[]
    ) {}
}

export class UsuarioTratado {
    constructor(
        public login: string,
        public senha?: string | null,
        public trocarSenha?: boolean
    ) {}
}
