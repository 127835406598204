import { IBaseUseCase } from '../../../../types/interfaces/IBaseUseCase';
import { ILoteEmbarqueResponseDto } from '../../dtos/ILoteEmbarqueResponseDto';
import { ILoteEmbarqueRepository } from '../repositories/ILoteEmbarqueRepository';

export class GetLoteEmbarqueByLoteEmbarqueTransportadoraId
    implements IBaseUseCase<number, ILoteEmbarqueResponseDto>
{
    constructor(private readonly repository: ILoteEmbarqueRepository) {}

    execute(id: number): Promise<ILoteEmbarqueResponseDto> {
        return this.repository.getLoteEmbarqueByLoteEmbarqueTransportadoraId(
            id
        );
    }
}
