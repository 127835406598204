import { Button, Grid, Card, CardContent, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { FunctionComponent, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import DividerComp from '../../../Components/Divider/DividerComp';
import { IDoubleCheck } from '../entities/IDoubleCheck';
import { styles } from '../../PerfilUsuario/helpers/Styles';
import ContratosModalSemNota from './ContratosModalSemNota';

interface VinculoContratoSemNotaFormProps {}

const VinculoContratoSemNotaForm: FunctionComponent<VinculoContratoSemNotaFormProps> = () => {
    const { control } = useFormContext<IDoubleCheck>();
    const [modalContratosSemNota, setModalContratosSemNota] = useState(false);
    const [modalVinculoContratoIndex] = useState(0);

    const { fields, remove } = useFieldArray({
        control,
        name: 'vinculosSemNota',
    });

    return (
        <>
            <DividerComp texto="Vincular Contratos (sem nota)" />
            <Grid container item spacing={2}>
                <Grid item xs={12} lg={3}>
                    <Button
                        onClick={() => {setModalContratosSemNota(true)}}
                        variant="contained"
                        style={styles().button}
                        size="large"
                    >
                        Vincular contrato(s)
                    </Button>
                </Grid>
            </Grid>

            {modalContratosSemNota && (
                <ContratosModalSemNota
                    open={modalContratosSemNota}
                    onClose={() => setModalContratosSemNota(false)}
                    nestedIndex={modalVinculoContratoIndex}
                />
            )}
            <DividerComp texto={`Contrato(s) vinculados (sem nota)`} />
                        <Grid container item spacing={2}>
                            {fields &&
                                fields.map((contrato, i) => {
                                    return (
                                        <Grid item xs={12} lg={1.3} key={i}>
                                            <Card key={i}>
                                                <CardContent
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent:
                                                            'space-around',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Typography>
                                                        Contrato:{' '}
                                                        {
                                                            contrato.numeroContrato
                                                        }
                                                    </Typography>
                                                    <IconButton
                                                        onClick={() => {
                                                            remove(i);
                                                            console.log(`aqui - chamou o excluir`);
                                                        }}
                                                        aria-label="delete"
                                                    >
                                                        <CloseIcon />
                                                    </IconButton>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    );
                                })}
                        </Grid>
        </>
    );
};

export default VinculoContratoSemNotaForm;